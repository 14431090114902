/**
 * @returns Common Column Defitions for Reference Tables
 */
const CommonColumns = [
  {
    field: 'created_by_id',
    headerName: 'Created By ID',
    type: 'number',
    editable: false,
  },
  {
    field: 'created_by',
    headerName: 'Created By',
    editable: false,
    flex: 1,
  },
  {
    field: 'created_at',
    headerName: 'Created At',
    type: 'dateTime',
    valueGetter: ({ value }: any): any => value && new Date(value),
    editable: false,
    flex: 1,
  },
  {
    field: 'updated_by_id',
    headerName: 'Updated By ID',
    type: 'number',
    editable: false,
  },
  {
    field: 'updated_by',
    headerName: 'Updated By',
    editable: false,
    flex: 1,
  },
  {
    field: 'updated_at',
    headerName: 'Updated At',
    type: 'dateTime',
    valueGetter: ({ value }: any): any => value && new Date(value),
    editable: false,
    flex: 1,
  },
  {
    field: 'is_deleted',
    headerName: 'Deleted',
    type: 'boolean',
    editable: false,
    flex: 1,
  },
  {
    field: 'id',
    headerName: 'ID',
    type: 'number',
    editable: false,
  },
];
export default CommonColumns;
