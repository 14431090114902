import Box from '@mui/material/Box';
import SVGAsset from '../../Assets/SVGAsset';
/**
 * @returns Assets Tables Column Definitions
 */
const AssetColumnDefinitions = [
  {
    name: 'Asset',
    table: 'asset',
    visibility: {
      id: false,
      asset_type_id: false,
      asset_format_id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'asset_name',
        headerName: 'Asset Name',
        editable: false,
        flex: 1,
      },
      {
        field: 'asset_type_id',
        headerName: 'Asset Type ID',
        type: 'number',
        editable: false,
        flex: 1,
      },
      {
        field: 'asset_type',
        headerName: 'Asset Type',
        editable: false,
        flex: 1,
      },
      {
        field: 'asset_format_id',
        headerName: 'Asset Format ID',
        type: 'number',
        editable: false,
        flex: 1,
      },
      {
        field: 'asset_format',
        headerName: 'Content',
        editable: false,
        flex: 1,
        renderCell: (params: any, props: any): any => {
          if (params.value !== undefined) {
            if (params.value.extension === 'svg') {
              return (
                <>
                  <Box sx={{ width: '100%', height: '100%', display: 'block' }}>
                    <SVGAsset title={params.row.asset_name} content={params.row.content} width={50} height={50} />
                  </Box>
                </>
              );
            }
          }
        },
      },
    ],
  },
  {
    name: 'Asset Format',
    table: 'asset_format',
    visibility: {
      id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'format',
        headerName: 'Format',
        editable: false,
        flex: 1,
      },
      {
        field: 'extension',
        headerName: 'Extension',
        editable: false,
        flex: 1,
      },
    ],
  },
  {
    name: 'Asset Type',
    table: 'asset_type',
    visibility: {
      id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'category',
        headerName: 'Category',
        editable: false,
        flex: 1,
      },
    ],
  },
];
export default AssetColumnDefinitions;
