import React from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import AdminNavActions from './AdminNavActions';
import * as Icon from 'react-feather';

const AdminNav = (props: any): JSX.Element => {
  // Get the Admin menu actions
  const adminMenu = AdminNavActions(props);

  // Build the Markup
  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="Help">
        <IconButton onClick={props.onOpenHelpMenu} sx={{ p: 1 }}>
          <Avatar
            sx={{
              boxShadow:
                '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
            }}
          >
            <Icon.HelpCircle />
          </Avatar>
        </IconButton>
      </Tooltip>
      <Tooltip title="Administration Menu">
        <IconButton onClick={props.onOpenAdminMenu} sx={{ p: 1 }}>
          <Avatar
            sx={{
              boxShadow:
                '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
            }}
          >
            <Icon.Settings />
          </Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: '45px' }}
        id="admin_menu"
        anchorEl={props.menuState}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(props.menuState)}
        onClose={props.onCloseAdminMenu}
      >
        {adminMenu.map((item: any): any => (
          <MenuItem key={item.id} onClick={item.onClick}>
            <item.icon />
            &nbsp;
            <Typography textAlign="center">{item.name}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
export default AdminNav;
