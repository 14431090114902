/**
 * @array AdColumnDefinitions
 * @returns Ads Tables Column Definitions
 */
const AdColumnDefinitions = [
  {
    name: 'Ad Format',
    table: 'ad_format',
    visibility: {
      id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'format_name',
        headerName: 'Format Name',
        editable: false,
      },
    ],
  },
  {
    name: 'Ad Pod',
    table: 'ad_pod',
    visibility: {
      id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'position',
        headerName: 'Position',
        editable: false,
      },
    ],
  },
  {
    name: 'Ad Product Type',
    table: 'ad_product_type',
    visibility: {
      id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'product_type',
        headerName: 'Product Type',
        flex: 1,
        editable: false,
      },
      {
        field: 'description',
        headerName: 'Description',
        flex: 1,
        editable: false,
      },
      {
        field: 'is_premium',
        headerName: 'Premium',
        flex: 1,
        type: 'boolean',
        editable: false,
      },
    ],
  },
  {
    name: 'Ad Product Type Ad Format',
    table: 'ad_product_type_ad_format',
    visibility: {
      id: false,
      ad_product_type_id: false,
      ad_format_id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'ad_product_type_id',
        headerName: 'Ad Product ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'product_type',
        headerName: 'Ad Product Name',
        editable: false,
        flex: 1,
      },
      {
        field: 'ad_format_id',
        headerName: 'Ad Format ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'format_name',
        headerName: 'Format Name',
        editable: false,
        flex: 1,
      },
    ],
  },
  {
    name: 'Ad Product Type Ad Pod',
    table: 'ad_product_type_ad_pod',
    visibility: {
      id: false,
      ad_product_type_id: false,
      ad_pod_id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'ad_product_type_id',
        headerName: 'Ad Product ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'product_type',
        headerName: 'Ad Product Name',
        editable: false,
        flex: 1,
      },
      {
        field: 'ad_pod_id',
        headerName: 'Ad Pod ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'ad_pod_position',
        headerName: 'Ad Pod Position',
        editable: false,
        flex: 1,
      },
    ],
  },
  {
    name: 'Ad Product Type Asset',
    table: 'ad_product_type_asset',
    visibility: {
      id: false,
      ad_product_type_id: false,
      asset_id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'ad_product_type_id',
        headerName: 'Ad Product ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'product_type',
        headerName: 'Ad Product Type',
        editable: false,
        flex: 1,
      },
      {
        field: 'asset_id',
        headerName: 'Asset ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'asset_name',
        headerName: 'Asset Name',
        editable: false,
        flex: 1,
      },
    ],
  },
  {
    name: 'Ad Product Type Device Type',
    table: 'ad_product_type_device_type',
    visibility: {
      id: false,
      ad_product_type_id: false,
      device_type_id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'ad_product_type_id',
        headerName: 'Ad Product ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'product_type',
        headerName: 'Ad Product Type',
        editable: false,
        flex: 1,
      },
      {
        field: 'device_type_id',
        headerName: 'Device Type ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'device_type',
        headerName: 'Device Type',
        editable: false,
        flex: 1,
      },
    ],
  },
  {
    name: 'Ad Product Type Platform',
    table: 'ad_product_type_platform',
    visibility: {
      id: false,
      ad_product_id: false,
      platform_id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'ad_product_type_id',
        headerName: 'Ad Product ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'product_type',
        headerName: 'Ad Product Name',
        editable: false,
        flex: 1,
      },
      {
        field: 'platform_id',
        headerName: 'Platform ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'platform_name',
        headerName: 'Platform Name',
        editable: false,
        flex: 1,
      },
    ],
  },
  {
    name: 'Ad Server',
    table: 'ad_server',
    visibility: {
      id: false,
      partner_id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'server_name',
        headerName: 'Ad Server Name',
        editable: false,
        flex: 1,
      },
      {
        field: 'acronym',
        headerName: 'Acronym',
        editable: false,
        flex: 1,
      },
      {
        field: 'partner_id',
        headerName: 'Partner ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'partner_name',
        headerName: 'Partner Name',
        editable: false,
        flex: 1,
      },
    ],
  },
  {
    name: 'Ad Spec',
    table: 'ad_spec',
    visibility: {
      id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'spec_name',
        headerName: 'Spec Name',
        editable: false,
        flex: 1,
      },
      {
        field: 'acronym',
        headerName: 'Acronym',
        editable: false,
        flex: 1,
      },
      {
        field: 'spec_version',
        headerName: 'Spec Version',
        editable: false,
      },
    ],
  },
  {
    name: 'Ad Tech',
    table: 'ad_tech',
    visibility: {
      id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'tech_name',
        headerName: 'Tech Name',
        editable: false,
        flex: 1,
      },
      {
        field: 'acronym',
        headerName: 'Acronym',
        editable: false,
        flex: 1,
      },
    ],
  },
];
export default AdColumnDefinitions;
