import React from 'react';
import styled from '@emotion/styled';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Card } from '@mui/material';
import Grid from '@mui/material/Grid';
//import DMEDLogo from '../../components/Logos/DMEDThreeLineLogo';
import ACMCubeLogo from '../../components/Logos/ACMCubeLogo';
import MainMenuActions from '../../components/Navigation/MainMenuActions';
import BrandsListing from '../../components/Brands/BrandListing';
import NavCard from './NavCard/NavCard';
import Breadcrumbs from '../../components/Navigation/Breadcrumbs';
import Container from '@mui/material/Container';
import { ACMIcon } from '../../components/Icons/ACM';

const HomeSection = styled.section`
  width: 100%;
  flex: auto;
  & .clickable {
    cursor: pointer;
  }
  & .logoLinks {
    color: inherit !important;
  }
`;

const BrandContainer = styled.span`
  display: block;
  text-align: center;
  text-shadow: 2px 2px 4px rgb(0 0 0 / 40%);
  & svg {
    max-height: 200px;
    filter: drop-shadow(2px 2px 4px rgb(0 0 0 / 40%));
  }
`;

/**
 * @function Home
 * @param props
 * @returns Home page
 */
const Home = (props: any): JSX.Element => {
  const theme = useTheme();
  const mainMenu = MainMenuActions(props);
  const homeData: Array<Object> = [];
  homeData.push(mainMenu[0]);
  const brandsData: Array<Object> = [];
  brandsData.push(mainMenu[1]);
  // eslint-disable-next-line
  const removeMain = mainMenu.shift(); //remove the first item
  // eslint-disable-next-line
  const removeBrands = mainMenu.shift(); //remove the second item
  const brandPageHandler = (): any => {
    props.onPageChange('/Brands');
  };

  return (
    <HomeSection>
      <ThemeProvider theme={theme}>
        <Box sx={{ display: 'flex', width: '100%', marginTop: '60px', p: 3 }}>
          <Breadcrumbs page="Home" />
        </Box>
        <Box sx={{ display: 'flex', width: '100%', marginTop: '10px' }}>
          <Grid container spacing={2} alignItems="flex-start" direction="row">
            <Grid item xs={0} md={4} lg={4}>
              &nbsp;
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Card sx={{ height: '100%', padding: '10px 10px 10px 10px' }}>
                <BrandContainer>
                  <ACMCubeLogo color="#007AFF" />
                  <Typography variant="h5" color="primary" sx={{ marginTop: 2, textAlign: 'center' }}>
                    Ad Capability Matrix
                  </Typography>
                </BrandContainer>
                {homeData.map((item: any) => (
                  <Typography key={item.id} variant="body2" color="text.primary" sx={{ marginTop: 2 }}>
                    <item.description />
                  </Typography>
                ))}
              </Card>
            </Grid>
            <Grid item xs={0} md={4} lg={4}>
              &nbsp;
            </Grid>
          </Grid>
        </Box>
        <Container maxWidth="lg">
          <Box sx={{ display: 'flex', width: '100%', marginTop: '10px' }}>
            <Grid container spacing={3} alignItems="flex-start" direction="row">
              <Grid item xs={12} md={6} lg={6}>
                <Grid item xs={12} md={12} lg={12} sx={{ marginTop: 1 }}>
                  <Grid container spacing={2} alignItems="stretch" direction="row" justifyContent="flex-start">
                    {mainMenu.map((item: any) => (
                      <NavCard
                        key={item.id}
                        onClick={item.onClick}
                        icon={<item.icon />}
                        longname={item.longName}
                        name={item.name}
                        description={<item.description />}
                      />
                    ))}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Typography
                  onClick={brandPageHandler}
                  variant="h5"
                  color="primary"
                  sx={{ marginTop: 1, marginBottom: 2, textAlign: 'center', cursor: 'pointer' }}
                >
                  <ACMIcon name="BrandsIcon" width={28} height={28} />
                  &nbsp;Brands
                </Typography>
                <Grid container spacing={2}>
                  <BrandsListing />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </ThemeProvider>
    </HomeSection>
  );
};
export default Home;
