/**
 * This file is required for '[[[.PackageManager]]] run sitemap' script
 * to build a sitemap.xml file in a './app/build' folder.
 * If the project does not require a sitemap.xml then route configuration
 * can be moved to any files safely, 'sitemap' script can also be removed from root package.json file.
 * Otherwise, running `[[[.PackageManager]]] run sitemap` will throw an error and a message will be shown
 * to add the file mannualy.
 */
import React from 'react';
import { Routes, Route } from '@hulu/react-router-dom';
import PageNotFound from './components/PageNotFound/PageNotFound';
import Home from './pages/home';
import AdProducts from './pages/AdProducts';
import AdProductDetails from './pages/AdProductDetails';
import Brand from './pages/Brands';
import Vendors from './pages/Vendors';
import Devices from './pages/Devices';
import Measurement from './pages/Measurement';
import Partners from './pages/Partners';
import Targeting from './pages/Targeting';
import ManageDataReferences from './pages/ManageDataReferences';
import Login from './pages/Login';

/**
 * Declarative Routing Configuration
 */
const Router = (props: any): any => {
  return (
    <Routes>
      <Route
        path="/Home"
        element={
          <Home
            onPageChange={props.onPageChange}
            onCloseMainMenu={(): any => {
              return;
            }}
          />
        }
      />
      <Route path="/AdProducts" element={<AdProducts onPageChange={props.onPageChange} />} />
      <Route path="/AdProductDetails" element={<AdProductDetails onPageChange={props.onPageChange} />} />
      <Route path="/Brands" element={<Brand />} />
      <Route path="/Vendors" element={<Vendors />} />
      <Route path="/Devices" element={<Devices />} />
      <Route path="/Measurement" element={<Measurement />} />
      <Route path="/Partners" element={<Partners />} />
      <Route path="/Targeting" element={<Targeting />} />
      <Route path="/ManageDataReferences" element={<ManageDataReferences />} />
      <Route path="/" element={<Login />} />
      {/**
       * Add more routes here:
       * <Route path="/foo" element={<FooPage />} />
       */}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};
export default Router;
