import React from 'react';
import styled from '@emotion/styled';
import { Card, CardActionArea, CardHeader, CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
//import { Card } from 'prism-ui/lib';

const IconContainer = styled.span`
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.334;
  margin-bottom: 0.35em;
  & svg {
    filter: drop-shadow(1px 1px 2px rgb(0 0 0 / 40%));
  }
`;

const NavCard = (props: any): JSX.Element => {
  return (
    <Grid item xs={12} md={6} lg={6} {...props}>
      <Card sx={{ height: '100%' }}>
        <CardActionArea onClick={props.onClick}>
          <CardHeader
            sx={{ color: 'primary.main', textShadow: '1px 1px 2px rgb(0 0 0 / 30%)' }}
            component="div"
            titleTypographyProps={{ variant: 'h5' }}
            avatar={<IconContainer>{props.icon}</IconContainer>}
            title={props.longname.length > 0 ? <abbr title={props.longname}>{props.name}</abbr> : props.name}
          />
          <Divider sx={{ borderColor: 'primary.main', borderWidth: 2, boxShadow: '2px 2px 4px rgb(0 0 0 / 40%)' }} />
          <CardContent>
            <Typography variant="body2" color="text.primary" sx={{ marginTop: 2 }} {...props}>
              {props.description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};
export default NavCard;
