import React from 'react';
import styled from '@emotion/styled';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Breadcrumbs from '../../components/Navigation/Breadcrumbs';
import BrandsListing from '../../components/Brands/BrandListing';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

const BrandSection = styled.section`
  width: 100%;
  flex: auto;
  overflow: hidden;
`;

const BrandsHome = (props: any): JSX.Element => {
  const theme = useTheme();
  const [showSubBrand, setShowSubBrand] = React.useState<boolean>(false);
  const [subBrandText, setSubBrandText] = React.useState<string>('Show Sub-Brands');
  const [brandStyle, setBrandStyle] = React.useState<any>([
    {
      brandGridXs: 6,
      brandGridMd: 4,
      brandGridLg: 3,
      brandHeight: 200,
      brandWidth: 200,
      brandMinHeight: '160px',
    },
  ]);
  const subBrandHandler = (): any => {
    if (showSubBrand) {
      setShowSubBrand(false);
      setSubBrandText('Show Sub-Brands');
      setBrandStyle([
        {
          brandGridXs: 6,
          brandGridMd: 4,
          brandGridLg: 3,
          brandHeight: 200,
          brandWidth: 200,
          brandMinHeight: '160px',
        },
      ]);
    } else {
      setShowSubBrand(true);
      setSubBrandText('Hide Sub-Brands');
      setBrandStyle([
        {
          brandGridXs: 12,
          brandGridMd: 12,
          brandGridLg: 12,
          brandHeight: 300,
          brandWidth: 300,
          brandMinHeight: '160px',
        },
      ]);
    }
  };
  return (
    <BrandSection>
      <ThemeProvider theme={theme}>
        <Box
          data-testid="app-root"
          sx={{
            display: 'flex',
            width: '100%',
            bgcolor: 'background.default !important',
            color: 'text.primary',
            borderRadius: 1,
            p: 3,
            marginTop: '60px',
          }}
        >
          <Breadcrumbs page="Brands" />
        </Box>
        <Box sx={{ display: 'flex', width: '100%', marginBottom: 2, p: 3 }}>
          <Stack spacing={2} direction="row">
            <Button onClick={subBrandHandler} variant="contained">
              {subBrandText}
            </Button>
          </Stack>
        </Box>
        <Box sx={{ display: 'flex', width: '100%', marginTop: '0px', p: 3 }}>
          <Grid container spacing={1}>
            <BrandsListing
              showSubBrand={showSubBrand}
              brandGridXs={brandStyle[0].brandGridXs}
              brandGridMd={brandStyle[0].brandGridMd}
              brandGridLg={brandStyle[0].brandGridLg}
              brandHeight={brandStyle[0].brandHeight}
              brandWidth={brandStyle[0].brandWidth}
              brandMinHeight={brandStyle[0].brandMinHeight}
              subBrandGridXs={6}
              subBrandGridMd={4}
              subBrandGridLg={3}
              subBrandHeight={200}
              subBrandWidth={200}
              subBrandMinHeight="110px"
            />
          </Grid>
        </Box>
      </ThemeProvider>
    </BrandSection>
  );
};
export default BrandsHome;
