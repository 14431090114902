/**
 * @array UserColumnDefinitions
 * @returns User Tables Column Definitions
 */
const UserColumnDefinitions = [
  {
    name: 'User',
    table: 'user',
    visibility: {
      id: false,
      created_by_id: false,
      updated_by_id: false,
      status_id: false,
    },
    columns: [
      {
        field: 'firstname',
        headerName: 'First Name',
        editable: false,
        flex: 1,
      },
      {
        field: 'lastname',
        headerName: 'Last Name',
        editable: false,
        flex: 1,
      },
      {
        field: 'status_id',
        headerName: 'Status ID',
        editable: false,
        flex: 1,
      },
      {
        field: 'status',
        headerName: 'Status',
        editable: false,
        flex: 1,
      },
      {
        field: 'email',
        headerName: 'Email',
        editable: false,
        flex: 1,
      },
      {
        field: 'last_login',
        headerName: 'Last Login',
        editable: false,
        flex: 1,
      },
    ],
  },
  {
    name: 'Status',
    table: 'status',
    visibility: {
      id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'category',
        headerName: 'Category',
        editable: false,
        flex: 1,
      },
    ],
  },
];
export default UserColumnDefinitions;
