import * as React from 'react';
import styled from '@emotion/styled';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import DMEDOneLineLogo from '../Logos/DMEDOneLineLogo';

const FooterTextContainer = styled.span`
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1.334;
  margin-left: 5px;
  padding-bottom: 0.5em;
  padding-top: 13px;
  display: block;
  float: right;
  @media (max-width: 680px) {
    float: none;
  }
`;

const FooterBrandContainer = styled.span`
  display: block;
  text-shadow: 3px 3px 4px rgb(0 0 0 / 40%);
  & svg {
    filter: drop-shadow(3px 3px 4px rgb(0 0 0 / 40%));
  }
`;

const AppBarrContainer = styled.span`
  @ .footerBar {
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0px -2px 4px -1px rgb(0 0 0 / 20%), 0px -4px 5px 0px rgb(0 0 0 / 14%), 0px -1px 10px 0px rgb(0 0 0 / 12%);
  }
`;

const PageFooter = (props: any): JSX.Element => {
  const theme = useTheme();
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBarrContainer>
          <AppBar className="footerBar" position="sticky" component="footer" sx={{ top: 'auto', bottom: 0 }}>
            <Toolbar sx={{ alignItems: 'center', justifyContent: 'center' }}>
              <Button variant="text" sx={{ my: 0, color: 'white', display: 'block', textTransform: 'none' }}>
                <FooterBrandContainer>
                  <DMEDOneLineLogo />
                  <FooterTextContainer>Ad Support Tools &copy; 2021</FooterTextContainer>
                </FooterBrandContainer>
              </Button>
            </Toolbar>
          </AppBar>
        </AppBarrContainer>
      </Box>
    </ThemeProvider>
  );
};
export default PageFooter;
