/**
 * @returns Measurement Tables Column Definitions
 */
const MeasurementColumnDefinitions = [
  {
    name: 'Measurement',
    table: 'measurement',
    visibility: {
      id: false,
      measurement_type_id: false,
      vendor_id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'measurement_name',
        headerName: 'Measurement Name',
        editable: false,
        flex: 1,
      },
      {
        field: 'measurement_type_id',
        headerName: 'Measurement Type ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'measurement_type',
        headerName: 'Measurement Type',
        editable: false,
        flex: 1,
      },
      {
        field: 'vendor_id',
        headerName: 'Vendor ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'vendor_name',
        headerName: 'Vendor Name',
        editable: false,
        flex: 1,
      },
    ],
  },
  {
    name: 'Measurement Type',
    table: 'measurement_type',
    visibility: {
      id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'category',
        headerName: 'Category',
        editable: false,
        flex: 1,
      },
    ],
  },
  {
    name: 'Vendor',
    table: 'vendor',
    visibility: {
      id: false,
      vendor_type_id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'vendor_name',
        headerName: 'Vendor Name',
        editable: false,
        flex: 1,
      },
      {
        field: 'acronym',
        headerName: 'Acronym',
        editable: false,
        flex: 1,
      },
      {
        field: 'vendor_type_id',
        headerName: 'Vendor Type ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'vendor_type',
        headerName: 'Vendor Type',
        editable: false,
        flex: 1,
      },
      {
        field: 'url',
        headerName: 'URL',
        editable: false,
        flex: 1,
      },
    ],
  },
  {
    name: 'Vendor Asset',
    table: 'vendor_asset',
    visibility: {
      id: false,
      vendor_id: false,
      asset_id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'vendor_id',
        headerName: 'Vendor ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'vendor_name',
        headerName: 'Vendor Name',
        editable: false,
        flex: 1,
      },
      {
        field: 'asset_id',
        headerName: 'Asset ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'asset_name',
        headerName: 'Asset Name',
        editable: false,
        flex: 1,
      },
    ],
  },
  {
    name: 'Vendor Type',
    table: 'vendor_type',
    visibility: {
      id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'category',
        headerName: 'Category',
        editable: false,
        flex: 1,
      },
    ],
  },
];
export default MeasurementColumnDefinitions;
