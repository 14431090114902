import * as React from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleChevronLeft, faCircleChevronRight } from '@fortawesome/free-solid-svg-icons'; // Open Panel, Close Panel

const drawerWidth = 270;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: 'rgba(0,0,0,0.5)',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

/**
 * @function LeftCollapseDrawer
 * @param props items - navigation items,
 * children - page content,
 * drawerOpen - boolean open/close state of drawer,
 * onDrawerClose - function to close the drawer,
 * onDrawerOpen - function to open the drawer
 * @returns Left collapsable drawer
 */
const LeftCollapseDrawer = (props: any): JSX.Element => {
  return (
    <Box sx={{ display: 'flex', marginTop: '25px' }}>
      <Drawer variant="permanent" open={props.drawerOpen} sx={{ marginTop: '100px' }}>
        <Toolbar />
        <Box>
          <DrawerHeader>
            <Tooltip title={props.drawerOpen ? 'Close Panel' : 'Open Panel'}>
              <IconButton id="filterPanel" onClick={props.drawerOpen ? props.onDrawerClose : props.onDrawerOpen}>
                {!props.drawerOpen ? (
                  <FontAwesomeIcon icon={faCircleChevronRight} />
                ) : (
                  <FontAwesomeIcon icon={faCircleChevronLeft} />
                )}
              </IconButton>
            </Tooltip>
          </DrawerHeader>
          <Divider />
          <React.Fragment>{props.items}</React.Fragment>
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        {props.children}
      </Box>
    </Box>
  );
};
export default LeftCollapseDrawer;
