import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import SVGAsset from '../../components/Assets/SVGAsset';

const PlatformList = (props: any): JSX.Element => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12} lg={12} sx={{ textAlign: 'full' }}>
          {props.platformData.map((platform: any, index: number): any => {
            if (props.platformIds.indexOf(platform.id) > -1) {
              return (
                <Tooltip key={platform.id + index} title={platform.platform_name}>
                  <IconButton>
                    <SVGAsset
                      title={platform.platform_name}
                      content={platform.content}
                      width={props.width ? props.width : 30}
                      height={props.height ? props.height : 30}
                    />
                  </IconButton>
                </Tooltip>
              );
            } else {
              return <span key={platform.id + index}></span>;
            }
          })}
        </Grid>
      </Grid>
    </Box>
  );
};
export default PlatformList;
