const FilterObjectArray = (listArray: any, objectKey: any, filters: any): any => {
  if (filters.length === 0) {
    return listArray;
  } else {
    return listArray.filter((element: any): any => {
      return (
        element[objectKey].filter((subElement: any): any => {
          return filters.indexOf(subElement) > -1;
        }).length === filters.length
      );
    });
  }
};
export default FilterObjectArray;
