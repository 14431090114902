import React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
//import { Card } from 'prism-ui/lib';
import SVGAsset from '../Assets/SVGAsset';
import axios from 'axios';

/**
 * @function BrandListing
 * @param props
 * @returns List of brands for landing page
 */
const BrandListing = (props: any): JSX.Element => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [brandData, setBrandData] = React.useState<any[]>([]);
  React.useEffect(() => {
    if (isLoading) {
      axios.get(`data/brand_asset_view.json`).then((res: any): any => {
        const rows: any[] = res.data['brand_asset_view'];
        //console.log('ajax rowData: ', rows);
        setBrandData(rows);
        //console.log('rows: ', rows);
      });
      //console.log('brand_asset_view: ', brandData);
      return (): any => {
        setIsLoading(false);
      };
    }
  }, [isLoading, brandData]);
  const brandClickHandler = (): any => {
    alert('click to brand detail');
    return;
  };
  const brandsStyle = {
    xs: props.brandGridXs === undefined ? 6 : props.brandGridXs,
    md: props.brandGridMd === undefined ? 4 : props.brandGridMd,
    lg: props.brandGridLg === undefined ? 3 : props.brandGridLg,
    height: props.brandHeight === undefined ? 80 : props.brandHeight,
    width: props.brandWidth === undefined ? 80 : props.brandWidth,
    minHeight: props.brandMinHeight === undefined ? '110px' : props.brandMinHeight,
  };
  const subBrandsStyle = {
    xs: props.subBrandGridXs === undefined ? 6 : props.subBrandGridXs,
    md: props.subBrandGridMd === undefined ? 4 : props.subBrandGridMd,
    lg: props.subBrandGridLg === undefined ? 3 : props.subBrandGridLg,
    height: props.subBrandHeight === undefined ? 50 : props.subBrandHeight,
    width: props.subBrandWidth === undefined ? 50 : props.subBrandWidth,
    minHeight: props.subBrandMinHeight === undefined ? '50px' : props.subBrandMinHeight,
  };
  return (
    <Grid
      sx={{ paddingLeft: 1, paddingRight: 1 }}
      container
      spacing={2}
      alignItems="stretch"
      direction="row"
      justifyContent="flex-start"
    >
      {brandData.map((brand: any): any => {
        if (brand.parent_id === null && (brand.asset_category === 'logo' || brand.asset_category === 'icon/logo')) {
          return (
            <Grid
              key={brand.id}
              item
              xs={brandsStyle.xs}
              md={brandsStyle.md}
              lg={brandsStyle.lg}
              sx={{ textAlign: 'center' }}
            >
              <Card style={{ minHeight: '110px', marginBottom: 3, marginTop: 3 }}>
                <span onClick={brandClickHandler} style={{ cursor: 'pointer' }}>
                  <SVGAsset
                    title={brand.brand_name}
                    content={brand.content}
                    width={brandsStyle.width}
                    height={brandsStyle.height}
                  />
                </span>
              </Card>
              <Grid container spacing={1}>
                {brandData.map((subBrand: any): any => {
                  if (
                    props.showSubBrand &&
                    brand.brand_id === subBrand.parent_id &&
                    subBrand.parent_id !== null &&
                    (subBrand.asset_category === 'logo' || subBrand.asset_category === 'icon/logo')
                  ) {
                    return (
                      <Grid
                        key={'Site' + subBrand.id}
                        item
                        xs={subBrandsStyle.xs}
                        md={subBrandsStyle.md}
                        lg={subBrandsStyle.lg}
                        sx={{ textAlign: 'center' }}
                      >
                        <Card
                          style={{ minHeight: '50px', color: subBrand.asset_name === 'Disney+ Logo' ? '#01147b' : '' }}
                        >
                          <SVGAsset
                            title={subBrand.asset_name}
                            content={subBrand.content}
                            width={subBrandsStyle.width}
                            height={subBrandsStyle.height}
                          />
                        </Card>
                      </Grid>
                    );
                  } else return null;
                })}
              </Grid>
            </Grid>
          );
        } else return null;
      })}
    </Grid>
  );
};
export default BrandListing;
