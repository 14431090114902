import * as React from 'react';
import TextField from '@mui/material/TextField';
import moment from 'moment';

const RecordDates = (props: any): JSX.Element => {
  console.log('record date props: ', props.data);
  const createdAt = moment(props.data.created_at).format('MM/DD/YYYY hh:mm:ss');
  const updatedAt = moment(props.data.updated_at).format('MM/DD/YYYY hh:mm:ss');
  return (
    <>
      <TextField fullWidth id="createdOn" label="Created On" variant="outlined" value={createdAt} disabled />
      <TextField fullWidth id="updatedOn" label="Updated On" variant="outlined" value={updatedAt} disabled />
    </>
  );
};
export default RecordDates;
