import CommonColumnDefinitions from './CommonColumnDefinitions';
import ActionsCell from './ActionsCell';
import AdColumnDefinitions from './AdColumnDefinitions';
import AssetColumnDefinitions from './AssetColumnDefinitions';
import BrandColumnDefinitions from './BrandColumnDefinitions';
import CreativeColumnDefinitions from './CreativeColumnDefinitions';
import MeasurementColumnDefinitions from './MeasurementColumnDefinitions';
import OperationsColumnDefinitions from './OperationsColumnDefinitions';
import PartnerColumnDefinitions from './PartnerColumnDefinitions';
import PlatformsColumnDefinitions from './PlatformsColumnDefinitions';
import TargetingColumnDefinitions from './TargetingColomunDefinitions';
import UserColumnDefinitions from './UserColumnDefinitions';
import RemoveDuplicates from '../../../helpers/RemoveDuplicates';

const TableColumnDefinitions = (props: any): any => {
  const columnDefinitions: Array<any> = [
    ...AdColumnDefinitions,
    ...AssetColumnDefinitions,
    ...BrandColumnDefinitions,
    ...CreativeColumnDefinitions,
    ...MeasurementColumnDefinitions,
    ...OperationsColumnDefinitions,
    ...PartnerColumnDefinitions,
    ...PlatformsColumnDefinitions,
    ...TargetingColumnDefinitions,
    ...UserColumnDefinitions,
  ];
  const recordProps = { onEditRecord: props.onEditRecord, onDeleteRecord: props.onDeleteRecord, ...props };
  if (props.table === undefined || props.table === 'blank') {
    return {
      table: 'blank', // Default configuration
      visibility: { id: false },
      columns: [
        {
          field: 'blank',
          headerName: 'Data References',
          editable: false,
          flex: 1,
        },
        {
          field: 'id',
          headerName: 'ID',
          type: 'number',
          editable: false,
        },
      ],
    };
  }
  const actions = {
    field: 'action',
    headerName: 'Actions',
    sortable: false,
    flex: 1,
    renderCell: (params: any, props: any): any => ActionsCell(params, props, recordProps),
  };
  const pathIndex = columnDefinitions.findIndex((x: any) => x.table === props.table);
  const combinedColumnDefnitions: Array<any> = [
    actions,
    ...columnDefinitions[pathIndex].columns,
    ...CommonColumnDefinitions,
  ];
  console.log('combinedColumnDefnitions: ', combinedColumnDefnitions);
  console.log('columns before mod: ', columnDefinitions[pathIndex].columns);
  columnDefinitions[pathIndex].columns = [...combinedColumnDefnitions];
  const filtered = RemoveDuplicates(columnDefinitions[pathIndex].columns, 'field');
  columnDefinitions[pathIndex].columns = [...filtered];
  console.log('columns after mod: ', columnDefinitions[pathIndex].columns);
  return columnDefinitions[pathIndex];
};
export default TableColumnDefinitions;
