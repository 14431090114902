import React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
// import { Card } from 'prism-ui/lib';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import SVGAsset from '../../components/Assets/SVGAsset';

const AdProductBrands = (props: any): JSX.Element => {
  const brandsStyle = {
    xs: props.brandGridXs === undefined ? 2 : props.brandGridXs,
    md: props.brandGridMd === undefined ? 3 : props.brandGridMd,
    lg: props.brandGridLg === undefined ? 2 : props.brandGridLg,
    height: props.brandHeight === undefined ? 50 : props.brandHeight,
    width: props.brandWidth === undefined ? 50 : props.brandWidth,
    minHeight: props.brandMinHeight === undefined ? '110px' : props.brandMinHeight,
  };
  return (
    <Grid container spacing={1}>
      {props.brandData.map((brand: any): any => (
        <Grid
          key={brand.id}
          item
          xs={brandsStyle.xs}
          md={brandsStyle.md}
          lg={brandsStyle.lg}
          sx={{ textAlign: 'center' }}
        >
          <Card style={{ minHeight: '50px', marginBottom: 1, marginTop: 1 }}>
            <Tooltip title={'Go to ' + brand.brand_name + ' media kit for ' + brand.product_type}>
              <IconButton onClick={(): any => props.onClickAdProductBrand(brand.id)}>
                <SVGAsset
                  title={brand.brand_name}
                  content={brand.content}
                  width={brandsStyle.width}
                  height={brandsStyle.height}
                />
              </IconButton>
            </Tooltip>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};
export default AdProductBrands;
