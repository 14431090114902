import React from 'react';
import styled from '@emotion/styled';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import TableRowsIcon from '@mui/icons-material/TableRows';
import GridViewIcon from '@mui/icons-material/GridView';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Breadcrumbs from '../../components/Navigation/Breadcrumbs';
import axios from 'axios';
import BrandSiteFilter from '../../components/Filters/BrandSiteFilter';
import AdProductFilters from './Filters/AdProductFilters';
import AdProductTileView from './AdProductTileView';
import AdProductTableView from './AdProductTableView';
import FilterObjectArray from '../../helpers/FilterObjectArray';

const AdProductsSection = styled.section`
  width: 100%;
  flex: auto;
  overflow: scroll-vertical;
`;

const ViewSelector = (props: any): JSX.Element => {
  if (props.currentView === 'tile') {
    return (
      <AdProductTileView
        adProductData={props.adProductData}
        platformData={props.platformData}
        AdProductDetailEvent={props.AdProductDetailEvent}
      />
    );
  } else {
    return (
      <AdProductTableView
        adProductData={props.adProductData}
        platformData={props.platformData}
        AdProductDetailEvent={props.AdProductDetailEvent}
      />
    );
  }
};

const AdProducts = (props: any): JSX.Element => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [isLoadingPlatform, setIsLoadingPlatform] = React.useState<boolean>(true);
  const [adProductData, setAdProductData] = React.useState<any[]>([]);
  const [platformData, setPlatformData] = React.useState<any[]>([]);
  const [brandName, setBrandName] = React.useState<string[]>([]);
  const [siteName, setSiteName] = React.useState<string[]>([]);
  const [adFormatName, setAdFormatName] = React.useState<string[]>([]);
  const [platformName, setPlatformName] = React.useState<string[]>([]);
  const [deviceTypeName, setDeviceTypeName] = React.useState<string[]>([]);
  const [currentView, setCurrentView] = React.useState<string | null>('tile');
  const [premiumChecked, setPremiumChecked] = React.useState(false);

  const premiumFilter = (array: any, value: any): any => {
    if (value) {
      return array.filter((eachObj: any): any => eachObj.is_premium === 1);
    } else {
      return array;
    }
  };
  React.useEffect(() => {
    if (isLoadingPlatform) {
      axios.get(`data/tableData/platform_asset.json`).then((res: any): any => {
        const platformRows: any[] = res.data['platform_asset'];
        console.log('ajax rowData: ', platformRows);
        setPlatformData(platformRows);
        console.log('rows: ', platformRows);
      });
      console.log('platformData: ', platformData);
      return (): any => {
        setIsLoadingPlatform(false);
      };
    }
    if (isLoading) {
      axios.get(`data/ad_product_type_list.json`).then((res: any): any => {
        const rows: any[] = res.data['ad_product_type_list'];
        console.log('ajax rowData: ', rows);
        var filteredData = rows;
        filteredData = FilterObjectArray(filteredData, 'brands', brandName);
        filteredData = FilterObjectArray(filteredData, 'sites', siteName);
        filteredData = FilterObjectArray(filteredData, 'format_names', adFormatName);
        filteredData = FilterObjectArray(filteredData, 'platform_names', platformName);
        filteredData = FilterObjectArray(filteredData, 'device_types', deviceTypeName);
        filteredData = premiumFilter(filteredData, premiumChecked);
        setAdProductData(filteredData);
      });
      console.log('adProductData: ', adProductData);
      return (): any => {
        setIsLoading(false);
      };
    }
  }, [
    isLoading,
    adProductData,
    isLoadingPlatform,
    platformData,
    brandName,
    siteName,
    adFormatName,
    platformName,
    deviceTypeName,
    premiumChecked,
  ]);

  const brandChangeHandler = (value: any): any => {
    //console.log('onBrandChange: ', value);
    setBrandName(value);
    setIsLoading(true);
  };
  const siteChangeHandler = (value: any): any => {
    //console.log('onSiteChange: ', value);
    setSiteName(value);
    setIsLoading(true);
  };
  const adFormatChangeHandler = (value: any): any => {
    //console.log('onAdFormatChange: ', value);
    setAdFormatName(value);
    setIsLoading(true);
  };
  const platformChangeHandler = (value: any): any => {
    //console.log('onPlatformChange: ', value);
    setPlatformName(value);
    setIsLoading(true);
  };
  const deviceTypeChangeHandler = (value: any): any => {
    //console.log('onDeviceTypeChange: ', value);
    setDeviceTypeName(value);
    setIsLoading(true);
  };
  const premiumChangeHandler = (value: any): any => {
    //console.log('Premium Checked at Index: ', value);
    setPremiumChecked(value);
    setIsLoading(true);
  };
  const clearFilterHandler = (): any => {
    //console.log('clear filters');
    setBrandName([]);
    setSiteName([]);
    setAdFormatName([]);
    setPlatformName([]);
    setDeviceTypeName([]);
    setIsLoading(true);
  };
  const handleView = (event: React.MouseEvent<HTMLElement>, newView: string | null): any => {
    setCurrentView(newView);
  };
  const AdProductDetailHandler = (value: any): any => {
    console.log('onDeviceTypeChange: ', value);
    props.onPageChange('/AdProductDetails/?ad_product_type_id=' + value);
  };

  return (
    <AdProductsSection>
      <ThemeProvider theme={theme}>
        <Box
          data-testid="app-root"
          sx={{
            display: 'flex',
            width: '100%',
            bgcolor: 'background.default !important',
            color: 'text.primary',
            borderRadius: 1,
            p: 3,
            marginTop: '60px',
          }}
        >
          <Breadcrumbs page="Ad Products" />
        </Box>
        <Box sx={{ display: 'flex', width: '100%', marginTop: '0px', paddingLeft: 3 }}>
          <Grid container spacing={1}>
            <Grid item xs={6} md={3} lg={2} sx={{ textAlign: 'left' }}>
              <Typography gutterBottom variant="h4" component="div">
                Ad Products
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ display: 'flex', width: '100%', marginTop: '0px', paddingLeft: 1 }}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} lg={12} sx={{ textAlign: 'left' }}>
              <BrandSiteFilter
                brandName={brandName}
                onBrandChange={brandChangeHandler}
                siteName={siteName}
                onSiteChange={siteChangeHandler}
              />
              <AdProductFilters
                adFormatName={adFormatName}
                onAdFormatChange={adFormatChangeHandler}
                platformName={platformName}
                onPlatformChange={platformChangeHandler}
                deviceTypeName={deviceTypeName}
                onDeviceTypeChange={deviceTypeChangeHandler}
                premiumChecked={premiumChecked}
                onPremiumChanged={premiumChangeHandler}
              />
              <Button onClick={clearFilterHandler}>Clear Filters</Button>
              <ToggleButtonGroup value={currentView} exclusive onChange={handleView} aria-label="text alignment">
                <ToggleButton value="tile" aria-label="Tile View">
                  <Tooltip title="Tile View">
                    <GridViewIcon />
                  </Tooltip>
                </ToggleButton>
                <ToggleButton value="list" aria-label="List View">
                  <Tooltip title="List View">
                    <TableRowsIcon />
                  </Tooltip>
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ display: 'flex', width: '100%', marginTop: '10px', paddingLeft: 2 }}>
          <Grid container spacing={1}>
            <ViewSelector
              currentView={currentView}
              adProductData={adProductData}
              platformData={platformData}
              AdProductDetailEvent={AdProductDetailHandler}
            />
          </Grid>
        </Box>
      </ThemeProvider>
    </AdProductsSection>
  );
};
export default AdProducts;
