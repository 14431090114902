/**
 * This component defines the user's session state and the object that we get from MyID
 */
import React, { createContext, ReactElement, ReactNode, useEffect, useRef, useReducer, Dispatch } from 'react';
// eslint-disable-next-line
import { DateTime } from 'luxon';
import { GetBaseURL } from '../../helpers/GetURLParams';

/**
 * @interface AuthorizationContext Defines the interface for the auth object that is sent from MyID
 */
export interface AuthorizationContext {
  id_token: string;
  session_state: string | null;
  access_token: string;
  token_type: string;
  scope: string;
  profile: {
    sub: string;
    aud: string;
    iss: string;
    iat: DateTime;
    exp: DateTime;
    email_verified: boolean;
    profile: string;
    name: string;
    nickname: string;
    'authmngr.roles': [];
    preferred_username: string;
    given_name: string;
    'id.uuid': string;
    family_name: string;
    email: string;
    'authmngr.functions': [];
  };
  expires_at: DateTime;
}

/**
 * @interface StateContext Defines the interface for the global session object
 */
export interface StateContext {
  isAuthenticated: boolean;
  authorization: AuthorizationContext | null;
  apiToken: string | null;
  persistenceType: string;
}

/**
 * @type ActionType - Defines the types uses for managing the session
 */
export type ActionType = {
  type: string;
  payload?: any;
};

/**
 * @type ContextType - Defines the types used for managing the context
 */
export type ContextType = {
  sessionState: StateContext;
  dispatch: Dispatch<ActionType>;
};
/**
 * @constructor initialState - Defines the initial state when user is not logged in
 */
export const initialState: StateContext = {
  isAuthenticated: false,
  authorization: null,
  apiToken: null,
  persistenceType: 'sessionStorage',
};
/**
 * @function stateReducer Manage actions for state
 * @param state The state context
 * @param action The action in which to manage state aand the payload to send
 * @returns current session state
 */
export const stateReducer = (state: StateContext, action: ActionType): any => {
  // Perform the global state update action type
  switch (action.type) {
    case 'SET_AUTHORIZATION':
      return {
        ...state,
        authorization: action.payload,
      };
    case 'SET_API_TOKEN':
      return {
        ...state,
        apiToken: action.payload,
      };
    case 'AUTHENTICATE_USER':
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case 'SET_PERSISTENCE':
      return {
        ...state,
        persistenceType: action.payload,
      };
    case 'PURGE_STATE':
      return initialState;
    default:
      return state;
  }
};

/**
 * @function StateProvider React Context-based Global Store with a reducer
 * and persistent saves to sessionStorage/localStorage
 * @param param0 children
 * @returns current state that wraps the other React child components
 */
export const StateProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const [sessionState, dispatch] = useReducer(stateReducer, initializeState());
  const initialRenderGlobalState = useRef(true);
  const initialRenderPersistenceType = useRef(true);

  useEffect(() => {
    /*
     populate either sessionStorage or localStorage
     data from globalState based on persistenceType
    */
    if (initialRenderGlobalState.current) {
      initialRenderGlobalState.current = false;
      return;
    }
    const getPersistenceType = sessionState.persistenceType;
    if (getPersistenceType === 'sessionStorage') {
      sessionStorage.setItem('acmSessionState', JSON.stringify(sessionState));
    } else if (getPersistenceType === 'localStorage') {
      localStorage.setItem('acmLocalState', JSON.stringify(sessionState));
    }
  }, [sessionState]);

  useEffect(() => {
    /*
     purge sessionStorage or localStorage when either is selected
    */
    if (initialRenderPersistenceType.current) {
      initialRenderPersistenceType.current = false;
      return;
    }
    const getPersistenceType = sessionState.persistenceType;
    if (getPersistenceType === 'sessionStorage') {
      sessionStorage.removeItem('acmSessionState');
    } else if (getPersistenceType === 'localStorage') {
      localStorage.removeItem('acmLocalState');
    }
  }, [sessionState.persistenceType]);

  return <stateContext.Provider value={{ sessionState, dispatch }}>{children}</stateContext.Provider>;
};

/**
 * @constructor stateContext Used for passing the state to the session provider
 */
export const stateContext = createContext({} as ContextType);
/**
 * @function initializeState Initializes the session state
 * @returns the current session state
 */
const initializeState = (): any => {
  /*
   the order in which the the data is compared is very important;
   first try to populate the state from Storage if not return initialState
  */

  if (typeof Storage !== 'undefined') {
  } else {
    throw new Error('You need to enable Storage to run this app.');
  }

  const fromLocalStorage = JSON.parse(localStorage.getItem('acmLocalState') as string);
  const fromSessionStorage = JSON.parse(sessionStorage.getItem('acmSessionState') as string);
  return fromSessionStorage || fromLocalStorage || initialState;
};
/**
 * @function RouteStore Used for setting and getting the last route
 * used by the user in case the user gets logged out
 * @param action SET and store the current route or GET the last route stored
 * @returns the last stored route or sets a route
 */
export const RouteStore = (action: string): string | void => {
  // SET router state
  if (action === 'SET') {
    // get the baseURL
    const baseRouteURL = GetBaseURL();
    // Get the current full URL from the browser
    let currentURL = window.location.href;
    // Split the rest of the URL off to get route and parameters
    let currentRoute = `/${currentURL.split(baseRouteURL)[1]}`;
    // Reset to root if route is login
    if (currentRoute === '/') {
      currentRoute = '/Home';
    }
    // Store the value in local storage
    localStorage.setItem('storedRoute', currentRoute.toString());
  } else {
    // GET stored route
    const storedRoute = localStorage.getItem('storedRoute');
    // Check to make sure that the stored route exists
    if (storedRoute !== null) {
      // If the stored last route is Login, then send them to home
      if (storedRoute === '/' || storedRoute.includes('code')) {
        // Reset to root to prevent issues
        localStorage.setItem('storedRoute', '/');
        return '/Home';
      } else {
        // return the stored route
        return storedRoute;
      }
    } else {
      return '/Home';
    }
  }
};
