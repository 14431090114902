import { createTheme } from '@mui/material/styles';

/**
 * @constructor prismLight
 * @returns Prism Light Theme Object
 */
export const prismLight = createTheme(
  {
    typography: {
      fontFamily: 'InspireTWDC, serif',
    },
    palette: {
      mode: 'light',
      info: {
        main: '#3DA779',
      },
      primary: {
        main: '#0382ff',
      },
      secondary: {
        main: '#EDEEF0',
      },
      background: {
        paper: '#FFFFFF',
        default: '#F2F2F2',
      },
      text: {
        primary: 'rgba(0, 0, 0 , 1)',
      },
    },
  },
  {
    name: 'Prism - Light',
  }
);

/**
 * @constructor prismDark
 * @returns Prism Dark Theme Object
 */
export const prismDark = createTheme(
  {
    typography: {
      fontFamily: 'InspireTWDC, serif',
    },
    palette: {
      mode: 'dark',
      primary: {
        main: '#0382ff',
      },
    },
  },
  {
    name: 'Prism - Dark',
  }
);

/**
 * @constructor strata
 * @returns Prism Strata Theme Object
 */
export const strata = createTheme(
  {
    typography: {
      fontFamily: 'InspireTWDC, serif',
    },
    palette: {
      mode: 'light',
      info: {
        main: '#43DED3',
      },
      primary: {
        main: '#4D4DD1',
      },
      secondary: {
        main: '#EDEEF0',
      },
      text: {
        primary: '#111149',
      },
      background: {
        paper: '#FFFFFF',
        default: '#E5E5E5',
      },
    },
  },
  {
    name: 'Strata',
  }
);
