import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { DataGrid, GridColDef, GridColumnVisibilityModel, GridRowModel, GridRenderCellParams } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import SVGAsset from '../../components/Assets/SVGAsset';
import PremiumProduct from './PremiumProduct';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import PlatformList from './PlatformList';

const NoRecordsFound = (props: any): any => {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      <strong>No records found.</strong>
    </Stack>
  );
};

const AdProductTableView = (props: any): JSX.Element => {
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 0,
    },
    {
      field: 'product_type',
      flex: 1,
      headerName: 'Ad Product Type',
      renderCell: (params: GridRenderCellParams): any => {
        return (
          <Stack spacing={2} direction="row">
            <Tooltip title={'Go to ' + params.row.product_type + ' Ad Product Detail'}>
              <IconButton onClick={(): any => props.AdProductDetailEvent(params.row.id)}>
                <SVGAsset title={params.row.product_type} content={params.row.content} width={50} height={50} />
              </IconButton>
            </Tooltip>
            <Button
              variant="text"
              size="small"
              color="inherit"
              sx={{ textTransform: 'none' }}
              onClick={(): any => props.AdProductDetailEvent(params.row.id)}
            >
              {params.row.product_type}
            </Button>
          </Stack>
        );
      },
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams): any => {
        return (
          <Typography gutterBottom variant="inherit" component="div">
            {params.row.description === null ? '' : params.row.description}
          </Typography>
        );
      },
    },
    {
      field: 'format_names',
      flex: 1,
      headerName: 'Ad Formats',
      sortable: false,
      renderCell: (params: GridRenderCellParams): any => (
        <Grid container spacing={1}>
          {params.value.map((format: string, index: number): any => {
            if (params.value.length === index + 1) {
              return (
                <Typography key={index} gutterBottom variant="inherit" component="span">
                  {format}&nbsp;
                </Typography>
              );
            } else {
              return (
                <Typography key={index} gutterBottom variant="inherit" component="span">
                  {format},&nbsp;
                </Typography>
              );
            }
          })}
        </Grid>
      ),
    },
    {
      field: 'platform_names',
      flex: 1,
      headerName: 'Platforms',
      sortable: false,
      renderCell: (params: GridRenderCellParams): any => (
        <PlatformList platformData={props.platformData} platformIds={params.row.platform_ids} />
      ),
    },
    {
      field: 'device_types',
      flex: 1,
      headerName: 'Device Types',
      sortable: false,
      renderCell: (params: GridRenderCellParams): any => (
        <Typography
          gutterBottom
          variant="inherit"
          component="div"
          sx={{ width: params.colDef.computedWidth, overflowWrap: 'break-word' }}
        >
          {params.value.map((format: string, index: number): any => {
            if (params.value.length === index + 1) {
              return <React.Fragment key={index}>{format}&nbsp; </React.Fragment>;
            } else {
              return <React.Fragment key={index}>{format},&nbsp;</React.Fragment>;
            }
          })}
        </Typography>
      ),
    },
    {
      field: 'brands',
      flex: 1,
      headerName: 'Brands',
      sortable: false,
      renderCell: (params: GridRenderCellParams): any => (
        <Typography
          gutterBottom
          variant="inherit"
          component="div"
          sx={{ width: params.colDef.computedWidth, overflowWrap: 'break-word' }}
        >
          {params.value.map((format: string, index: number): any => {
            if (params.value.length === index + 1) {
              return <React.Fragment key={index}>{format}&nbsp; </React.Fragment>;
            } else {
              return <React.Fragment key={index}>{format},&nbsp;</React.Fragment>;
            }
          })}
        </Typography>
      ),
    },
    {
      field: 'sites',
      flex: 1,
      headerName: 'Sites',
      sortable: false,
      renderCell: (params: GridRenderCellParams): any => (
        <Typography
          gutterBottom
          variant="inherit"
          component="div"
          sx={{ width: params.colDef.computedWidth, overflowWrap: 'break-word' }}
        >
          {params.value.map((format: string, index: number): any => {
            if (params.value.length === index + 1) {
              return <React.Fragment key={index}>{format}&nbsp; </React.Fragment>;
            } else {
              return <React.Fragment key={index}>{format},&nbsp;</React.Fragment>;
            }
          })}
        </Typography>
      ),
    },
    {
      field: 'is_premium',
      flex: 1,
      headerName: 'Premium',
      renderCell: (params: GridRenderCellParams): any => <PremiumProduct isPremium={params.value} />,
    },
  ];

  const colVisibility: GridColumnVisibilityModel = {
    id: false,
    ad_product_type_id: false,
    updated_by_id: false,
  };

  const rows: GridRowModel[] = props.adProductData;
  const data = { rows: rows, columns: columns };
  let maxRows = rows.length < 100 ? Math.floor(rows.length) : 100;
  const rowLengths = [5, 10, 25, 50, maxRows];
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 10,
    page: 0,
  });

  return (
    <Box sx={{ width: '100%' }}>
      <DataGrid
        getRowHeight={(): any => 'auto'}
        components={{ NoRowsOverlay: NoRecordsFound }}
        sx={{ paddingBottom: 10 }}
        pageSizeOptions={rowLengths}
        pagination
        autoHeight={true}
        columnVisibilityModel={colVisibility}
        loading={props.loading}
        {...data}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
      />
    </Box>
  );
};
export default AdProductTableView;
