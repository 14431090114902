import React, { Fragment } from 'react';
import { ACMIcon } from '../Icons/ACM';

const DetailPageActions = (props: any): any => {
  const detailPagesMenu = [
    {
      name: 'Ad Product Details',
      longName: '',
      id: 'adProductsNav',
      category: 'Main',
      route: '/AdProductDetails',
      onClick: (): void => {
        props.onCloseMainMenu();
        props.onPageChange('/AdProductDetails');
      },
      icon: (props: any): JSX.Element => <ACMIcon name="AdProductIcon" width={28} height={28} {...props} />,
      description: (): JSX.Element => (
        <Fragment>
          Filterable list of supported brands and sites (i.e. sub-brands) of&nbsp;
          <abbr title="Disney Media, Entertainment and Distribution">DMED</abbr> ad products and their specifications.
        </Fragment>
      ),
    },
  ];
  return detailPagesMenu;
};
export default DetailPageActions;
