const GetURLParams = (param: string): any => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const value = urlParams.get(param);
  return value;
};
export default GetURLParams;

export const GetBaseURL = (): string => {
  const getURL = window.location;
  const baseURL = `${getURL.protocol}//${getURL.host}/${getURL.pathname.split('/')[0]}`;
  return baseURL;
};
