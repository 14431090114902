import React, { useState, useEffect } from 'react';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

/**
 * @function ThemeSelector
 * @param props properties passed to the component
 * @returns Theme Selector component
 */
const ThemeSelector = (props: any): JSX.Element => {
  const theme = useTheme();
  const [themeValue, setThemeValue] = useState(1);
  //localStorage.removeItem('userTheme');

  useEffect(() => {
    const storedUserTheme = localStorage.getItem('userTheme');
    if (storedUserTheme === null) {
      localStorage.setItem('userTheme', themeValue.toString());
    } else {
      let newTheme = parseInt(storedUserTheme);
      setThemeValue(newTheme);
      props.onThemeChange(newTheme - 1);
    }
  }, [props, themeValue]);

  const themeChangeHandler = (event: React.SyntheticEvent, newThemeValue: number): void => {
    setThemeValue(newThemeValue); // set the value to highlight UI
    // Store the user's theme in local storage
    localStorage.setItem('userTheme', newThemeValue.toString());
    // Zero returns a null so items are listed 1 to 3 and minus 1 to get index
    props.onThemeChange(newThemeValue - 1);
  };

  return (
    <ThemeProvider theme={theme}>
      <Typography textAlign="left">Theme:&nbsp;</Typography>
      <ToggleButtonGroup size="small" color="primary" value={themeValue} exclusive onChange={themeChangeHandler}>
        <ToggleButton value={1}>light</ToggleButton>
        <ToggleButton value={2}>dark</ToggleButton>
        <ToggleButton value={3}>strata</ToggleButton>
      </ToggleButtonGroup>
    </ThemeProvider>
  );
};
export default ThemeSelector;
