import React from 'react';
import styled from '@emotion/styled';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
// import { Card } from 'prism-ui/lib';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '../../components/Navigation/Breadcrumbs';
import SVGAsset from '../../components/Assets/SVGAsset';
import axios from 'axios';

const MeasurementSection = styled.section`
  width: 100%;
  flex: auto;
  overflow: hidden;
`;

function sortByKey(array: any[], key: string): any {
  return array.sort((a: any, b: any): any => {
    let x = a[key];
    let y = b[key];
    return x < y ? -1 : x > y ? 1 : 0;
  });
}

const Measurement = (props: any): JSX.Element => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [partnerData, setPartnerData] = React.useState<any[]>([]);
  React.useEffect(() => {
    if (isLoading) {
      axios.get(`data/tableData/partner_asset.json`).then((res: any): any => {
        const rows: any[] = res.data['partner_asset'];
        console.log('ajax rowData: ', rows);
        let sortedRows: any[] = sortByKey(rows, 'asset_name');
        setPartnerData(sortedRows);
        console.log('rows: ', sortedRows);
      });
      console.log('partnerData: ', partnerData);
      return (): any => {
        setIsLoading(false);
      };
    }
  }, [isLoading, partnerData]);
  return (
    <MeasurementSection>
      <ThemeProvider theme={theme}>
        <Box
          data-testid="app-root"
          sx={{
            display: 'flex',
            width: '100%',
            bgcolor: 'background.default !important',
            color: 'text.primary',
            borderRadius: 1,
            p: 3,
            marginTop: '60px',
          }}
        >
          <Breadcrumbs page="Partners" />
        </Box>
        <Box sx={{ display: 'flex', width: '100%', marginTop: '0px', p: 3 }}>
          <Grid container spacing={1}>
            {partnerData.map((partner, index) => (
              <Grid key={partner.id} item xs={6} md={3} lg={2} sx={{ textAlign: 'center' }}>
                <Card style={{ minHeight: '410px' }}>
                  <SVGAsset title={partner.asset_name} content={partner.content} width={250} height={250} />
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                      {partner.partner_name}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </ThemeProvider>
    </MeasurementSection>
  );
};
export default Measurement;
