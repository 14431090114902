/**
 * @returns Creative Tables Column Definitions
 */
const CreativeColumnDefinitions = [
  {
    name: 'Aspect Ratio',
    table: 'aspect_ratio',
    visibility: {
      id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'dimension',
        headerName: 'Dimension',
        editable: false,
        flex: 1,
      },
    ],
  },
  {
    name: 'Audio Size',
    table: 'audio_size',
    visibility: {
      id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'size',
        headerName: 'Size',
        type: 'number',
        editable: false,
        flex: 1,
      },
    ],
  },
  {
    name: 'Bitrate',
    table: 'bitrate',
    visibility: {
      id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'speed',
        headerName: 'Speed',
        type: 'number',
        editable: false,
        flex: 1,
      },
    ],
  },
  {
    name: 'Codec',
    table: 'codec',
    visibility: {
      id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'codec_name',
        headerName: 'Codec Name',
        editable: false,
        flex: 1,
      },
      {
        field: 'is_audio',
        headerName: 'Audio',
        type: 'boolean',
        editable: false,
      },
    ],
  },
  {
    name: 'Container Format',
    table: 'container_format',
    visibility: {
      id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'container_format_name',
        headerName: 'Container Format Name',
        editable: false,
        flex: 1,
      },
      {
        field: 'file_extension',
        headerName: 'File Extension',
        editable: false,
        flex: 1,
      },
    ],
  },
  {
    name: 'Creative Format',
    table: 'creative_format',
    visibility: {
      id: false,
      brand_id: false,
      site_id: false,
      aspect_ratio_id: false,
      audio_codec_id: false,
      audio_size_id: false,
      bitrate_id: false,
      container_id: false,
      duration_id: false,
      frame_rate_id: false,
      manifest_format_id: false,
      resolution_id: false,
      sample_rate_id: false,
      video_codec_id: false,
      video_profile_level_id: false,
      video_rate_control_id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'brand_id',
        headerName: 'Brand ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'brand_name',
        headerName: 'Brand Name',
        editable: false,
        flex: 1,
      },
      {
        field: 'site_id',
        headerName: 'Brand ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'site_name',
        headerName: 'Site Name',
        editable: false,
        flex: 1,
      },
      {
        field: 'aspect_ratio_id',
        headerName: 'Aspect Ratio ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'apect_ratio',
        headerName: 'Aspect Ratio',
        editable: false,
        flex: 1,
      },
      {
        field: 'audio_codec_id',
        headerName: 'Audio Codec ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'audio_codec',
        headerName: 'Audio Codec',
        editable: false,
        flex: 1,
      },
      {
        field: 'audio_size_id',
        headerName: 'Audio Size ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'audio_size',
        headerName: 'Audio Size',
        editable: false,
        flex: 1,
      },
      {
        field: 'bitrate_id',
        headerName: 'Bitrate ID',
        type: 'number',
        editable: false,
        flex: 1,
      },
      {
        field: 'bitrate',
        headerName: 'Bitrate',
        editable: false,
        flex: 1,
      },
      {
        field: 'container_id',
        headerName: 'Container ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'container',
        headerName: 'Container',
        editable: false,
        flex: 1,
      },
      {
        field: 'duration_id',
        headerName: 'Duration ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'duration',
        headerName: 'Duration',
        editable: false,
        flex: 1,
      },
      {
        field: 'frame_rate_id',
        headerName: 'Frame Rate ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'frame_rate',
        headerName: 'Frame Rate',
        editable: false,
        flex: 1,
      },
      {
        field: 'manifest_format_id',
        headerName: 'Manifest Format ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'manifest_format',
        headerName: 'Manifest Format',
        editable: false,
        flex: 1,
      },
      {
        field: 'resolution_id',
        headerName: 'Resolution ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'resolution',
        headerName: 'Resolution',
        editable: false,
        flex: 1,
      },
      {
        field: 'sample_rate_id',
        headerName: 'Sample Rate ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'sample_rate',
        headerName: 'Sample Rate',
        editable: false,
        flex: 1,
      },
      {
        field: 'video_codec_id',
        headerName: 'Video Codec ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'video_codec',
        headerName: 'Video Codec',
        editable: false,
        flex: 1,
      },
      {
        field: 'video_profile_level_id',
        headerName: 'Video Profile Level ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'video_profile_level',
        headerName: 'Video Profile Level',
        editable: false,
        flex: 1,
      },
      {
        field: 'video_rate_control_id',
        headerName: 'Video Rate Control ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'video_rate_control',
        headerName: 'Video Rate Control',
        editable: false,
        flex: 1,
      },
      {
        field: 'height',
        headerName: 'Height',
        editable: false,
        flex: 1,
      },
      {
        field: 'width',
        headerName: 'Width',
        editable: false,
        flex: 1,
      },
      {
        field: 'max_file_size',
        headerName: 'Max File Size',
        type: 'number',
        editable: false,
        flex: 1,
      },
    ],
  },
  {
    name: 'Creative Restriction',
    table: 'creative_restriction',
    visibility: {
      id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'description',
        headerName: 'Description',
        editable: false,
        flex: 1,
      },
    ],
  },
  {
    name: 'Duration',
    table: 'duration',
    visibility: {
      id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'length',
        headerName: 'Length',
        editable: false,
        flex: 1,
      },
    ],
  },
  {
    name: 'Frame Rate',
    table: 'frame_rate',
    visibility: {
      id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'speed',
        headerName: 'Speed',
        editable: false,
        flex: 1,
      },
    ],
  },
  {
    name: 'Manifest Format',
    table: 'manifest_format',
    visibility: {
      id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'format_name',
        headerName: 'Format Name',
        editable: false,
        flex: 1,
      },
      {
        field: 'acronym',
        headerName: 'Acronym',
        editable: false,
        flex: 1,
      },
    ],
  },
  {
    name: 'Resolution',
    table: 'resolution',
    visibility: {
      id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'dimension',
        headerName: 'Dimension',
        editable: false,
        flex: 1,
      },
    ],
  },
  {
    name: 'Sample Rate',
    table: 'sample_rate',
    visibility: {
      id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'frequency',
        headerName: 'Frequency',
        editable: false,
        flex: 1,
      },
    ],
  },
  {
    name: 'Video Profile Level',
    table: 'video_profile_level',
    visibility: {
      id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'profile_name',
        headerName: 'Profile Name',
        editable: false,
        flex: 1,
      },
      {
        field: 'profile_level',
        headerName: 'Profile Level',
        editable: false,
        flex: 1,
      },
    ],
  },
  {
    name: 'Video Rate Control',
    table: 'video_rate_control',
    visibility: {
      id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'rate_name',
        headerName: 'Rate Name',
        editable: false,
        flex: 1,
      },
      {
        field: 'acronym',
        headerName: 'Acronym',
        editable: false,
        flex: 1,
      },
    ],
  },
];
export default CreativeColumnDefinitions;
