import React from 'react';
import styled from '@emotion/styled';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Breadcrumbs from '../../components/Navigation/Breadcrumbs';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import GetURLParams from '../../helpers/GetURLParams';
import SVGAsset from '../../components/Assets/SVGAsset';
import PlatformList from '../AdProducts/PlatformList';
import AdProductBrands from './AdProductBrands';

const AdProductSection = styled.section`
  width: 100%;
  flex: auto;
  overflow: hidden;
`;

const AdProductDetails = (props: any): JSX.Element => {
  const theme = useTheme();
  // get the ad product ID from the URL parameter

  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [isLoadingPlatform, setIsLoadingPlatform] = React.useState<boolean>(true);
  const [isLoadingBrands, setIsLoadingBrands] = React.useState<boolean>(true);
  const [adProductData, setAdProductData] = React.useState<any[]>([]);
  const [platformData, setPlatformData] = React.useState<any[]>([]);
  const [brandData, setBrandData] = React.useState<any[]>([]);

  const adProductTypeId =
    parseInt(GetURLParams('ad_product_type_id')) === null || isNaN(parseInt(GetURLParams('ad_product_type_id')))
      ? 0
      : parseInt(GetURLParams('ad_product_type_id'));

  const adProductFilter = (array: any, value: any): any => {
    if (value !== 0) {
      return array.filter((eachObj: any): any => eachObj.ad_product_type_id === value);
    } else {
      return array;
    }
  };
  React.useEffect(() => {
    const zeroAdProduct = [
      {
        id: 0,
        ad_product_type_id: 0,
        product_type: 'Unavailable Ad Product Type',
        description: 'An Ad Product Type ID needs to be passed in order to see an Ad Product.',
        is_premium: 0,
        content: '',
        asset_type_id: 0,
        asset_type: 'thumbnail',
        asset_mimetype: 'image/svg+xml',
        platform_ids: [],
        platform_names: [],
        device_type_ids: [],
        device_types: [],
        ad_format_ids: 0,
        format_names: [],
        brand_ids: [],
        brands: [],
        site_ids: [],
        sites: [],
      },
    ];
    if (isLoading) {
      axios.get(`../data/ad_product_type_list.json`).then((res: any): any => {
        const rows: any[] = res.data['ad_product_type_list'];
        var filteredData = [...zeroAdProduct, ...rows];
        filteredData = adProductFilter(filteredData, adProductTypeId);
        setAdProductData(filteredData);
      });
      return (): any => {
        setIsLoading(false);
      };
    }
    if (isLoadingPlatform) {
      axios.get(`../data/tableData/platform_asset.json`).then((res: any): any => {
        const platformRows: any[] = res.data['platform_asset'];
        setPlatformData(platformRows);
      });
      return (): any => {
        setIsLoadingPlatform(false);
      };
    }
    if (isLoadingBrands) {
      axios.get(`../data/brand_ad_product_type_asset_list.json`).then((res: any): any => {
        const brandRows: any[] = res.data['brand_ad_product_type_asset_list'];
        var filteredData = brandRows;
        filteredData = adProductFilter(filteredData, adProductTypeId);
        setBrandData(filteredData);
      });
      return (): any => {
        setIsLoadingBrands(false);
      };
    }
  }, [isLoading, isLoadingPlatform, isLoadingBrands, platformData, brandData, adProductData, adProductTypeId]);
  const AdProductBrandHandler = (value: any): any => {
    let brandRecord = [];
    brandRecord = brandData.filter((x: any): any => x.id === value);
    window.open(brandRecord[0].media_kit_url, '_blank');
  };
  if (!isLoading) {
    return (
      <AdProductSection>
        <ThemeProvider theme={theme}>
          <Box
            data-testid="app-root"
            sx={{
              display: 'flex',
              width: '100%',
              bgcolor: 'background.dark !important',
              backgroundColor: 'grey.300',
              color: 'text.primary',
              borderRadius: 0,
              paddingLeft: 3,
              paddingTop: 3,
              paddingBottom: 3,
              marginTop: '60px',
              marginLeft: 0,
            }}
          >
            <Box sx={{ display: 'flex', width: '100%', marginTop: '0px', marginBottom: '0px', p: 0 }}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={12} lg={12} sx={{ textAlign: 'left' }}>
                  <Breadcrumbs page="Ad Product Details" />
                </Grid>
                <Grid item xs={12} md={12} lg={12} sx={{ textAlign: 'left' }}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6} lg={6} sx={{ textAlign: 'left' }}>
                      <Typography variant="h2" component="div" style={{ display: 'flex' }}>
                        <SVGAsset
                          title={adProductData[0].product_type}
                          content={adProductData[0].content}
                          width={150}
                          height={150}
                        />
                        &nbsp;
                        <span style={{ paddingTop: 30, paddingBottom: 0 }}>{adProductData[0].product_type}</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} sx={{ textAlign: 'left' }}>
                      <Typography variant="h6" component="div" style={{ display: 'flex' }}>
                        Description
                      </Typography>
                      <Typography variant="body1" component="div" style={{ display: 'flex' }}>
                        <span style={{ paddingTop: 1 }}>{adProductData[0].description}</span>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', width: '100%', marginTop: '10px', paddingLeft: 2, paddingRight: 2 }}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} lg={6} sx={{ textAlign: 'left' }}>
                <Typography gutterBottom variant="h6" component="div" style={{ display: 'flex' }}>
                  Available Brands
                </Typography>
                <AdProductBrands brandData={brandData} onClickAdProductBrand={AdProductBrandHandler} />
              </Grid>
              <Grid item xs={12} md={6} lg={6} sx={{ textAlign: 'left' }}>
                <Typography gutterBottom variant="h6" component="div" style={{ display: 'flex' }}>
                  Available Platforms
                </Typography>
                <PlatformList
                  platformData={platformData}
                  platformIds={adProductData[0].platform_ids}
                  width={50}
                  height={50}
                />
              </Grid>
            </Grid>
          </Box>
        </ThemeProvider>
      </AdProductSection>
    );
  } else {
    return <></>;
  }
};
export default AdProductDetails;
