import keymirror from 'keymirror';

/*
 * logo action types
 */
export const types = keymirror({
  CLICK: null,
});

/*
 * logo action creators
 */
export function click(): { type: string } {
  return { type: types.CLICK };
}
