import React from 'react';
import styled from '@emotion/styled';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import ACMCubeLogo from '../../components/Logos/ACMCubeLogo';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useAuth } from 'oidc-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { stateContext } from '../../components/Auth/StateProvider';
import { useEffectOnce } from '../../helpers/useEffectOnce';

const LoginSection = styled.section`
  width: 100%;
  flex: auto;
  overflow: hidden;
  text-align: center;
`;

const BrandContainer = styled.span`
  display: block;
  text-align: center;
  text-shadow: 2px 2px 4px rgb(0 0 0 / 40%);
  & svg {
    max-height: 200px;
    filter: drop-shadow(2px 2px 4px rgb(0 0 0 / 40%));
  }
`;

const LoginButton = (props: any): JSX.Element => {
  // Get the current full URL from the browser
  let currentURL = window.location.href;
  if (currentURL.includes('code')) {
    return (
      <>
        <FontAwesomeIcon icon={faSpinner} className="fa-spin-pulse fa-4x" />
        <Typography variant="h5">Loading...</Typography>
      </>
    );
  } else {
    return (
      <Button variant="contained" onClick={props.onClick}>
        Login with MyID
      </Button>
    );
  }
};

const Login = (props: any): JSX.Element => {
  const auth = useAuth();
  const { sessionState } = React.useContext(stateContext);
  const SignInMyID = (): void => {
    auth.signIn();
  };
  useEffectOnce((): any => {
    // Run an interval to check for session timeout
    const backgroundCheckRedirect = setTimeout((): void => {
      let currentURL = window.location.href;
      // Make sure it has not redirected yet
      if (!sessionState.isAuthenticated && currentURL.includes('code')) {
        auth.signIn();
      }
    }, 5000);
    return function cleanup(): any {
      clearTimeout(backgroundCheckRedirect);
    };
  });
  return (
    <LoginSection>
      <Container maxWidth="sm">
        <Box
          data-testid="app-root"
          sx={{
            display: 'inline-block',
            width: '100%',
            bgcolor: 'background.default !important',
            color: 'text.primary',
            borderRadius: 1,
            p: 3,
            marginTop: '60px',
            textAlign: 'center',
          }}
        >
          <BrandContainer>
            <ACMCubeLogo color="#007AFF" />
            <Typography variant="h5" color="primary" sx={{ marginTop: 2, p: 3, textAlign: 'center' }}>
              Ad Capability Matrix
            </Typography>
          </BrandContainer>
        </Box>
        <Box sx={{ display: 'inline-block', width: '50%', marginTop: '20px', p: 3, textAlign: 'center' }}>
          <LoginButton onClick={SignInMyID} />
        </Box>
      </Container>
    </LoginSection>
  );
};
export default Login;
