import * as React from 'react';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import ListItemIcon from '@mui/material/ListItemIcon';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import axios from 'axios';
import SVGAsset from '../Assets/SVGAsset';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const BrandSiteFilter = (props: any): any => {
  const [disabledSite, setDisabledSite] = React.useState<boolean>(true);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [brandData, setBrandData] = React.useState<any[]>([]);
  React.useEffect(() => {
    if (isLoading) {
      axios.get(`data/brand_asset_view.json`).then((res: any): any => {
        const rows: any[] = res.data['brand_asset_view'];
        // Remove the logos from the list
        const filteredRows = rows.filter(function (brand): any {
          return brand.asset_type_id === 1 || brand.asset_type_id === 3;
        });
        // Set the filtered icon brand data
        setBrandData(filteredRows);
      });
      console.log('brand_asset_view: ', brandData);
      return (): any => {
        setIsLoading(false);
      };
    }
  }, [isLoading, brandData]);

  const handleBrandChange = (event: SelectChangeEvent<typeof props.brandName>): any => {
    const {
      target: { value },
    } = event;
    if (value.length > 0) {
      setDisabledSite(false);
    } else {
      setDisabledSite(true);
    }
    props.onBrandChange(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };
  const handleSiteChange = (event: SelectChangeEvent<typeof props.siteName>): any => {
    const {
      target: { value },
    } = event;
    props.onSiteChange(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  return (
    <>
      <FormControl sx={{ m: 1, width: 200 }} size="small">
        <InputLabel id="demo-multiple-chip-label">Brand</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={props.brandName}
          onChange={handleBrandChange}
          input={<OutlinedInput id="brandFilter" label="Brand" />}
          renderValue={(selected): any => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value: any, index: number): any => (
                <Chip key={index} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {brandData.map((brand: any, index: number): any => {
            if (brand.parent_id === null) {
              return (
                <MenuItem key={brand.id} value={brand.brand_name}>
                  <Checkbox checked={props.brandName.indexOf(brand.brand_name) > -1} />
                  <Tooltip title={brand.brand_name}>
                    <ListItemIcon>
                      <SVGAsset title={brand.brand_name} content={brand.content} width={50} height={50} />
                    </ListItemIcon>
                  </Tooltip>
                </MenuItem>
              );
            } else {
              return false;
            }
          })}
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1, width: 200 }} disabled={disabledSite} size="small">
        <InputLabel id="demo-multiple-chip-label">Site</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={props.siteName}
          onChange={handleSiteChange}
          input={<OutlinedInput id="siteFilter" label="Site" />}
          renderValue={(selected: any): any => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value: any): any => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {brandData.map((brand: any): any => {
            if (brand.parent_id !== null && props.brandName.indexOf(brand.parent_brand) > -1) {
              return (
                <MenuItem key={brand.id} value={brand.brand_name}>
                  <Checkbox checked={props.siteName.indexOf(brand.brand_name) > -1} />
                  <Tooltip title={brand.brand_name}>
                    <ListItemIcon>
                      <SVGAsset title={brand.brand_name} content={brand.content} width={50} height={50} />
                    </ListItemIcon>
                  </Tooltip>
                </MenuItem>
              );
            } else {
              return false;
            }
          })}
        </Select>
      </FormControl>
    </>
  );
};
export default BrandSiteFilter;
