import { types } from '../actions/hint';

type HintState = {
  siteStatusCode: number;
};

export const initialState: HintState = {
  siteStatusCode: 0,
};

const hint = (state = initialState, action: any): HintState => {
  switch (action.type) {
    case types.SET_SITE_STATUS: {
      return { ...state, siteStatusCode: action.status };
    }
    default:
      return state;
  }
};

export default hint;
