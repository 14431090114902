import keymirror from 'keymirror';
import axios from 'axios';

import config from '@hulu/env-config';

/*
 * hint action types
 */
export const types = keymirror({
  SET_SITE_STATUS: null,
});

/*
 * hint action creators
 */
export function setSiteStatusCode(response: any): { type: string; status: string } {
  return {
    type: types.SET_SITE_STATUS,
    status: response.status,
  };
}

/*
 * hint thunks
 */
export function getSiteStatus() {
  return function getStatus(dispatch: Function): Promise<any> {
    const statuses = [2, 4, 5];
    const randStatus = statuses[Math.floor(Math.random() * statuses.length)];
    return axios.get(`${config.REACT_APP_EXAMPLE_API_URL}/${randStatus}00`).then(
      (res) => dispatch(setSiteStatusCode(res)),
      (error) => {
        const status = error.response ? error.response.status : -1;
        dispatch(setSiteStatusCode({ status }));
      }
    );
  };
}
