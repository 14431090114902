import React from 'react';
import styled from '@emotion/styled';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Breadcrumbs from '../../components/Navigation/Breadcrumbs';
import Grid from '@mui/material/Grid';

const TargetingSection = styled.section`
  width: 100%;
  flex: auto;
  overflow: hidden;
`;

const Targeting = (props: any): JSX.Element => {
  const theme = useTheme();
  return (
    <TargetingSection>
      <ThemeProvider theme={theme}>
        <Box
          data-testid="app-root"
          sx={{
            display: 'flex',
            width: '100%',
            bgcolor: 'background.default !important',
            color: 'text.primary',
            borderRadius: 1,
            p: 3,
            marginTop: '60px',
          }}
        >
          <Breadcrumbs page="Targeting" />
        </Box>
        <Box sx={{ display: 'flex', width: '100%', marginTop: '0px', p: 3 }}>
          <Grid container spacing={1}>
            <Grid item xs={6} md={3} lg={2} sx={{ textAlign: 'left' }}>
              Targeting stuff
            </Grid>
          </Grid>
        </Box>
      </ThemeProvider>
    </TargetingSection>
  );
};
export default Targeting;
