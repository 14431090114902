const RemoveDuplicates = (originalArray: any, prop: any): any => {
  var newArray: any = [];
  var lookupObject: any = {};

  for (var i in originalArray) {
    lookupObject[originalArray[i][prop]] = originalArray[i];
  }

  for (i in lookupObject) {
    newArray.push(lookupObject[i]);
  }
  return newArray;
};
export default RemoveDuplicates;
