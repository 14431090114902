import React, { SVGAttributes } from 'react';
import styled from '@emotion/styled';
import cx from 'classnames';

const Container = styled.svg`
  color: currentColor;
`;

const ACMCubeLogoLogo = React.forwardRef(
  ({ className, ...props }: SVGAttributes<SVGSVGElement>, ref?: React.Ref<SVGSVGElement>) => {
    return (
      <Container
        viewBox="0 0 360 360"
        fill="currentcolor"
        width={props.width}
        height={props.height}
        aria-hidden="true"
        ref={ref}
        className={cx('TrekMonochromeLogo-root', className)}
        {...props}
      >
        <title>Ad Capability Matrix Icon</title>
        <path
          d="m255 95.8-24.2 14.5-77.9-46 25.2-14.8 76.9 46.3zm80.4 5.1v161c0 5.1-2.7 9.8-7 12.4l-141 83.7c-4.5 2.7-10.2 2.7-14.7 0l-141-83.7c-4.4-2.6-7-7.3-7-12.4v-164.3c0-5.1 2.7-9.8 7.1-12.4l141.1-83.2c4.6-2.7 10.3-2.6 14.8 0.1l140.8 86.5c4.3 2.6 6.9 7.3 6.9 12.3zm-162.1 87.5-133.4-78.2v154.3l133.4 77.6v-62.3l-28.3-15.2v30l-79.1-46v-91.5l79.1 46.3v30.2l28.3 16.4v-61.6zm137.8-92.6-132.9-77.9-132.9 78.2 27.2 16.1 51.3-31.7 79.1 47.2-52.8 30.3 26.4 15.6 134.6-77.8zm9.7 14.4-132.8 76.4v155.5l26.7-15.9v-124.6l26.1-16.1v125.3l27.9-16.6v-123l26.1-16.1v123.7l26-15.4v-153.2z"
          style={{ fill: props.color }}
        />
      </Container>
    );
  }
);
export default ACMCubeLogoLogo;
