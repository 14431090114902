import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TooltipIconButton from '../Tooltip/TooltipIconButton';
import MainMenuActions from './MainMenuActions';

const MenuItemContainer = styled.span`
  display: block;
  text-shadow: 1px 1px 2px rgb(0 0 0 / 30%);
  & svg {
    filter: drop-shadow(1px 1px 2px rgb(0 0 0 / 30%));
  }
`;

/**
 * @function MainNav
 * @param props
 * @returns the main navigation for Ad Capability Matrix
 */
const MainNav = (props: any): JSX.Element => {
  /* Initial list of navigation items with actions */
  const mainMenu = MainMenuActions(props);

  return (
    <Fragment>
      <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={props.onOpenMainMenu}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={props.menuState}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={Boolean(props.menuState)}
          onClose={props.onOpenMainMenu}
          sx={{
            display: { xs: 'block', md: 'block' },
          }}
        >
          {mainMenu.map((item: any) => (
            <MenuItem key={item.id} onClick={item.onClick}>
              <item.icon />
              &nbsp;
              <Typography textAlign="center">
                {item.longName.length > 0 ? <abbr title={item.longName}>{item.name}</abbr> : item.name}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box>
      <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
        {mainMenu.map((item: any) => (
          <TooltipIconButton
            key={item.id}
            id={item.id}
            onClick={item.onClick}
            variant="text"
            tooltip={item.longName.length > 0 ? item.longName : item.name}
          >
            <MenuItemContainer>
              <item.icon />
            </MenuItemContainer>
          </TooltipIconButton>
        ))}
      </Box>
    </Fragment>
  );
};
export default MainNav;
