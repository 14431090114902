import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import ListItemIcon from '@mui/material/ListItemIcon';
import SVGAsset from '../Assets/SVGAsset';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, personName: readonly string[], theme: Theme): any {
  return {
    fontWeight:
      personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

const MultiSelectFilter = (props: any): any => {
  const theme = useTheme();
  const handleChange = (event: SelectChangeEvent<typeof props.selectName>): any => {
    const {
      target: { value },
    } = event;
    props.onSelectChange(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  return (
    <>
      <FormControl sx={{ m: 1, width: 200 }} size="small">
        <InputLabel id="demo-multiple-chip-label">{props.label}</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={props.selectName}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected: any): any => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value: any): any => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {props.data.map((item: any): any => {
            if (props.hasIcon) {
              return (
                <MenuItem
                  key={item.id}
                  value={item[props.node]}
                  style={getStyles(item[props.node], props.selectName, theme)}
                >
                  <ListItemIcon>
                    <SVGAsset title={item[props.node]} content={item.content} width={30} height={30} />
                  </ListItemIcon>
                  {item[props.node]}
                </MenuItem>
              );
            } else {
              return (
                <MenuItem
                  key={item.id}
                  value={item[props.node]}
                  style={getStyles(item[props.node], props.selectName, theme)}
                >
                  {item[props.node]}
                </MenuItem>
              );
            }
          })}
        </Select>
      </FormControl>
    </>
  );
};
export default MultiSelectFilter;
