import React, { useState, useCallback } from 'react';
import { useLocation, useNavigate } from '@hulu/react-router-dom';
import { MyIDProvider } from './components/Auth/MyIDProvider';
import SessionManager from './components/Auth/SessionManager';
import { hot } from 'react-hot-loader';
//import { Main } from '@hulu/margaret-plugins';
import { Global, css } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import NavBar from './components/Navigation/NavBar';
import Routes from './routes';
import { prismDark, prismLight, strata } from './components/Theme/ThemeList';
//import './App.css';
import PageFooter from './components/Layout/PageFooter';
import { StateProvider } from './components/Auth/StateProvider';

/**
 * @function Navigation Used to show the navigation when logged in
 * @param props passed properties to the navigation component
 * @returns returns the navigation if logged in
 */
const Navigation = (props: any): JSX.Element => {
  //const { sessionState } = useContext(stateContext);
  let location = useLocation();
  if (location.pathname === '/') {
    return <div>&nbsp;</div>;
  } else {
    return <NavBar onThemeChange={props.onThemeChange} theme={props.themeColor} onPageChange={props.onPageChange} />;
  }
};
/**
 * @function App Main application component
 * @param props properties passed to the App component
 * @returns Main application component
 */
const App = (props: any): JSX.Element => {
  const themes = [prismLight, prismDark, strata];
  const [themeColor, setThemeColor] = useState(themes[0]);
  const [loadingSession, setLoadingSession] = useState<boolean>(true);

  /**
   * @function themeChangeHandler
   * @param newThemeValue passes the the index of the theme
   * menu item and chooses the theme from the themes array.
   */
  const themeChangeHandler = (newThemeValue: number): void => {
    //console.log('newThemeValue at App: ', newThemeValue);
    setThemeColor(themes[newThemeValue]);
  };
  // Passed to the navigation component to navigate the site
  const navigate = useNavigate();
  const pageChangeHandler = useCallback((page: string) => navigate(page, { replace: true }), [navigate]);

  const GlobalStyle = css`
    body {
      margin: 0;
      padding: 0;
      width: 100%;
      box-sizing: border-box;
      font-family: Graphik, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-display: fallback;
      background-color: ${themeColor.palette.background.default} !important;
      color: green;
    }

    code {
      font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    }

    *,
    *:before,
    *:after {
      box-sizing: inherit;
    }
  `;
  //console.log('theme: ', themeColor);
  return (
    <ThemeProvider theme={themeColor}>
      <StateProvider>
        <MyIDProvider onPageChange={pageChangeHandler}>
          <SessionManager
            onPageChange={pageChangeHandler}
            loadingSession={loadingSession}
            setLoadingSession={setLoadingSession}
          >
            <Navigation onThemeChange={themeChangeHandler} theme={themeColor} onPageChange={pageChangeHandler} />
            <Global styles={GlobalStyle} />
            <Box
              data-testid="app-root"
              sx={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: 'background.default !important',
                color: 'text.primary',
                borderRadius: 1,
                paddingTop: 2,
                marginBottom: 20,
              }}
            >
              <Routes onPageChange={pageChangeHandler} />
            </Box>
            <Box
              sx={{
                display: 'flex',
                position: 'fixed',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 30,
                bottom: 0,
              }}
            >
              <PageFooter />
            </Box>
          </SessionManager>
        </MyIDProvider>
      </StateProvider>
    </ThemeProvider>
  );
};
export default hot(module)(App);
