import React, { useState, MouseEvent, Fragment } from 'react';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

/**
 * @function TooltipButton
 * @param props
 * @returns A Button with a tooltip
 */
const TooltipIconButton = (props: any): JSX.Element => {
  const [popoverState, setPopoverState] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>): void => {
    setPopoverState(event.currentTarget);
  };

  const handlePopoverClose = (): void => {
    setPopoverState(null);
  };

  const open = Boolean(popoverState);

  return (
    <Fragment>
      <IconButton
        size={props.size}
        color="inherit"
        aria-label={props.tooltip}
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        onClick={props.onClick}
        sx={{ mr: 2 }}
      >
        {props.children}
      </IconButton>
      <Popover
        id={props.id}
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={popoverState}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>{props.tooltip}</Typography>
      </Popover>
    </Fragment>
  );
};
export default TooltipIconButton;
