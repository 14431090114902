import * as React from 'react';
import { styled } from '@mui/material/styles';
import styles from '@emotion/styled';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faRectangleAd,
  faBullseye,
  faHandshakeAngle,
  faImage,
  faRuler,
  faClapperboard,
  faTags,
  faIndustry,
  faLayerGroup,
  faSquare,
  faUsersGear,
} from '@fortawesome/free-solid-svg-icons';
import AdColumnDefinitions from '../../../components/Table/TableColumnDefinitions/AdColumnDefinitions';
import AssetColumnDefinitions from '../../../components/Table/TableColumnDefinitions/AssetColumnDefinitions';
import BrandColumnDefinitions from '../../../components/Table/TableColumnDefinitions/BrandColumnDefinitions';
import CreativeColumnDefinitions from '../../../components/Table/TableColumnDefinitions/CreativeColumnDefinitions';
import MeasurementColumnDefinitions from '../../../components/Table/TableColumnDefinitions/MeasurementColumnDefinitions';
import OperationsColumnDefinitions from '../../../components/Table/TableColumnDefinitions/OperationsColumnDefinitions';
import PartnerColumnDefinitions from '../../../components/Table/TableColumnDefinitions/PartnerColumnDefinitions';
import PlatformsColumnDefinitions from '../../../components/Table/TableColumnDefinitions/PlatformsColumnDefinitions';
import TargetingColumnDefinitions from '../../../components/Table/TableColumnDefinitions/TargetingColomunDefinitions';
import UserColumnDefinitions from '../../../components/Table/TableColumnDefinitions/UserColumnDefinitions';

const sectionList = [
  {
    name: 'Ads',
    id: 'adsPanel',
    icon: (props: any): JSX.Element => (
      <FontAwesomeIcon icon={faRectangleAd} mask={faSquare} transform="shrink-5 down-1" {...props} />
    ),
    tables: [...AdColumnDefinitions], // Use the data from the column definitions for menu
  },
  {
    name: 'Assets',
    id: 'assetsPanel',
    icon: (props: any): JSX.Element => (
      <FontAwesomeIcon icon={faImage} mask={faSquare} transform="shrink-5 down-1" {...props} />
    ),
    tables: [...AssetColumnDefinitions], // Use the data from the column definitions for menu
  },
  {
    name: 'Brands',
    id: 'brandsPanel',
    icon: (props: any): JSX.Element => (
      <FontAwesomeIcon icon={faTags} mask={faSquare} transform="shrink-5 down-1" {...props} />
    ),
    tables: [...BrandColumnDefinitions], // Use the data from the column definitions for menu
  },
  {
    name: 'Creatives',
    id: 'creativesPanel',
    icon: (props: any): JSX.Element => (
      <FontAwesomeIcon icon={faClapperboard} mask={faSquare} transform="shrink-5 down-1" {...props} />
    ),
    tables: [...CreativeColumnDefinitions], // Use the data from the column definitions for menu
  },
  {
    name: 'Measurement',
    id: 'measurementPanel',
    icon: (props: any): JSX.Element => (
      <FontAwesomeIcon icon={faRuler} mask={faSquare} transform="shrink-5 down-1" {...props} />
    ),
    tables: [...MeasurementColumnDefinitions], // Use the data from the column definitions for menu
  },
  {
    name: 'Operations',
    id: 'operationsPanel',
    icon: (props: any): JSX.Element => (
      <FontAwesomeIcon icon={faIndustry} mask={faSquare} transform="shrink-5 down-1" {...props} />
    ),
    tables: [...OperationsColumnDefinitions], // Use the data from the column definitions for menu
  },
  {
    name: 'Partners',
    id: 'partnersPanel',
    icon: (props: any): JSX.Element => (
      <FontAwesomeIcon icon={faHandshakeAngle} mask={faSquare} transform="shrink-5 down-1" {...props} />
    ),
    tables: [...PartnerColumnDefinitions], // Use the data from the column definitions for menu
  },
  {
    name: 'Platforms',
    id: 'platformsPanel',
    icon: (props: any): JSX.Element => (
      <FontAwesomeIcon icon={faLayerGroup} mask={faSquare} transform="shrink-5 down-1" {...props} />
    ),
    tables: [...PlatformsColumnDefinitions], // Use the data from the column definitions for menu
  },
  {
    name: 'Targeting',
    id: 'targetingPanel',
    icon: (props: any): JSX.Element => (
      <FontAwesomeIcon icon={faBullseye} mask={faSquare} transform="shrink-5 down-1" {...props} />
    ),
    tables: [...TargetingColumnDefinitions], // Use the data from the column definitions for menu
  },
  {
    name: 'Users',
    id: 'usersPanel',
    icon: (props: any): JSX.Element => (
      <FontAwesomeIcon icon={faUsersGear} mask={faSquare} transform="shrink-5 down-1" {...props} />
    ),
    tables: [...UserColumnDefinitions], // Use the data from the column definitions for menu
  },
];

const TableMenuStyle = styles.div`
  .item {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .item div {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .item span {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 12px;
  }
`;

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  })
);

const TableMenu = (props: any): JSX.Element => {
  const [expanded, setExpanded] = React.useState<string | false>('panel1');

  const handleChange =
    (panel: string): any =>
    (event: React.SyntheticEvent, newExpanded: boolean): any => {
      setExpanded(newExpanded ? panel : false);
      if (props.drawerOpen === false) {
        props.onDrawerOpen();
      }
    };
  const handleTableChange =
    (tableObj: any): any =>
    (event: React.SyntheticEvent, newExpanded: boolean): any => {
      event.preventDefault();
      props.onSelectedTable(tableObj);
    };
  return (
    <TableMenuStyle>
      {sectionList.map((item: any, index: number): any => (
        <Accordion key={index} expanded={expanded === item.id && props.drawerOpen} onChange={handleChange(item.id)}>
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ display: props.drawerOpen ? 'inline-block' : 'none', color: '#0382ff' }} />
            }
            aria-controls={item.id + '-content'}
            id={item.id + '-header'}
            sx={{ color: '#0382ff', marginTop: 0, marginBottom: 0 }}
            className="item"
          >
            <Tooltip title={item.name}>
              <IconButton sx={{ color: '#0382ff' }}>
                <item.icon className="fa-lg" />
              </IconButton>
            </Tooltip>
            <Typography
              variant="body1"
              component="span"
              sx={{
                marginTop: 0.5,
                marginBottom: 0.5,
                display: props.drawerOpen ? 'inline-block' : 'none',
                fontSize: 18,
              }}
            >
              {item.name}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              <nav aria-label={item.name + ' tables'}>
                <List>
                  {item.tables.map((subitem: any, index: number): any => {
                    return (
                      <ListItem key={index} disablePadding>
                        <ListItemButton onClick={handleTableChange(subitem)}>
                          <ListItemText
                            sx={subitem.name === props.selectedTable ? { fontWeight: 800 } : { fontWeight: 400 }}
                            primary={subitem.name}
                            disableTypography={true}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
              </nav>
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
    </TableMenuStyle>
  );
};
export default TableMenu;
