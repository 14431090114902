import React from 'react';
import styled from '@emotion/styled';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
// import { Card } from 'prism-ui/lib';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '../../components/Navigation/Breadcrumbs';
import SVGAsset from '../../components/Assets/SVGAsset';
import axios from 'axios';

const DevicesSection = styled.section`
  width: 100%;
  flex: auto;
  overflow: hidden;
`;

const Devices = (props: any): JSX.Element => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [brandData, setBrandData] = React.useState<any[]>([]);
  React.useEffect(() => {
    if (isLoading) {
      axios.get(`data/tableData/platform_asset.json`).then((res: any): any => {
        const rows: any[] = res.data['platform_asset'];
        console.log('ajax rowData: ', rows);
        setBrandData(rows);
        console.log('rows: ', rows);
      });
      console.log('adProductData: ', brandData);
      return (): any => {
        setIsLoading(false);
      };
    }
  }, [isLoading, brandData]);
  return (
    <DevicesSection>
      <ThemeProvider theme={theme}>
        <Box
          data-testid="app-root"
          sx={{
            display: 'flex',
            width: '100%',
            bgcolor: 'background.default !important',
            color: 'text.primary',
            borderRadius: 1,
            p: 3,
            marginTop: '40px',
          }}
        >
          <Breadcrumbs page="Devices" />
        </Box>
        <Box sx={{ display: 'flex', width: '100%', marginTop: '0px' }}>
          <Grid container spacing={1}>
            {brandData.map((product, index) => (
              <Grid key={product.id} item xs={6} md={3} lg={2} sx={{ textAlign: 'center' }}>
                <Card style={{ minHeight: '410px' }}>
                  <SVGAsset title={product.asset_name} content={product.content} width={250} height={250} />
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                      {product.platform_name}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </ThemeProvider>
    </DevicesSection>
  );
};
export default Devices;
