import React from 'react';
import * as Icon from 'react-feather';

const AdminNavActions = (props: any): any => {
  const profileMenu = [
    {
      name: 'Manage Data References',
      longName: '',
      id: 'referencesDataNav',
      category: 'Admin',
      route: '/ManageDataReferences',
      onClick: (): void => {
        props.onCloseAdminMenu();
        props.onPageChange('/ManageDataReferences');
      },
      icon: (props: any): JSX.Element => <Icon.Database />,
    },
    {
      name: 'Audits',
      longName: '',
      id: 'auditsNav',
      category: 'Admin',
      route: '/Audits',
      onClick: (): void => {
        //props.onCloseAdminMenu();
        //props.onPageChange('/Audits');
        console.log('does not exist yet');
      },
      icon: (props: any): JSX.Element => <Icon.List />,
    },
  ];
  return profileMenu;
};
export default AdminNavActions;
