import * as React from 'react';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import MUIBreadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import MainMenuActions from './MainMenuActions';
import AdminNavAction from './AdminNavActions';
import DetailPageActions from './DetailPageActions';
import SettingsIcon from '@mui/icons-material/Settings';

const IconContainer = styled.span`
  margin-right: 10px;
  & .breadcrumbIcon {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 1em;
    height: 1em;
    display: inline-block;
    fill: currentColor;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: inherit;
    margin-right: 4px;
    color: text.primary;
  }
`;

const Breadcrumbs = (props: any): JSX.Element => {
  const mainMenu = MainMenuActions(props);
  const adminMenu = AdminNavAction(props);
  const detailMenu = DetailPageActions(props);
  // Create new menu array hold all the menu items.
  const menuItems = [...mainMenu, ...adminMenu, ...detailMenu];
  //console.log('menuItems: ', menuItems);
  const navIndex = menuItems.findIndex((x: any) => x.name === (props.page === undefined ? 'Home' : props.page));
  //console.log('props.page: ', props.page);
  const homePage = mainMenu[0];
  const navPage = menuItems[navIndex];
  if (props.page === 'Home') {
    return (
      <MUIBreadcrumbs aria-label="breadcrumb">
        <Typography sx={{ display: 'flex', alignItems: 'center' }} color="text.primary">
          <IconContainer>
            <homePage.icon className="breadcrumbIcon" />
          </IconContainer>
          Home
        </Typography>
      </MUIBreadcrumbs>
    );
  } else {
    if (navPage.category === 'Admin') {
      if (navPage.route === '/ManageDataReferences') {
        return (
          <MUIBreadcrumbs aria-label="breadcrumb">
            <Link underline="hover" sx={{ display: 'flex', alignItems: 'center' }} color="inherit" href="/">
              <IconContainer>
                <homePage.icon className="breadcrumbIcon" />
              </IconContainer>
              Home
            </Link>
            <Typography sx={{ display: 'flex', alignItems: 'center' }} color="text.primary">
              <IconContainer>
                <SettingsIcon className="breadcrumbIcon" />
              </IconContainer>
              Administration
            </Typography>
            <Typography sx={{ display: 'flex', alignItems: 'center' }} color="text.primary">
              <IconContainer>
                <navPage.icon className="breadcrumbIcon" />
              </IconContainer>
              {navPage.longName.length > 0 ? <abbr title={navPage.longName}>{navPage.name}</abbr> : navPage.name}
            </Typography>
            <Typography sx={{ display: 'flex', alignItems: 'center' }} color="text.primary">
              {props.subPage === '' ? <em>Select Table on Left</em> : props.subPage}
            </Typography>
          </MUIBreadcrumbs>
        );
      } else {
        return (
          <MUIBreadcrumbs aria-label="breadcrumb">
            <Link underline="hover" sx={{ display: 'flex', alignItems: 'center' }} color="inherit" href="/">
              <IconContainer>
                <homePage.icon className="breadcrumbIcon" />
              </IconContainer>
              Home
            </Link>
            <Typography sx={{ display: 'flex', alignItems: 'center' }} color="text.primary">
              <IconContainer>
                <SettingsIcon className="breadcrumbIcon" />
              </IconContainer>
              Administration
            </Typography>
            <Typography sx={{ display: 'flex', alignItems: 'center' }} color="text.primary">
              <IconContainer>
                <navPage.icon className="breadcrumbIcon" />
              </IconContainer>
              {navPage.longName.length > 0 ? <abbr title={navPage.longName}>{navPage.name}</abbr> : navPage.name}
            </Typography>
          </MUIBreadcrumbs>
        );
      }
    } else {
      if (navPage.route === '/AdProductDetails') {
        return (
          <MUIBreadcrumbs aria-label="breadcrumb">
            <Link underline="hover" sx={{ display: 'flex', alignItems: 'center' }} color="inherit" href="/">
              <IconContainer>
                <homePage.icon className="breadcrumbIcon" />
              </IconContainer>
              Home
            </Link>
            <Link underline="hover" sx={{ display: 'flex', alignItems: 'center' }} color="inherit" href="/AdProducts">
              <IconContainer>
                <navPage.icon className="breadcrumbIcon" />
              </IconContainer>
              Ad Products
            </Link>
            <Typography sx={{ display: 'flex', alignItems: 'center' }} color="text.primary">
              {navPage.longName.length > 0 ? <abbr title={navPage.longName}>{navPage.name}</abbr> : navPage.name}
            </Typography>
          </MUIBreadcrumbs>
        );
      } else {
        return (
          <MUIBreadcrumbs aria-label="breadcrumb">
            <Link underline="hover" sx={{ display: 'flex', alignItems: 'center' }} color="inherit" href="/">
              <IconContainer>
                <homePage.icon className="breadcrumbIcon" />
              </IconContainer>
              Home
            </Link>
            <Typography sx={{ display: 'flex', alignItems: 'center' }} color="text.primary">
              <IconContainer>
                <navPage.icon className="breadcrumbIcon" />
              </IconContainer>
              {navPage.longName.length > 0 ? <abbr title={navPage.longName}>{navPage.name}</abbr> : navPage.name}
            </Typography>
          </MUIBreadcrumbs>
        );
      }
    }
  }
};
export default Breadcrumbs;
