import React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
// import { Card } from 'prism-ui/lib';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import SVGAsset from '../../components/Assets/SVGAsset';
import * as Icon from 'react-feather';
import PremiumProduct from './PremiumProduct';
import PlatformList from './PlatformList';

const AdProductTileView = (props: any): JSX.Element => {
  let recordLength = props.adProductData.length;
  console.log('record length1: ', props.adProductData.length);
  if (recordLength === 0) {
    return (
      <Grid item xs={12} md={12} lg={12} sx={{ textAlign: 'center', minHeight: '40px' }}>
        <Card style={{ minHeight: '20px' }}>
          <CardContent>
            <Typography gutterBottom variant="inherit" component="div">
              <strong>No records found.</strong>
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    );
  } else {
    return (
      <Grid container spacing={1}>
        {props.adProductData.map((product: any) => {
          console.log('record length2: ', recordLength);
          return (
            <Grid key={product.ad_product_type_id} item xs={6} md={3} lg={3} sx={{ textAlign: 'center' }}>
              <Card style={{ minHeight: '370px' }}>
                <Tooltip title={'Go to ' + product.product_type + ' Ad Product Detail'}>
                  <IconButton onClick={(): any => props.AdProductDetailEvent(product.id)}>
                    <SVGAsset title={product.product_type} content={product.content} width={150} height={150} />
                  </IconButton>
                </Tooltip>
                <CardContent>
                  <Typography gutterBottom variant="inherit" component="div">
                    <strong>{product.product_type}</strong>
                    <Tooltip title={product.description === null ? '' : product.description}>
                      <IconButton>
                        <Icon.Info />
                      </IconButton>
                    </Tooltip>
                    <PremiumProduct isPremium={product.is_premium} />
                  </Typography>
                  <PlatformList platformData={props.platformData} platformIds={product.platform_ids} />
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    );
  }
};
export default AdProductTileView;
