import * as React from 'react';
import styled from '@emotion/styled';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { DataGrid, GridColDef, GridRowModel, GridColumnVisibilityModel } from '@mui/x-data-grid';
import LeftCollapseDrawer from '../../components/Layout/LeftCollapseDrawer';
import Breadcrumbs from '../../components/Navigation/Breadcrumbs';
import TableMenu from './TableMenu/TableMenu';
import TableColumnDefinitions from '../../components/Table/TableColumnDefinitions/TableColumnDefinitions';
import axios from 'axios';
import Modal from '@mui/material/Modal';
// import { Modal } from 'prism-ui/lib/index';
import TableManagementForms from '../../components/Forms/TableManagementForms';

const DataManagementSection = styled.section`
  width: 100%;
  flex: auto;
  overflow: hidden;
`;

const ManageDataReferences = (props: any): JSX.Element => {
  const theme = useTheme();
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [loadTable, setLoadtable] = React.useState<boolean>(false);
  const [selectedTable, setSelectedTable] = React.useState<string>('');
  const [modalTitle, setModalTitle] = React.useState<string>('');
  const [selectedTableName, setSelectedTableName] = React.useState('blank');
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 10,
    page: 0,
  });
  const [columnVisibility, setColumnVisibility] = React.useState<GridColumnVisibilityModel>({ id: false });
  const [formData, setFormData] = React.useState<object>({});
  const [columnDefinitions, setColumnDefinitions] = React.useState<GridColDef[]>((): any => {
    const columns: GridColDef[] = [
      {
        field: 'blank',
        headerName: 'Data References',
        editable: false,
        flex: 1,
      },
      {
        field: 'id',
        headerName: 'ID',
        type: 'number',
        editable: false,
      },
    ];
    return columns;
  });
  const initialRow = [{ id: 1, blank: 'Select table name on left navigation to populate table with existing data.' }];
  // Set the state of the row data. This will be changed when moved to server side.
  const [rowData, setRowData] = React.useState<GridRowModel[]>(initialRow);

  const handleAddModal = (): any => {
    setModalTitle('Add');
    setFormData({});
    setModalOpen(true);
  };

  const handleEditModal = (recordProps: any): any => {
    setModalTitle('Edit');
    setModalOpen(true);
    console.log('edit recordProps at Manage table: ', recordProps);
    setFormData(recordProps);
  };

  const deleteTableRecordHandler = (recordProps: any): any => {
    console.log('delete recordProps at Manage table: ', recordProps);
  };

  React.useEffect(() => {
    if (isLoading && loadTable) {
      const colDefs = TableColumnDefinitions({
        table: selectedTableName,
        onEditRecord: handleEditModal,
        onDeleteRecord: deleteTableRecordHandler,
        ...props,
      });
      const { visibility } = colDefs;
      console.log('colDefs: ', colDefs);
      const columns: GridColDef[] = colDefs.columns;
      setColumnVisibility(visibility);
      setColumnDefinitions(columns);
      axios.get(`data/tableData/${selectedTableName}.json`).then((res: any): any => {
        const rows: GridRowModel[] = res.data[selectedTableName];
        //console.log('ajax rowData: ', rows);
        setRowData(rows);
        setIsLoading(false);
      });
      //console.log('rowData: ', rowData);
      //console.log('columnDefinitions: ', columnDefinitions);
      //console.log('columnVisibility: ', columnVisibility);
      return (): any => {
        setLoadtable(false);
      };
    }
  }, [isLoading, columnVisibility, selectedTableName, rowData, columnDefinitions, loadTable, props]);

  const handleDrawerOpen = (): void => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = (): void => {
    setDrawerOpen(false);
  };

  const handleSelectedTable = (tableObj: any): any => {
    //console.log('tableObj: ', tableObj);
    const { name, table } = tableObj;
    setSelectedTable(name);
    setSelectedTableName(table);
    setIsLoading(true);
    setLoadtable(true);
    //handleColumnData(table);
  };

  const handleModalClose = (): any => setModalOpen(false);

  const handleSaveRecord = (): any => {
    console.log('Form Data: ', formData);
    setModalOpen(false);
  };

  const ModalActions = (
    <>
      <Button variant="contained" onClick={handleModalClose}>
        Cancel
      </Button>
      <Button variant="contained" onClick={handleSaveRecord}>
        Save
      </Button>
    </>
  );

  return (
    <DataManagementSection>
      <ThemeProvider theme={theme}>
        <LeftCollapseDrawer
          items={
            <TableMenu
              onDrawerOpen={handleDrawerOpen}
              onDrawerClose={handleDrawerClose}
              drawerOpen={drawerOpen}
              selectedTable={selectedTable}
              onSelectedTable={handleSelectedTable}
            />
          }
          onDrawerOpen={handleDrawerOpen}
          onDrawerClose={handleDrawerClose}
          drawerOpen={drawerOpen}
        >
          <Box
            data-testid="app-root"
            sx={{
              display: 'block',
              width: '100%',
              bgcolor: 'background.default !important',
              color: 'text.primary',
              borderRadius: 1,
              p: 3,
            }}
          >
            <Box sx={{ width: '100%', display: 'block', height: '40px' }}>
              <Breadcrumbs page="Manage Data References" subPage={selectedTable} />
            </Box>
            <Box sx={{ width: '100%', display: 'block', height: '40px' }}>
              {selectedTable && (
                <Stack spacing={2} direction="row">
                  <Button variant="contained" onClick={handleAddModal}>
                    {'Add ' + selectedTable}
                  </Button>
                </Stack>
              )}
            </Box>
            <Box sx={{ width: '100%', display: 'block' }}>
              <DataGrid
                pageSizeOptions={[10, 25, 50, 75, 100]}
                pagination
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                rows={rowData}
                columns={columnDefinitions}
                loading={isLoading}
                columnVisibilityModel={columnVisibility}
                autoHeight={true}
              />
            </Box>
          </Box>
          <Modal
            onClose={handleModalClose}
            open={modalOpen}
            sx={{ width: '90%' }}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <React.Fragment>
              <h2 id="modal-title">
                `${modalTitle} ${selectedTable}`
              </h2>
              <Typography id="modal-description" variant="body1">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Officia reprehenderit quasi at. Nostrum
                tempora inventore odio tenetur. Cumque odio, reiciendis rem saepe nemo repellat eius ex doloremque
                dicta, minus tenetur!
              </Typography>
              <TableManagementForms table={selectedTableName} type={modalTitle} data={formData} />
              {ModalActions}
            </React.Fragment>
          </Modal>
        </LeftCollapseDrawer>
      </ThemeProvider>
    </DataManagementSection>
  );
};
export default ManageDataReferences;
