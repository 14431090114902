/**
 * @returns Platforms Tables Column Definitions
 */
const PlatformsColumnDefinitions = [
  {
    name: 'Browser',
    table: 'browser',
    visibility: {
      id: false,
      platform_id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'browser_name',
        headerName: 'Browser Name',
        editable: false,
        flex: 1,
      },
      {
        field: 'version',
        headerName: 'Version',
        editable: false,
        flex: 1,
      },
      {
        field: 'platform_id',
        headerName: 'Platform ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'platform_name',
        headerName: 'Platform Name',
        editable: false,
        flex: 1,
      },
    ],
  },
  {
    name: 'Device',
    table: 'device',
    visibility: {
      id: false,
      device_type_id: false,
      manufacturer_id: false,
      model_id: false,
      platform_id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'device_name',
        headerName: 'Device Name',
        editable: false,
        flex: 1,
      },
      {
        field: 'os_version',
        headerName: 'OS Version',
        editable: false,
        flex: 1,
      },
      {
        field: 'device_type_id',
        headerName: 'Device Type ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'device_type',
        headerName: 'Device Type',
        editable: false,
        flex: 1,
      },
      {
        field: 'manufacturer_id',
        headerName: 'Manufacturer ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'manufacturer_name',
        headerName: 'Manufacturer Name',
        editable: false,
        flex: 1,
      },
      {
        field: 'model_id',
        headerName: 'Model ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'model_name',
        headerName: 'Model Name',
        editable: false,
        flex: 1,
      },
      {
        field: 'platform_id',
        headerName: 'Platform ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'platform_name',
        headerName: 'Platform Name',
        editable: false,
        flex: 1,
      },
    ],
  },
  {
    name: 'Device Asset',
    table: 'device_asset',
    visibility: {
      id: false,
      device_id: false,
      asset_id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'device_id',
        headerName: 'Device ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'device_name',
        headerName: 'Device Name',
        editable: false,
        flex: 1,
      },
      {
        field: 'asset_id',
        headerName: 'Asset ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'asset_name',
        headerName: 'Asset Name',
        editable: false,
        flex: 1,
      },
    ],
  },
  {
    name: 'Device Model',
    table: 'device_model',
    visibility: {
      id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'model_name',
        headerName: 'Model Name',
        editable: false,
        flex: 1,
      },
      {
        field: 'number',
        headerName: 'Number',
        editable: false,
        flex: 1,
      },
    ],
  },
  {
    name: 'Device Type',
    table: 'device_type',
    visibility: {
      id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'category',
        headerName: 'Category',
        editable: false,
        flex: 1,
      },
    ],
  },
  {
    name: 'Manufacturer',
    table: 'manufacturer',
    visibility: {
      id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'manufacturer_name',
        headerName: 'Manufacturer Name',
        editable: false,
        flex: 1,
      },
      {
        field: 'url',
        headerName: 'URL',
        editable: false,
        flex: 1,
      },
    ],
  },
  {
    name: 'Platform',
    table: 'platform',
    visibility: {
      id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'platform_name',
        headerName: 'Platform Name',
        editable: false,
        flex: 1,
      },
      {
        field: 'acronym',
        headerName: 'Acronym',
        editable: false,
        flex: 1,
      },
    ],
  },
  {
    name: 'Platform Asset',
    table: 'platform_asset',
    visibility: {
      id: false,
      platform_id: false,
      asset_id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'platform_id',
        headerName: 'Platform ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'platform_name',
        headerName: 'Platform Name',
        editable: false,
        flex: 1,
      },
      {
        field: 'asset_id',
        headerName: 'Asset ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'asset_name',
        headerName: 'Asset Name',
        editable: false,
        flex: 1,
      },
    ],
  },
];
export default PlatformsColumnDefinitions;
