import React from 'react';
import styled from 'styled-components';
import pageNotFoundBackground from './assets/images/page-not-found.jpg';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const PageNotFoundContainer = styled.div`
  height: 100vh;
  background-image: url(${pageNotFoundBackground});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

const PageNotFound = (): JSX.Element => {
  const theme = useTheme();
  return (
    <ThemeProvider theme={theme}>
      <PageNotFoundContainer>
        <Typography variant="h1">Whoops! That page does not exist.</Typography>
      </PageNotFoundContainer>
    </ThemeProvider>
  );
};
export default PageNotFound;
