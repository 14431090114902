import React from 'react';
import axios from 'axios';
import MultiSelectFilter from '../../../components/Filters/MultiSelectFilter';
import PremiumFilter from './PremiumFilter';

const AdProductFilters = (props: any): JSX.Element => {
  const [isLoadingAdFormat, setIsLoadingAdFormat] = React.useState<boolean>(true);
  const [adFormatData, setAdFormatData] = React.useState<any[]>([]);
  const [isLoadingPlatform, setIsLoadingPlatform] = React.useState<boolean>(true);
  const [platformData, setPlatformData] = React.useState<any[]>([]);
  const [isLoadingDeviceType, setIsLoadingDeviceType] = React.useState<boolean>(true);
  const [deviceTypeData, setDeviceTypeData] = React.useState<any[]>([]);
  React.useEffect(() => {
    if (isLoadingAdFormat) {
      axios.get(`data/tableData/ad_format.json`).then((res: any): any => {
        const adFormatRows: any[] = res.data['ad_format'];
        // Set the filtered icon brand data
        setAdFormatData(adFormatRows);
      });
      console.log('ad_format: ', adFormatData);
      return (): any => {
        setIsLoadingAdFormat(false);
      };
    }
    if (isLoadingPlatform) {
      axios.get(`data/tableData/platform_asset.json`).then((res: any): any => {
        const platformRows: any[] = res.data['platform_asset'];
        // Set the filtered icon brand data
        setPlatformData(platformRows);
      });
      console.log('platform: ', platformData);
      return (): any => {
        setIsLoadingPlatform(false);
      };
    }
    if (isLoadingDeviceType) {
      axios.get(`data/tableData/device_type.json`).then((res: any): any => {
        const deviceTypeRows: any[] = res.data['device_type'];
        // Set the filtered icon brand data
        setDeviceTypeData(deviceTypeRows);
      });
      console.log('device_type: ', deviceTypeData);
      return (): any => {
        setIsLoadingDeviceType(false);
      };
    }
  }, [isLoadingAdFormat, adFormatData, isLoadingPlatform, platformData, isLoadingDeviceType, deviceTypeData]);
  const adFormatChangeHandler = (value: any): any => {
    console.log('onAdFormaChange: ', value);
    props.onAdFormatChange(value);
  };
  const platformChangeHandler = (value: any): any => {
    console.log('onPlatformChange: ', value);
    props.onPlatformChange(value);
  };
  const deviceTypeChangeHandler = (value: any): any => {
    console.log('onDeviceTypeChange: ', value);
    props.onDeviceTypeChange(value);
  };
  return (
    <>
      <MultiSelectFilter
        selectId="AdFormatFilter"
        label="Ad Format"
        node="format_name"
        data={adFormatData}
        selectName={props.adFormatName}
        onSelectChange={adFormatChangeHandler}
      />
      <MultiSelectFilter
        selectId="PlatformFilter"
        hasIcon={true}
        label="Platform"
        node="platform_name"
        data={platformData}
        selectName={props.platformName}
        onSelectChange={platformChangeHandler}
      />
      <MultiSelectFilter
        selectId="DeviceTypeFilter"
        label="Device Type"
        node="category"
        data={deviceTypeData}
        selectName={props.deviceTypeName}
        onSelectChange={deviceTypeChangeHandler}
      />
      <PremiumFilter onChange={props.onPremiumChanged} premiumChecked={props.premiumChecked} />
    </>
  );
};
export default AdProductFilters;
