import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';

const PremiumProduct = (props: any): JSX.Element => {
  if (props.isPremium === 1) {
    return (
      <Tooltip title="Premium">
        <IconButton>
          <WorkspacePremiumOutlinedIcon fontSize="medium" />
        </IconButton>
      </Tooltip>
    );
  } else {
    return <></>;
  }
};
export default PremiumProduct;
