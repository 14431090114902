import React, { useCallback, useContext } from 'react';
import { useAuth } from 'oidc-react';
import { AUTH_SESSION_TIMEOUT_MILLIS } from '../../constants';
import { stateContext, RouteStore } from './StateProvider';
import { useEffectOnce } from '../../helpers/useEffectOnce';
import { GetBaseURL } from '../../helpers/GetURLParams';
import envConfig from '@hulu/env-config';
import { DateTime } from 'luxon';

export const SessionKey = (): string => {
  // Get the base URL that is used in settings to build key
  const baseURL = GetBaseURL();
  // Build the key that the OIDC Client stores the data from MyID
  return `oidc.user:${baseURL}api/myid:${envConfig.REACT_APP_OIDC_CLIENT_ID}`;
};

const SessionManager = (props: any): JSX.Element => {
  const auth = useAuth();
  // get the data from the state context
  const { sessionState, dispatch } = useContext(stateContext);

  /**
   * @function checkAuth Checks the user for a valid session state
   */
  const checkAuth = useCallback((): any => {
    const authorize = async (): Promise<any> => {
      // console.log('sessionState.isAuthenticated: ', sessionState.isAuthenticated);
      // console.log('sessionState.authorization: ', sessionState.authorization);
      // Check to see if user is authenticated
      if (sessionState.isAuthenticated) {
        // Check that authorization object is not null
        if (sessionState.authorization !== null) {
          // Get the expiration date and time from session
          let sessionExpiration = sessionState.authorization['expires_at'];
          console.log('sessionExpiration: ', sessionExpiration);
          // Check to make sure that the expiration date is available
          if (sessionExpiration !== null && sessionExpiration !== undefined) {
            // Get the current time using MomentJS
            let currentTime = DateTime.now();
            // console.log('currentTime: ', currentTime);
            var expiration = DateTime.fromMillis(sessionExpiration.valueOf());
            console.log('expiration', expiration);
            // Get the difference from the current time and the expiration and
            // convert that difference into milliseconds
            let diffInMilliseconds = expiration.diff(currentTime).toMillis();
            console.log('diffInMilliseconds: ', diffInMilliseconds);
            // If the session has timed out force MyID login
            if (diffInMilliseconds <= 0) {
              // Store where the user is at in application
              RouteStore('SET');
              // Session is timed out force login to MyID
              await auth
                .signOut()
                .then((): any => dispatch({ type: 'PURGE_STATE' }))
                .then((): any => auth.signIn());
            }
          } else {
            // console.log('forced sign out because no authorization found');
            // Force a new sign in for fresh session state
            await auth
              .signOut()
              .then((): any => dispatch({ type: 'PURGE_STATE' }))
              .then((): any => auth.signIn());
          }
        } else {
          // console.log('no auth object found found');
          // If no authorization object force login to get one
          await auth.signIn();
        }
      } else {
        // If not authenticated, check for no user data and that the route has changed
        if (auth.userData === null && props.loadingSession && !window.location.href.includes('code')) {
          await auth.signIn();
        }
      }
    };
    authorize();
  }, [auth, sessionState, props, dispatch]);

  useEffectOnce((): any => {
    // Run the initial check
    if (props.loadingSession) {
      checkAuth();
    }
    // Run an interval to check for session timeout
    const backgroundCheckAuth = setInterval((): void => {
      checkAuth();
    }, AUTH_SESSION_TIMEOUT_MILLIS);
    return function cleanup(): any {
      props.setLoadingSession(false);
      clearInterval(backgroundCheckAuth);
    };
  });

  return <>{props.children}</>;
};
export default SessionManager;
