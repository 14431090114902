import React, { useContext, useCallback } from 'react';
import { AuthProvider, useAuth, User } from 'oidc-react';
import GetURLParams from '../../helpers/GetURLParams';
import { DateTime } from 'luxon';
import { AUTH_SESSION_TIMEOUT_MILLIS } from '../../constants';
import { SessionKey } from './SessionManager';
import { stateContext, RouteStore } from './StateProvider';
import { GetBaseURL } from '../../helpers/GetURLParams';
import envConfig from '@hulu/env-config';

// Get the base URL that will be used in settings
// const regex = new RegExp(/(.*)\//g);
// const m = window.location.href.match(regex);
// const baseURL = m ? m[0] : ''; // get path up to last slash

/**
 * @constructor oidcConfig - Defines the interface for the OIDC Client
 */
const oidcConfig = {
  authority: GetBaseURL() + 'myid',
  clientId: envConfig.REACT_APP_OIDC_CLIENT_ID,
  redirectUri: GetBaseURL(), // Pulled from the request URL
  postLogoutRedirectUri: GetBaseURL() + '/Login',
  responseType: 'code',
  clientSecret: envConfig.REACT_APP_OIDC_CLIENT_SECRET,
  scope: 'openid profile email id.uuid authmngr.roles authmngr.functions',
  grant_type: 'authorization_code',
  autoSignIn: false,
  clockSkew: 5000,
};

/**
 * @function MyIDProvider Manages the MyID OIDC Auth code flow authentication
 * @param props Properties passed from the parent component
 * @returns The MyID session object from Disney MyID
 */
export const MyIDProvider = (props: any): JSX.Element => {
  // Get the dispatch actions to manage updating the state
  const { dispatch } = useContext(stateContext);
  /**
   * @function signInHandler Callback function used to set the
   * session state object that comes from MyID. This is wrapped
   * in a callback function to help ensure that it only runs once
   */
  const signInHandler = useCallback(
    async (userData: User | null): Promise<any> => {
      // Ensure the data that comes back from MYID is not null
      if (userData !== null) {
        console.log('userData: ', userData);
        // Get the state variable sent back from MyID
        let currentState = await GetURLParams('state');
        //GET last route user was on and navigate there
        let currentRoute = RouteStore('GET');
        props.onPageChange(currentRoute);
        // Set the user being authenticate to true
        dispatch({ type: 'AUTHENTICATE_USER', payload: true });
        // Ensure that the state is defined in the URL
        if (currentState !== undefined) {
          userData.session_state = currentState;
        }
        // Get the expiration from MyID and add the session timeout length to it
        let sessionExpiration = DateTime.now().plus(AUTH_SESSION_TIMEOUT_MILLIS).toMillis();
        // Resent the expiration time to now plus the session timeout in constants
        userData.expires_at = sessionExpiration;
        // Sent the updated object to the session state
        dispatch({ type: 'SET_AUTHORIZATION', payload: userData });
        // Check for OIDC Client's stored object
        if (sessionStorage[SessionKey()] !== undefined) {
          // Get the session object created by the OIDC Client
          const sessionObject = await JSON.parse(sessionStorage[SessionKey()]);
          // Check to make sure the state is deined in the URL parameter
          if (currentState !== undefined) {
            // Set both session_state and state to current State
            sessionObject.session_state = currentState;
            sessionObject.state = currentState;
          }
          sessionObject.expires_at = sessionExpiration;
          // Set the session the OIDC Client uses
          sessionStorage.setItem(SessionKey(), JSON.stringify(sessionObject));
        }
      } else {
        console.error('No User Data returned');
        props.onPageChange('/Login');
      }
    },
    [props, dispatch]
  );

  return (
    <AuthProvider onSignIn={signInHandler} {...oidcConfig}>
      {props.children}
    </AuthProvider>
  );
};

// Export the Auth Provider so that we need only one import to App.tsx
export { AuthProvider, useAuth };
