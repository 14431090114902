import React, { Fragment } from 'react';
import { ACMIcon } from '../Icons/ACM';
import StorefrontIcon from '@mui/icons-material/Storefront';
const MainMenuAction = (props: any): any => {
  const mainMenu = [
    {
      name: 'Home',
      longName: '',
      id: 'homeNav',
      category: 'Main',
      route: '/Home',
      onClick: (): void => {
        props.onCloseMainMenu();
        props.onPageChange('/Home');
      },
      icon: (props: any): JSX.Element => <ACMIcon name="HomeIcon" width={28} height={28} />,
      description: (): JSX.Element => (
        <Fragment>
          Ad Capability Matrix (ACM) provides a quick, accurate, and current view into the ad capabilities across all
          Disney brands, including ad creative specs, ad product availability, audience targeting, measurement
          integrations, supported platforms, etc.
        </Fragment>
      ),
    },
    {
      name: 'Brands',
      longName: '',
      id: 'brandsNav',
      category: 'Main',
      route: '/Brands',
      onClick: (): void => {
        props.onCloseMainMenu();
        props.onPageChange('/Brands');
      },
      icon: (props: any): JSX.Element => <ACMIcon name="BrandsIcon" width={28} height={28} {...props} />,
      description: (): JSX.Element => (
        <Fragment>
          List of the <abbr title="Disney Media, Entertainment and Distribution">DMED</abbr> brands available for ad
          delivery.
        </Fragment>
      ),
    },
    {
      name: 'Ad Products',
      longName: '',
      id: 'adProductsNav',
      category: 'Main',
      route: '/AdProducts',
      onClick: (): void => {
        props.onCloseMainMenu();
        props.onPageChange('/AdProducts');
      },
      icon: (props: any): JSX.Element => <ACMIcon name="AdProductIcon" width={28} height={28} {...props} />,
      description: (): JSX.Element => (
        <Fragment>
          Filterable list of supported brands and sites (i.e. sub-brands) of&nbsp;
          <abbr title="Disney Media, Entertainment and Distribution">DMED</abbr> ad products and their specifications.
        </Fragment>
      ),
    },
    {
      name: 'Measurement',
      longName: '',
      id: 'measurementNav',
      category: 'Main',
      route: '/Measurement',
      onClick: (): void => {
        props.onCloseMainMenu();
        props.onPageChange('/Measurement');
      },
      icon: (props: any): JSX.Element => <ACMIcon name="MeasurementIcon" width={28} height={28} {...props} />,
      description: (): JSX.Element => (
        <Fragment>
          Filterable list of supported measurement/analytics vendors by brand and sub-brand ad format. i.e. Moat,
          ComScore, Nielsen <abbr title="Digital Ad Ratings">DAR</abbr>, BlueKai, Live Ramp, etc.
        </Fragment>
      ),
    },
    {
      name: 'Partners',
      longName: '',
      id: 'partnersNav',
      category: 'Main',
      route: '/Partners',
      onClick: (): void => {
        props.onCloseMainMenu();
        props.onPageChange('/Partners');
      },
      icon: (props: any): JSX.Element => <ACMIcon name="PartnerIcon" width={28} height={28} {...props} />,
      description: (): JSX.Element => (
        <Fragment>
          Filterable list of third party ad platform partners by brand and sub-brand. i.e.&nbsp;
          <abbr title="Multichannel Video Programming Distributors">MVPDs</abbr>, Salesforce, O1, etc.
        </Fragment>
      ),
    },
    {
      name: 'Vendors',
      longName: '',
      id: 'vendorsNav',
      category: 'Main',
      route: '/Vendors',
      onClick: (): void => {
        props.onCloseMainMenu();
        props.onPageChange('/Vendors');
      },
      icon: (props: any): JSX.Element => <StorefrontIcon fontSize="large" />,
      description: (): JSX.Element => (
        <Fragment> Filterable list of third party ad platform vendors used for measurement.</Fragment>
      ),
    },
    {
      name: 'Targeting',
      longName: '',
      id: 'targetingNav',
      category: 'Main',
      route: '/Targeting',
      onClick: (): void => {
        props.onCloseMainMenu();
        props.onPageChange('/Targeting');
      },
      icon: (props: any): JSX.Element => <ACMIcon name="TargetingIcon" width={28} height={28} {...props} />,
      description: (): JSX.Element => (
        <Fragment>
          Filterable list of 1P, 2P, and 3P targeting data by brand/sub-brand on what device should be delivering
          audience-targetable impressions.
        </Fragment>
      ),
    },
  ];
  return mainMenu;
};
export default MainMenuAction;
