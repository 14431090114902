const ACMIcons = [
  {
    name: 'HomeIcon',
    path: 'm22.567 7.8495-9.8307-7.6238c-0.40125-0.30094-0.90282-0.30094-1.3041 0l-9.931 7.6238c-0.30094 0.20063-0.40125 0.50157-0.40125 0.80251v12.038c0 0.90282 0.30094 1.7053 1.0031 2.3072 0.60188 0.60188 1.4044 1.0031 2.3072 1.0031h15.348c0.90282 0 1.7053-0.30094 2.3072-1.0031 0.60188-0.70219 1.0031-1.4044 1.0031-2.3072v-12.038c-0.10031-0.30094-0.20063-0.60188-0.50157-0.80251zm-8.326 13.944h-4.4138v-8.7273h4.4138zm6.5204-1.1034c0 0.30094-0.10031 0.60188-0.30094 0.80251s-0.50157 0.30094-0.80251 0.30094h-3.3103v-9.8307c0-0.60188-0.50157-1.1034-1.1034-1.1034h-6.5204c-0.60188 0-1.1034 0.50157-1.1034 1.1034v9.8307h-3.21c-0.30094 0-0.60188-0.10031-0.80251-0.30094-0.20063-0.20063-0.30094-0.50157-0.30094-0.80251v-11.436l8.7273-6.8213 8.7273 6.8213z',
  },
  {
    name: 'AdProductIcon',
    path: 'm12.046 0c-0.60251 0-1.1046 0.20084-1.6067 0.40167l-7.7322 4.4184c-0.50209 0.30126-0.90377 0.70293-1.205 1.205-0.30126 0.50209-0.40167 1.1046-0.40167 1.6067v8.7364c0 0.60251 0.20084 1.1046 0.40167 1.6067 0.30126 0.50209 0.70293 0.90377 1.205 1.205l7.6318 4.4184c0.50209 0.30126 1.1046 0.40167 1.6067 0.40167s1.1046-0.20084 1.6067-0.40167l7.6318-4.4184c0.50209-0.30126 0.90377-0.70293 1.205-1.205s0.40167-1.1046 0.40167-1.6067v-8.7364c0-0.60251-0.20084-1.1046-0.40167-1.6067-0.30126-0.50209-0.70293-0.90377-1.205-1.205l-7.6318-4.4184c-0.40167-0.20084-0.90377-0.40167-1.5063-0.40167zm-0.50209 2.3096c0.20084-0.10042 0.40167-0.10042 0.50209-0.10042 0.10042 0 0.40167 0.10042 0.50209 0.10042l7.0293 4.0167-2.7113 1.6067-7.5314-4.4184zm1.6067 10.343 7.6318-4.4184v8.1339c0 0.20084-0.10042 0.40167-0.10042 0.50209-0.10042 0.20084-0.20084 0.30126-0.40167 0.40167l-7.1297 4.1172zm-2.2092 0-7.6318-4.4184v8.1339c0 0.20084 0.10042 0.40167 0.10042 0.50209 0.10042 0.20084 0.20084 0.30126 0.40167 0.40167l7.1297 4.1172zm1.1046-1.8075-7.6318-4.4184 2.7113-1.6067 7.6318 4.4184z',
  },
  {
    name: 'BrandsIcon',
    path: 'm1.1046 0c-0.60251 0-1.1046 0.50209-1.1046 1.1046v11.347c0 0.30126 0.10042 0.60251 0.30126 0.80335l9.7406 9.7406c0.30126 0.30126 0.70293 0.60251 1.1046 0.70293 0.40167 0.20084 0.90376 0.30126 1.3054 0.30126 0.40167 0 0.90377-0.10042 1.3054-0.30126 0.40167-0.20084 0.80335-0.40167 1.1046-0.70293l8.1339-8.1339-0.80335-0.80335 0.80335 0.80335c0.60251-0.60251 1.0042-1.5063 1.0042-2.41s-0.40167-1.8075-1.0042-2.41l-9.7406-9.7406c-0.10042-0.20084-0.40167-0.30126-0.70293-0.30126zm20.385 13.356-8.1339 8.1339c-0.10042 0.10042-0.20084 0.20084-0.40167 0.20084-0.10042 0.10042-0.30126 0.10042-0.40167 0.10042-0.10042 0-0.30126 0-0.40167-0.10042-0.10042-0.10042-0.30126-0.10042-0.40167-0.20084l-9.4393-9.4393v-9.7406h9.7406l9.4393 9.4393c0.20084 0.20084 0.30126 0.50209 0.30126 0.80335s-0.10042 0.60251-0.30126 0.80335zm-14.661-7.6318c-0.60251 0-1.1046 0.50209-1.1046 1.1046 0 0.60251 0.50209 1.1046 1.1046 1.1046 0.60251 0 1.1046-0.50209 1.1046-1.1046 0-0.60251-0.50209-1.1046-1.1046-1.1046z',
  },
  {
    name: 'MeasurementIcon',
    path: 'm0 7.7c0-0.7 0.5-1.2 1.2-1.2h21.6c0.7 0 1.2 0.5 1.2 1.2v9.6c0 0.7-0.5 1.2-1.2 1.2h-21.6c-0.7 0-1.2-0.5-1.2-1.2v-9.6zm19.8 1.2h1.8v7.2h-19.2v-7.2h1.8v1.8c0 0.3 0.3 0.6 0.6 0.6s0.6-0.3 0.6-0.6v-1.8h2.4v1.8c0 0.3 0.3 0.6 0.6 0.6s0.6-0.3 0.6-0.6v-1.8h1.8v3.6c0 0.7 0.5 1.2 1.2 1.2s1.2-0.5 1.2-1.2v-3.6h1.8v1.8c0 0.3 0.3 0.6 0.6 0.6s0.6-0.3 0.6-0.6v-1.8h2.4v1.8c0 0.3 0.3 0.6 0.6 0.6s0.6-0.3 0.6-0.6v-1.8z',
  },
  {
    name: 'PartnerIcon',
    path: 'm5.5 7.5v7.5c0.1 0.1 0.3 0.2 0.4 0.3 0.5 0.4 1.2 0.9 2 1.5 0.8 0.5 1.6 1 2.4 1.4s1.4 0.6 1.7 0.6c0.4 0 1.1-0.2 2-0.7 0.6-0.3 1.2-0.7 1.9-1.1-0.2-0.4-0.5-1-0.8-1.7-0.5-1.2-1.2-2.7-1.9-4.2l-1.2 0.7c-0.9 0.6-2.1 0.7-3.1 0.5-0.9-0.3-2.1-1-2.3-2.3-0.2-1 0.2-1.8 0.6-2.4 0.3-0.4 0.7-0.8 1.1-1.1-0.3 0-0.5 0.1-0.7 0.2-0.8 0.3-1.5 0.5-2 0.7l-0.1 0.1zm6.9-1.8c-0.1 0.1-0.3 0.3-0.5 0.4-0.6 0.5-1.4 1.2-1.9 1.7-0.1 0.2-0.3 0.3-0.4 0.4-0.3 0.3-0.5 0.4-0.7 0.7s-0.2 0.4-0.2 0.6 0.2 0.4 0.7 0.6c0.5 0.1 1.1 0 1.4-0.2l2.2-1.3c0.6-0.4 1.4-0.2 1.7 0.5 0.8 1.8 1.7 3.7 2.4 5.2 0.2 0.5 0.4 0.9 0.6 1.3 0.2-0.2 0.4-0.3 0.6-0.5 0.1-0.1 0.2-0.2 0.3-0.2v-7.4s-0.1 0-0.2-0.1c-0.5-0.2-1.2-0.5-2-0.7-1.4-0.5-3-0.9-4-1zm8.3 2.1v6.5h1.1v-6.5h-1.1zm-0.7 8.8c-0.1 0.1-0.3 0.2-0.4 0.3-0.5 0.4-1.1 0.8-1.8 1.3-0.9 0.6-1.9 1.3-2.8 1.8s-2 0.9-3 0.9c-0.9 0-1.9-0.4-2.7-0.8-0.9-0.4-1.8-1-2.6-1.6-0.9-0.5-1.6-1.1-2.2-1.5-0.2-0.2-0.4-0.3-0.6-0.5h-1.7c-1.2 0-2.2-1-2.2-2.2v-6.5c0-1.2 1-2.2 2.2-2.2h2c0.2-0.1 0.4-0.1 0.6-0.3 0.5-0.2 1.3-0.5 2.1-0.8 1.6-0.5 3.7-1.1 5.1-1.1s3.5 0.6 5.1 1.1c0.8 0.3 1.6 0.6 2.1 0.8 0.3 0.1 0.5 0.2 0.6 0.3h2c1.2 0 2.2 1 2.2 2.2v6.5c0 1.2-1 2.2-2.2 2.2h-1.8zm-16.7-2.2v-6.6h-1.1v6.5h1.1z',
  },
  {
    name: 'TargetingIcon',
    path: 'm12 8.7c-1.8 0-3.3 1.5-3.3 3.3s1.5 3.3 3.3 3.3 3.3-1.5 3.3-3.3-1.5-3.3-3.3-3.3zm-1.1 3.3c0-0.6 0.5-1.1 1.1-1.1s1.1 0.5 1.1 1.1-0.5 1.1-1.1 1.1-1.1-0.5-1.1-1.1zm-6.5 0c0-4.2 3.4-7.6 7.6-7.6s7.6 3.4 7.6 7.6-3.4 7.6-7.6 7.6-7.6-3.4-7.6-7.6zm7.6-5.5c-3 0-5.5 2.5-5.5 5.5s2.5 5.5 5.5 5.5 5.5-2.4 5.5-5.5-2.5-5.5-5.5-5.5zm-12 5.5c0-6.6 5.4-12 12-12s12 5.4 12 12-5.4 12-12 12-12-5.4-12-12zm12-9.8c-5.4 0-9.8 4.4-9.8 9.8s4.4 9.8 9.8 9.8 9.8-4.4 9.8-9.8-4.4-9.8-9.8-9.8z',
  },
  {
    name: 'ProfileIcon',
    path: 'm16 22c-0.6 0-1-0.4-1-1v-2c0-1.7-1.3-3-3-3h-7c-1.7 0-3 1.3-3 3v2c0 0.6-0.4 1-1 1s-1-0.4-1-1v-2c0-2.8 2.2-5 5-5h7c2.8 0 5 2.2 5 5v2c0 0.6-0.4 1-1 1zm-7.5-20c-2.8 0-5 2.2-5 5s2.2 5 5 5 5-2.2 5-5-2.2-5-5-5zm0 8c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3zm14.4 1.5c0-0.1 0.1-0.1 0.2-0.1 0.5 0 0.9-0.4 0.9-0.9s-0.4-0.9-0.9-0.9c-0.1 0-0.1 0-0.2-0.1v-0.1c0-0.1 0-0.2 0.1-0.2 0.3-0.3 0.3-0.9 0-1.2-0.2-0.2-0.4-0.3-0.6-0.3s-0.5 0.1-0.6 0.3c-0.1 0.1-0.1 0.1-0.2 0-0.1 0-0.1-0.1-0.1-0.2-0.1-0.4-0.5-0.8-1-0.8s-0.9 0.4-0.9 0.9c0 0.1 0 0.1-0.1 0.2h-0.1c-0.1 0-0.2 0-0.2-0.1-0.3-0.3-0.9-0.3-1.2 0s-0.3 0.9 0 1.3c0.1 0.1 0.1 0.1 0 0.2 0 0.1-0.1 0.1-0.2 0.1-0.5 0-0.9 0.4-0.9 0.9s0.4 0.9 0.9 0.9c0.1 0 0.1 0 0.2 0.1 0 0.1 0 0.2-0.1 0.2-0.3 0.3-0.3 0.9 0 1.2s0.9 0.3 1.3 0c0.1-0.1 0.1-0.1 0.2 0 0.1 0 0.1 0.1 0.1 0.2 0 0.5 0.4 0.9 0.9 0.9s0.9-0.4 0.9-0.9c0-0.1 0-0.1 0.1-0.2 0.1 0 0.2 0 0.2 0.1 0.2 0.2 0.4 0.3 0.6 0.3s0.5-0.1 0.6-0.3c0.3-0.3 0.3-0.9 0-1.3 0.1 0 0.1-0.1 0.1-0.2zm-2.4 0.2c-0.6 0-1.2-0.5-1.2-1.2s0.5-1.2 1.2-1.2c0.6 0 1.2 0.5 1.2 1.2s-0.6 1.2-1.2 1.2z',
  },
  {
    name: 'ThemeIcon',
    path: 'm5.2 17.7c0 0.5-0.4 0.9-0.9 0.9s-0.9-0.4-0.9-0.9 0.4-0.9 0.9-0.9 0.9 0.4 0.9 0.9zm16.8-1.6v3.2c0 1.5-1.2 2.6-2.6 2.6h-14.7-0.3-0.1c-2.4 0.1-4.3-1.8-4.3-4.2v-15.1c0-1.4 1.2-2.6 2.6-2.6h3.2c1.5 0 2.6 1.2 2.6 2.6v5l3.4-3.4c1-1 2.7-1 3.7 0l2.3 2.3c1 1 1 2.7 0 3.7l-3.3 3.3h4.7c1.6 0 2.8 1.2 2.8 2.6zm-20.2-5.4h4.8v-3.5h-4.8v3.5zm6.7-0.5v6.8l8.1-8.1c0.3-0.3 0.3-0.8 0-1.1l-2.3-2.3c-0.3-0.3-0.8-0.3-1.1 0l-4.7 4.7zm-6.7-7.6v2.7h4.8v-2.7c0-0.4-0.4-0.8-0.8-0.8h-3.2c-0.4 0-0.8 0.4-0.8 0.8zm2.5 17.6h0.4 0.1c0.1 0 0.3-0.1 0.4-0.1h0.1c0.1-0.1 0.2-0.1 0.3-0.2l0.3-0.3c0.1-0.1 0.1-0.2 0.2-0.3 0-0.1 0.1-0.1 0.1-0.2 0.1-0.1 0.1-0.2 0.2-0.3 0-0.1 0.1-0.1 0.1-0.2s0.1-0.2 0.1-0.3v-0.1c0-0.2 0.1-0.3 0.1-0.5v-5.2h-4.9v5.2c0 1.4 1.1 2.5 2.5 2.5zm15.8-4.1c0-0.4-0.4-0.8-0.8-0.8h-6.5l-4.8 4.9h11.3c0.4 0 0.8-0.4 0.8-0.8v-3.3z',
  },
];
export default ACMIcons;
