import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import RecordDates from './FormItems/RecordDates';
import IsDeletedSwitch from './FormItems/IsDeletedSwitch';

const AdCategoryForm = (props: any): JSX.Element => {
  const [adCategory, setAdCategory] = React.useState<string>(
    props.data.category === undefined ? '' : props.data.category
  );
  const [adCategoryDeleted, setAdCategoryDeleted] = React.useState<number>(
    props.data.is_deleted === undefined ? 0 : props.data.is_deleted
  );
  const formChangeHandler = (event: any): any => {
    if (event.target.id === 'adCategory') {
      setAdCategory(event.target.value);
      props.data.category = event.target.value;
    } else if (event.target.id === 'adCategoryDeleted') {
      if (event.target.checked) {
        setAdCategoryDeleted(1);
        props.data.is_deleted = 1;
      } else {
        setAdCategoryDeleted(0);
        props.data.is_deleted = 0;
      }
    }
  };
  const categoryElement = (
    <TextField
      id="adCategory"
      fullWidth
      label="Category"
      variant="outlined"
      value={adCategory}
      onChange={formChangeHandler}
    />
  );
  const formStyle = {
    '& > :not(style)': { m: 1, width: '99%' },
  };
  if (props.type === 'Edit') {
    return (
      <Box component="form" sx={formStyle} noValidate autoComplete="off">
        {categoryElement}
        <RecordDates data={props.data} />
        <IsDeletedSwitch id="adCategoryDeleted" value={adCategoryDeleted} onChange={formChangeHandler} />
      </Box>
    );
  } else {
    return (
      <Box component="form" sx={formStyle} noValidate autoComplete="off">
        {categoryElement}
      </Box>
    );
  }
};
export default AdCategoryForm;
