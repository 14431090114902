import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import hint from './reducers/hint';
import logo from './reducers/logo';

export const app = combineReducers({
  hint,
  logo,
});

const logger = createLogger({
  diff: true,
  duration: true,
});

const store = createStore(app, composeWithDevTools(applyMiddleware(thunk, logger)));

export default store;
