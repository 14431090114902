import AdCategoryForm from './AdCategoryForm';
import AdFormatForm from './AdFormatForm';

const TableManagementForms = (props: any): any => {
  const tabManagementForms = [
    {
      table: 'ad_category',
      form: <AdCategoryForm type={props.type} data={props.data} />,
    },
    {
      table: 'ad_format',
      form: <AdFormatForm type={props.type} data={props.data} />,
    },
  ];
  const pathIndex = tabManagementForms.findIndex(
    (x) => x.table === (props.table === undefined ? 'ad_category' : props.table)
  );
  console.log('pathIndex: ', pathIndex);
  return tabManagementForms[pathIndex].form;
};
export default TableManagementForms;
