import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import RecordDates from './FormItems/RecordDates';
import IsDeletedSwitch from './FormItems/IsDeletedSwitch';

const AdFormatForm = (props: any): JSX.Element => {
  console.log('AdCategoryForm: ', props.data);
  const [adFormat, setAdFormat] = React.useState<string>(
    props.data.format_name === undefined ? '' : props.data.format_name
  );
  const [adFormatDeleted, setAdFormatDeleted] = React.useState<number>(
    props.data.is_deleted === undefined ? 0 : props.data.is_deleted
  );
  const formChangeHandler = (event: any): any => {
    if (event.target.id === 'adFormatName') {
      setAdFormat(event.target.value);
      props.data.category = event.target.value;
    } else if (event.target.id === 'adFormatDeleted') {
      if (event.target.checked) {
        setAdFormatDeleted(1);
        props.data.is_deleted = 1;
      } else {
        setAdFormatDeleted(0);
        props.data.is_deleted = 0;
      }
    }
  };
  const formatNameElement = (
    <TextField
      id="adFormatName"
      fullWidth
      label="Format Name"
      variant="outlined"
      value={adFormat}
      onChange={formChangeHandler}
    />
  );
  const formStyle = {
    '& > :not(style)': { m: 1, width: '99%' },
  };
  if (props.type === 'Edit') {
    return (
      <Box component="form" sx={formStyle} noValidate autoComplete="off">
        {formatNameElement}
        <RecordDates data={props.data} />
        <IsDeletedSwitch id="adFormatDeleted" value={adFormatDeleted} onChange={formChangeHandler} />
      </Box>
    );
  } else {
    return (
      <Box component="form" sx={formStyle} noValidate autoComplete="off">
        {formatNameElement}
      </Box>
    );
  }
};
export default AdFormatForm;
