import { types } from '../actions/logo';

type LogoState = {
  clickCount: number;
};

export const initialState: LogoState = {
  clickCount: 0,
};

const logo = (state = initialState, action: any): LogoState => {
  switch (action.type) {
    case types.CLICK: {
      return { ...state, clickCount: state.clickCount + 1 };
    }
    default:
      return state;
  }
};

export default logo;
