import React from 'react';
import Icons from './ACMIcons';
/**
 * @function ACMIcon
 * @param props width, height, color, name
 * @returns A specific DMED Icon
 */
const ACMIcon = (props: any): JSX.Element => {
  // Get the index of the passed property name to get the path index
  const pathIndex = Icons.findIndex(
    (x: any): any => x.name === (props.name === undefined ? 'Announcement' : props.name)
  );
  return (
    <svg
      viewBox="0 0 24 24"
      version="1.1"
      height={props.height === undefined ? 24 : props.height}
      width={props.width === undefined ? 24 : props.width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={props.color === undefined ? 'currentColor' : props.color}
        clipRule="evenodd"
        fillRule="evenodd"
        d={Icons[pathIndex].path}
      />
    </svg>
  );
};
export default ACMIcon;
