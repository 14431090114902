/**
 * @returns Targeting Tables Column Definitions
 */
const TargetingColumnDefinitions = [
  {
    name: 'Ad Category',
    table: 'ad_category',
    visibility: {
      id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'category',
        headerName: 'Category',
        editable: false,
        flex: 1,
      },
    ],
  },
  {
    name: 'Audience Segment',
    table: 'audience_segment',
    visibility: {
      id: false,
      segment_type_id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'segment_name',
        headerName: 'Segment Name',
        editable: false,
        flex: 1,
      },
      {
        field: 'segment_type_id',
        headerName: 'Segment Type ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'segment_type',
        headerName: 'Segment Type',
        editable: false,
        flex: 1,
      },
    ],
  },
  {
    name: 'Audience Segment Type',
    table: 'audience_segment_type',
    visibility: {
      id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'category',
        headerName: 'Category',
        editable: false,
      },
    ],
  },
  {
    name: 'Content Type',
    table: 'content_type',
    visibility: {
      id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'category',
        headerName: 'Category',
        editable: false,
        flex: 1,
      },
      {
        field: 'acronym',
        headerName: 'Acronym',
        editable: false,
        flex: 1,
      },
    ],
  },
  {
    name: 'Data Source',
    table: 'data_source',
    visibility: {
      id: false,
      partner_id: false,
      data_source_type_id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'partner_id',
        headerName: 'Partner ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'partner_name',
        headerName: 'Partner Name',
        editable: false,
        flex: 1,
      },
      {
        field: 'data_source_type_id',
        headerName: 'Data Source Type ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'data_source_type',
        headerName: 'Data Source Type',
        editable: false,
        flex: 1,
      },
    ],
  },
  {
    name: 'Data Source Type',
    table: 'data_source_type',
    visibility: {
      id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'category',
        headerName: 'Category',
        editable: false,
        flex: 1,
      },
    ],
  },
];
export default TargetingColumnDefinitions;
