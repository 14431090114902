/**
 * @returns Brand Tables Column Definitions
 */
const BrandColumnDefinitions = [
  {
    name: 'Brand',
    table: 'brand',
    visibility: {
      id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'brand_name',
        headerName: 'Brand Name',
        editable: false,
        flex: 1,
      },
      {
        field: 'acronym',
        headerName: 'Acronym',
        editable: false,
        flex: 1,
      },
    ],
  },
  {
    name: 'Brand Ad Event Signal',
    table: 'brand_ad_event_signal',
    visibility: {
      id: false,
      brand_id: false,
      site_id: false,
      ad_event_signal_type_id: false,
      content_type_id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'brand_id',
        headerName: 'Brand ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'brand_name',
        headerName: 'Brand Name',
        editable: false,
        flex: 1,
      },
      {
        field: 'site_id',
        headerName: 'Brand ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'site_name',
        headerName: 'Site Name',
        editable: false,
        flex: 1,
      },
      {
        field: 'ad_event_signal_type_id',
        headerName: 'Ad Event Signal Type ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'ad_event_signal_type',
        headerName: 'Ad Event Signal Type',
        editable: false,
        flex: 1,
      },
      {
        field: 'content_type_id',
        headerName: 'Content Type ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'content_type',
        headerName: 'Content Type',
        editable: false,
        flex: 1,
      },
    ],
  },
  {
    name: 'Brand Ad Product',
    table: 'brand_ad_product', // Brand Ad Product
    visibility: {
      id: false,
      brand_id: false,
      site_id: false,
      ad_product_id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'brand_id',
        headerName: 'Brand ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'brand_name',
        headerName: 'Brand Name',
        editable: false,
        flex: 1,
      },
      {
        field: 'site_id',
        headerName: 'Brand ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'site_name',
        headerName: 'Site Name',
        editable: false,
        flex: 1,
      },
      {
        field: 'ad_product_id',
        headerName: 'Ad Product ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'ad_product_name',
        headerName: 'Ad Product Name',
        editable: false,
        flex: 1,
      },
    ],
  },
  {
    name: 'Brand Ad Spec',
    table: 'brand_ad_spec',
    visibility: {
      id: false,
      brand_id: false,
      site_id: false,
      ad_spec_id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'brand_id',
        headerName: 'Brand ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'brand_name',
        headerName: 'Brand Name',
        editable: false,
        flex: 1,
      },
      {
        field: 'site_id',
        headerName: 'Brand ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'site_name',
        headerName: 'Site Name',
        editable: false,
        flex: 1,
      },
      {
        field: 'ad_spec_id',
        headerName: 'Ad Spec ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'ad_spec_name',
        headerName: 'Ad Spec Name',
        editable: false,
        flex: 1,
      },
    ],
  },
  {
    name: 'Brand Asset',
    table: 'brand_asset',
    visibility: {
      id: false,
      brand_id: false,
      asset_id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'brand_id',
        headerName: 'Brand ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'brand_name',
        headerName: 'Brand Name',
        editable: false,
        flex: 1,
      },
      {
        field: 'asset_id',
        headerName: 'Asset ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'asset_name',
        headerName: 'Asset Name',
        editable: false,
        flex: 1,
      },
    ],
  },
  {
    name: 'Brand Audience',
    table: 'brand_audience',
    visibility: {
      id: false,
      brand_id: false,
      site_id: false,
      audience_segment_id: false,
      content_type_id: false,
      platform_id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'brand_id',
        headerName: 'Brand ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'brand_name',
        headerName: 'Brand Name',
        editable: false,
        flex: 1,
      },
      {
        field: 'site_id',
        headerName: 'Brand ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'site_name',
        headerName: 'Site Name',
        editable: false,
        flex: 1,
      },
      {
        field: 'audience_segment_id',
        headerName: 'Audience Segment ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'audience_segment_name',
        headerName: 'Audience Segment Name',
        editable: false,
        flex: 1,
      },
      {
        field: 'content_type_id',
        headerName: 'Content Type ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'content_type',
        headerName: 'Content Type',
        editable: false,
        flex: 1,
      },
      {
        field: 'platform_id',
        headerName: 'Platform ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'platform_name',
        headerName: 'Platform Name',
        editable: false,
        flex: 1,
      },
    ],
  },
  {
    name: 'Brand Creative Restriction',
    table: 'brand_creative_restriction',
    visibility: {
      id: false,
      brand_id: false,
      site_id: false,
      creative_restriction_id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'brand_id',
        headerName: 'Brand ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'brand_name',
        headerName: 'Brand Name',
        editable: false,
      },
      {
        field: 'site_id',
        headerName: 'Brand ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'site_name',
        headerName: 'Site Name',
        editable: false,
      },
      {
        field: 'creative_restriction_id',
        headerName: 'Creative Restriction ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'creative_restriction',
        headerName: 'Creative Restriction',
        editable: false,
      },
    ],
  },
  {
    name: 'Brand DAI Type',
    table: 'brand_dai_type',
    visibility: {
      id: false,
      brand_id: false,
      site_id: false,
      dai_type_id: false,
      platform_id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'brand_id',
        headerName: 'Brand ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'brand_name',
        headerName: 'Brand Name',
        editable: false,
      },
      {
        field: 'site_id',
        headerName: 'Brand ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'site_name',
        headerName: 'Site Name',
        editable: false,
      },
      {
        field: 'dai_type_id',
        headerName: 'DAI Type ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'dai_type',
        headerName: 'DAI Type',
        editable: false,
      },
      {
        field: 'platform_id',
        headerName: 'Platform ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'platform_name',
        headerName: 'Platform Name',
        editable: false,
      },
    ],
  },
  {
    name: 'Brand Data',
    table: 'brand_data',
    visibility: {
      id: false,
      brand_id: false,
      site_id: false,
      data_source_id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'brand_id',
        headerName: 'Brand ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'brand_name',
        headerName: 'Brand Name',
        editable: false,
      },
      {
        field: 'site_id',
        headerName: 'Brand ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'site_name',
        headerName: 'Site Name',
        editable: false,
      },
      {
        field: 'data_source_id',
        headerName: 'Data Source ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'data_source_name',
        headerName: 'Data Source Name',
        editable: false,
      },
    ],
  },
  {
    name: 'Brand Deal',
    table: 'brand_deal',
    visibility: {
      id: false,
      brand_id: false,
      site_id: false,
      deal_type_id: false,
      sales_channel_id: false,
      platform_id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'brand_id',
        headerName: 'Brand ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'brand_name',
        headerName: 'Brand Name',
        editable: false,
      },
      {
        field: 'site_id',
        headerName: 'Brand ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'site_name',
        headerName: 'Site Name',
        editable: false,
      },
      {
        field: 'deal_type_id',
        headerName: 'Deal Type ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'deal_type',
        headerName: 'Deal Type',
        editable: false,
      },
      {
        field: 'sales_channel_id',
        headerName: 'Sales Channel ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'sales_channel',
        headerName: 'Sales Channel',
        editable: false,
      },
      {
        field: 'platform_id',
        headerName: 'Platform ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'platform_name',
        headerName: 'Platform Name',
        editable: false,
      },
    ],
  },
  {
    name: 'Brand Device',
    table: 'brand_device',
    visibility: {
      id: false,
      brand_id: false,
      site_id: false,
      device_id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'brand_id',
        headerName: 'Brand ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'brand_name',
        headerName: 'Brand Name',
        editable: false,
      },
      {
        field: 'site_id',
        headerName: 'Brand ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'site_name',
        headerName: 'Site Name',
        editable: false,
      },
      {
        field: 'device_id',
        headerName: 'Device ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'device_name',
        headerName: 'Device Name',
        editable: false,
      },
    ],
  },
  {
    name: 'Brand Logging',
    table: 'brand_logging',
    visibility: {
      id: false,
      brand_id: false,
      site_id: false,
      ad_signal_logging_type_id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'brand_id',
        headerName: 'Brand ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'brand_name',
        headerName: 'Brand Name',
        editable: false,
      },
      {
        field: 'site_id',
        headerName: 'Brand ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'site_name',
        headerName: 'Site Name',
        editable: false,
      },
      {
        field: 'ad_signal_logging_type_id',
        headerName: 'Ad Signal Logging Type ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'ad_signal_logging_type',
        headerName: 'Ad Signal Logging Type',
        editable: false,
      },
    ],
  },
  {
    name: 'Brand Measurement',
    table: 'brand_measurement',
    visibility: {
      id: false,
      brand_id: false,
      site_id: false,
      measurement_id: false,
      content_type_id: false,
      platform_id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'brand_id',
        headerName: 'Brand ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'brand_name',
        headerName: 'Brand Name',
        editable: false,
      },
      {
        field: 'site_id',
        headerName: 'Brand ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'site_name',
        headerName: 'Site Name',
        editable: false,
      },
      {
        field: 'measurement_id',
        headerName: 'Measurement ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'measurement',
        headerName: 'Measurement',
        editable: false,
      },
      {
        field: 'content_type_id',
        headerName: 'Content Type ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'content_type',
        headerName: 'Content Type',
        editable: false,
      },
      {
        field: 'platform_id',
        headerName: 'Platform ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'platform_name',
        headerName: 'Platform Name',
        editable: false,
      },
    ],
  },
  {
    name: 'Brand Partner',
    table: 'brand_partner',
    visibility: {
      id: false,
      brand_id: false,
      site_id: false,
      partner_id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'brand_id',
        headerName: 'Brand ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'brand_name',
        headerName: 'Brand Name',
        editable: false,
      },
      {
        field: 'site_id',
        headerName: 'Brand ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'site_name',
        headerName: 'Site Name',
        editable: false,
      },
      {
        field: 'partner_id',
        headerName: 'Partner ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'partner_name',
        headerName: 'Partner Name',
        editable: false,
      },
    ],
  },
  {
    name: 'Brand Pod',
    table: 'brand_pod',
    visibility: {
      id: false,
      brand_id: false,
      site_id: false,
      ad_pod_id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'brand_id',
        headerName: 'Brand ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'brand_name',
        headerName: 'Brand Name',
        editable: false,
      },
      {
        field: 'site_id',
        headerName: 'Brand ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'site_name',
        headerName: 'Site Name',
        editable: false,
      },
      {
        field: 'ad_pod_id',
        headerName: 'Ad Pod ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'ad_pod_position',
        headerName: 'Ad Pod Position',
        editable: false,
      },
    ],
  },
  {
    name: 'Site',
    table: 'site',
    visibility: {
      id: false,
      brand_id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'brand_id',
        headerName: 'Brand ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'brand_name',
        headerName: 'Brand Name',
        editable: false,
      },
      {
        field: 'site_name',
        headerName: 'Site Name',
        editable: false,
      },
      {
        field: 'url',
        headerName: 'URL',
        editable: false,
      },
    ],
  },
  {
    name: 'Site Asset',
    table: 'site_asset',
    visibility: {
      id: false,
      site_id: false,
      asset_id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'site_id',
        headerName: 'Site ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'site_name',
        headerName: 'Site Name',
        editable: false,
      },
      {
        field: 'asset_id',
        headerName: 'Asset ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'asset_name',
        headerName: 'Asset Name',
        editable: false,
      },
    ],
  },
];
export default BrandColumnDefinitions;
