import React, { useState } from 'react';
import styled from '@emotion/styled';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { ThemeProvider, useTheme } from '@mui/material/styles';
//import DMEDIconLogo from '../Logos/DMEDIconLogo';
import ACMCubeLogo from '../Logos/ACMCubeLogo';
import ProfileNav from './ProfileNav';
import MainNav from './MainNav';
import AdminNav from './AdminNav';

const IconBrandContainer = styled.span`
  & svg {
    display: block;
    filter: drop-shadow(3px 3px 4px rgb(0 0 0 / 40%));
  }
`;

const NavBar = (props: any): JSX.Element => {
  const theme = useTheme();
  // Set the state of the menu being open or closed
  const [mainMenuState, setMainMenuState] = useState<null | HTMLElement>(null);
  /**
   * @function handleOpenMainMenu Used to open the menu
   * @param event
   */
  const handleOpenMainMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setMainMenuState(event.currentTarget);
  };
  /**
   * @function handleCloseMainMenu Used to close mainmenu items
   */
  const handleCloseMainMenu = (): void => {
    setMainMenuState(null);
  };
  // State used to control the open and closing of the profile menu
  const [adminMenuState, setAdminMenuState] = useState<null | HTMLElement>(null);
  /**
   * @function handleOpenAdminMenu Sets the Admin menu to open
   * @param event Event triggered by the onClick action
   */
  const handleOpenAdminMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAdminMenuState(event.currentTarget);
  };
  /**
   * @function handleCloseAdminMenu Sets the Admin menu to close
   */
  const handleCloseAdminMenu = (): void => {
    setAdminMenuState(null);
  };
  /**
   * @function themeChangeHandler function to change the active theme
   * @param newThemeValue the value of the them to change to
   */
  const themeChangeHandler = (newThemeValue: number): void => {
    props.onThemeChange(newThemeValue);
  };
  /**
   * @function pageChangeHandler
   * @param page passes the page to route to
   */
  const pageChangeHandler = (page: string): any => {
    props.onPageChange(page);
  };
  const homeHandler = (): any => {
    props.onPageChange('/Home');
  };
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed" sx={{ zIndex: (theme): any => theme.zIndex.drawer + 20 }}>
          <Container maxWidth={false}>
            <Toolbar disableGutters color="background">
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="open drawer"
                sx={{ mr: 2 }}
                onClick={homeHandler}
              >
                <IconBrandContainer>
                  <ACMCubeLogo color="currentColor" height="50px" width="50px" />
                </IconBrandContainer>
              </IconButton>
              <Typography
                variant="h6"
                noWrap
                component="h1"
                sx={{
                  mr: 2,
                  display: { xs: 'flex', md: 'flex' },
                  marginTop: 0,
                  textShadow: '3px 3px 4px rgb(0 0 0 / 40%)',
                }}
              >
                Ad Capability Matrix
              </Typography>
              <MainNav
                onPageChange={pageChangeHandler}
                onCloseMainMenu={handleCloseMainMenu}
                onOpenMainMenu={handleOpenMainMenu}
                menuState={mainMenuState}
              />
              <AdminNav
                onPageChange={pageChangeHandler}
                onCloseAdminMenu={handleCloseAdminMenu}
                onOpenAdminMenu={handleOpenAdminMenu}
                menuState={adminMenuState}
              />
              <ProfileNav onThemeChange={themeChangeHandler} {...props} />
            </Toolbar>
          </Container>
        </AppBar>
      </Box>
    </ThemeProvider>
  );
};
export default NavBar;
