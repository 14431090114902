import React, { SVGAttributes } from 'react';
import styled from '@emotion/styled';
import cx from 'classnames';

const Container = styled.svg`
  color: currentColor;
`;

const DMEDOneLineLogo = React.forwardRef(
  ({ className, ...props }: SVGAttributes<SVGSVGElement>, ref?: React.Ref<SVGSVGElement>) => {
    return (
      <Container
        viewBox="0 0 522 47"
        fill="currentcolor"
        width="436px"
        height="40px"
        aria-hidden="true"
        ref={ref}
        className={cx('TrekMonochromeLogo-root', className)}
        {...props}
      >
        <title>Disney Media Entertainment and Distribution Technology</title>
        <path d="M370.9,37.5c3.5-1.3,6-4.4,6.5-7.9c-2.8,2.6-7.3,5.4-14.2,7.5C365.6,38.3,368.4,38.4,370.9,37.5z" />
        <path d="M386.9,22.2c0-0.1-0.1-0.1-0.2-0.1c0,0,0,0,0,0l0,0c-2.2,1.1-4.8,0.5-6.3-1.4c0-0.1-0.1-0.1-0.2-0.1	c-0.1,0-0.1,0.1-0.1,0.2c0.7,1.3,0.7,2.8,0.2,4.1c-0.4,0.9-1.1,1.7-2,2.1c-0.3,0.1-0.5,0.3-0.8,0.4l0,0c0-1-0.2-1.9-0.6-2.8	c-0.7-2.1-2.2-3.8-4-5c2.6,0.2,4.9-0.9,5.8-3.1c1.1-2.9-0.6-6.1-3.8-7.4c-3.2-1.2-7,0.2-7.9,2.9c-0.7,2.1,0.2,4.6,2.2,6.2	c-1.7-0.3-3.4-0.1-5,0.4c-1.5,0.5-2.9,1.5-4,2.7c0.5-2.5-0.4-4.9-2.5-6.1c-2.5-1.4-5.7-0.3-7.6,2.6c-1.9,2.9-1.2,6.7,1.3,8.2	c1.9,1.2,4.7,0.7,6.7-1.1c-0.3,1-0.5,2-0.5,3l0,0c-5.1,2.6-8.3,5.1-7.8,6.7c0.5,1.6,4.8,1.6,10.7,0.4c2.6-0.5,5.5-1.3,8.6-2.4	c2.9-1,5.9-2.6,8.5-4.1c0.5-0.3,1-0.6,1.5-0.9c0.9-0.6,2-0.8,3.1-0.6c1.2,0.2,2.3,0.9,3,1.9c0,0.1,0.1,0.1,0.2,0.1 c0.1,0,0.1-0.1,0.1-0.2c0,0,0,0,0,0c-1.1-2.2-0.5-4.8,1.4-6.3l0,0C386.9,22.4,387,22.3,386.9,22.2C386.9,22.2,386.9,22.2,386.9,22.2	z M355.7,32.7c-0.2-0.7,0.6-1.6,2.1-2.7l0,0c0.2,1.2,0.7,2.4,1.4,3.5l0,0C357.2,33.6,355.9,33.4,355.7,32.7z" />
        <path d="M37.6,17.2c0.4,0,0.8,0.2,1.1,0.4c0.4,0.4,0.5,1,0.3,1.5c-0.7,1.5-2.5,2.6-3.7,2.9c-0.7,0.2-1.6,0.2-2.3-0.1	c-0.3,0.2-0.6,0.8-1,0.6c-0.6-0.4-0.1-1.1-0.4-1.6c-0.1-0.1-0.2-0.1-0.3-0.3c-0.4-1,0.1-1.9,0.7-2.7C33,16.8,35.9,15.5,37.6,17.2	L37.6,17.2L37.6,17.2L37.6,17.2z M34.1,17.9c-0.6,0.1-1.2,0.4-1.6,0.8c-0.4,0.4-0.6,0.8-0.4,1.2c0.7-0.6,1.1-1.3,1.9-1.8	C34.1,18.1,34.3,18,34.1,17.9L34.1,17.9L34.1,17.9L34.1,17.9z M37.6,18.1c-1.5-0.1-2.6,1-3.6,2c-0.1,0.2-0.4,0.4-0.1,0.6	c1.4,0.1,2.7-0.3,3.8-1.2c0.3-0.3,0.5-0.6,0.4-1C37.9,18.4,37.8,18.2,37.6,18.1L37.6,18.1L37.6,18.1L37.6,18.1z" />
        <path d="M50.3,24.5c0.7,1.7,1.3,4.3,0,5.9c-0.3,0.4-0.9,0.7-1.3,0.4c-1.4-1.1-2-2.7-2.9-4.2c-0.1-0.1-0.1,0.1-0.2,0.2	c-0.3,1.5,0.3,3.4-0.7,4.5c-0.4,0.1-0.7-0.1-0.9-0.5c-0.5-1.5,0-3.1,0.1-4.6c0.3-0.8,0.4-1.7,1.1-2.2c1.1,0.4,1.6,1.6,2.2,2.5	c0.4,0.7,0.8,1.4,1.3,2c0.4-0.2,0.2-0.8,0.2-1.1c-0.2-1.4-0.6-2.7-0.9-4c0-0.3-0.2-0.9,0.1-1.1C49.5,22.6,49.9,23.6,50.3,24.5	L50.3,24.5L50.3,24.5L50.3,24.5z" />
        <path d="M43.9,24c0,0.3-0.3,0.7-0.5,0.8c-2,0.3-4.3,0.1-6.2,0.8c-0.1,0.2,0.2,0.3,0.4,0.3c1.6,0.3,3.4,0.3,5,0.7	c0.8,0.2,1.1,1.2,1.2,2c0,0.7-0.2,1.4-0.9,1.9c-1.6,1-4,1-5.6,0c-0.6-0.3-1.2-0.9-1.2-1.6c0-0.5,0.2-1,0.6-1.2	c1.5-0.6,3.4-0.3,4.7,0.5c0.1,0.4-0.4,0.4-0.6,0.6c-1.5,0.9-2.6-1-3.9-0.3c-0.2,0.1-0.3,0.5-0.1,0.6c1.7,0.7,3.6,0.2,5.2-0.3	c0.2-0.1,0.4-0.3,0.4-0.4c-0.1-0.7-0.8-0.8-1.3-1c-1.4-0.4-3-0.4-4.6-0.5c-0.3-0.1-0.7-0.3-0.8-0.5c-0.2-0.5-0.2-1.2,0.2-1.6	c2-1.7,5-1.6,7.5-1.1C43.6,23.7,43.8,23.8,43.9,24L43.9,24L43.9,24L43.9,24z" />
        <path d="M34.6,24.8c0.2,2,0.3,3.9,0.3,6.1c0,0.3-0.4,0.3-0.6,0.4c-0.3,0.1-0.8,0-0.9-0.2c-0.5-0.8-0.3-1.9-0.4-2.8	c0.1-1.5,0-3.1,0.5-4.6c0.1-0.2,0.3-0.4,0.5-0.3C34.5,23.8,34.6,24.3,34.6,24.8L34.6,24.8L34.6,24.8L34.6,24.8z" />
        <path d="M58.2,24.5c0.1,0.2,0.2,0.4,0,0.6c-1,0.4-2.3,0.2-3.4,0.4c-0.3,0.3-0.5,0.7-0.4,1.1c0.1,0.1,0.2,0.2,0.3,0.1	c0.7,0,1.7-0.3,2.1,0.3c0.1,0.2,0,0.9-0.3,0.9c-0.8,0.1-2.2-0.1-2.6,0.1c-0.5,0.3-0.5,1-0.7,1.5c0.4,0.2,0.7,0,1.2-0.1	c0.8-0.1,1.7-0.4,2.4-0.2c0.1,0.3,0.4,0.6,0.2,0.9c-1.3,1.1-3.1,2.2-4.9,1.4c-0.7-0.3-1-1.4-0.8-2.3c0.1-0.6,0.8-1.3,0.4-1.9	c-0.1-0.3,0.1-0.6,0.4-0.6c0.7,0,0.6-0.9,0.9-1.3c-0.4-0.4-1.4-0.4-1.3-1.3c0.5-0.3,1.1-0.2,1.6-0.3c1.3-0.3,2.7-0.4,3.9-0.3	C57.7,23.7,58,24.2,58.2,24.5L58.2,24.5L58.2,24.5L58.2,24.5z" />
        <path d="M27.9,19.6c2,1.6,4.5,4.3,4.2,7.5c-0.4,2.5-3,4.4-5.3,5c-2.2,0.6-4.9,0.6-7.1,0.1c-0.1,0.4-0.3,0.9-0.8,1.1	c-0.3,0.1-0.7,0-0.9-0.1c-0.7-0.6-0.1-1.8-1.1-2.2c-1.9-0.8-3.9-2.3-4.8-4.3c-0.1-0.4,0-0.8,0.3-1.1c1.5-1.2,3.3-1.6,5.2-2	c0.1,0,0.1-0.1,0.1-0.1c0.1-1.3,0.1-2.6,0.6-3.7c0.1-0.2,0.3-0.2,0.5-0.1c1.2,0.9,0.6,2.6,1.1,3.8c2.2,0.1,4.5,0.2,6.2,1.5	c0.6,0.5,0.7,1.4,0.6,2c-0.2,0.5-0.8,0.9-1.4,1c-0.4,0-1.1,0-1.1-0.3c0-0.3,1.3-0.8,0.9-1.2c-0.6-0.6-3.3-0.9-5.1-1.1	c-0.2,0-0.4,0-0.4,0.3c0,1.6-0.2,3.4,0.1,5c0,0.1,0.1,0.2,0.3,0.3c3,0.5,6.3,0.2,8.6-1.5c1.1-0.9,1.5-2.1,1.3-3.5	c-1-3.8-5-6.2-8.3-7.7c-3.2-1.4-6.8-2.1-10.6-1.9c-0.6,0-1.6,0.3-1.6,0.5s1.1,0.1,1,0.6c-0.1,0.4-1.2,0.2-1.6,0.1	c-0.3-0.1-0.3-0.6-0.2-0.9c0.8-1.5,3.1-1.6,4.2-1.6C18.6,14.4,24.2,16.6,27.9,19.6L27.9,19.6L27.9,19.6L27.9,19.6z M17.5,25.3	c-1.5,0-3,0.2-4.4,0.7c-0.3,0.1-0.6,0.4-0.4,0.8c0.6,0.7,1.3,1.4,2.1,1.8c0.7,0.4,1.7,1,2.6,1.1c0.2-1.4,0.2-2.7,0.2-4.2	C17.5,25.5,17.6,25.4,17.5,25.3L17.5,25.3L17.5,25.3L17.5,25.3z" />
        <path d="M61.4,23.4c-0.1,1.9-1.8,3-1.8,4.8c0.1,0.1,0.1,0.1,0.2,0.1c1.4-1.5,2.7-3.7,4.8-4.3c0.5,0,1,0.4,1.2,0.8	c0.7,1.4,0.5,3.4-0.4,4.7c-1,1.3-2.8,2.5-4.8,2.3c-0.8,2-1.4,4.2-1.7,6.4c-0.2,0.4-0.6,0-0.8,0c-1.7-1.4-0.3-5-0.2-5.4	c0.1-0.5,0.6-1.5,0.9-2.4c-0.8-1.4-0.3-3.2,0.4-4.5c0.5-0.9,1.2-1.8,2-2.5C61.3,23.3,61.3,23.3,61.4,23.4L61.4,23.4L61.4,23.4	L61.4,23.4z M64.8,25.7c-0.3,0-0.4,0.3-0.6,0.4c-1,1.1-2,2.2-2.4,3.5c0.7,0.1,1.2-0.3,1.8-0.6c1-0.6,1.6-1.6,1.5-2.8	C65.1,26,64.9,25.9,64.8,25.7L64.8,25.7L64.8,25.7L64.8,25.7z" />
        <g>
          <path d="M72.5,20.5h3.1l2.9,8h0l2.9-8h3.1v11.2h-2v-9h0l-3.4,9h-1.4l-3.4-9h0v9h-1.9V20.5z" />
          <path d="M93.9,28v0.3c0,0.1,0,0.2,0,0.3h-5.8c0,0.3,0.1,0.5,0.2,0.8c0.1,0.2,0.3,0.4,0.5,0.6c0.2,0.2,0.4,0.3,0.7,0.4	c0.3,0.1,0.5,0.1,0.8,0.1c0.5,0,0.9-0.1,1.2-0.3c0.3-0.2,0.6-0.4,0.8-0.7l1.3,1c-0.8,1-1.8,1.5-3.3,1.5c-0.6,0-1.1-0.1-1.6-0.3	c-0.5-0.2-0.9-0.4-1.3-0.8c-0.4-0.3-0.6-0.8-0.9-1.2c-0.2-0.5-0.3-1-0.3-1.7c0-0.6,0.1-1.2,0.3-1.7c0.2-0.5,0.5-0.9,0.8-1.3	c0.4-0.4,0.8-0.6,1.3-0.8c0.5-0.2,1-0.3,1.6-0.3c0.5,0,1,0.1,1.5,0.3c0.4,0.2,0.8,0.4,1.2,0.8c0.3,0.3,0.6,0.8,0.8,1.3	C93.8,26.7,93.9,27.3,93.9,28z M92,27.1c0-0.2,0-0.5-0.1-0.7c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.2-0.3-0.3-0.6-0.4	c-0.2-0.1-0.5-0.2-0.8-0.2c-0.6,0-1.1,0.2-1.5,0.5c-0.4,0.3-0.6,0.8-0.7,1.3H92z" />
          <path d="M103.2,31.7h-1.8v-1.1h0c-0.3,0.4-0.6,0.8-1.1,1c-0.5,0.2-1,0.3-1.5,0.3c-0.6,0-1.1-0.1-1.6-0.3	c-0.5-0.2-0.9-0.5-1.2-0.9c-0.3-0.4-0.6-0.8-0.7-1.3c-0.2-0.5-0.3-1-0.3-1.6c0-0.6,0.1-1.1,0.3-1.6c0.2-0.5,0.4-0.9,0.8-1.3	c0.3-0.4,0.7-0.6,1.2-0.8c0.5-0.2,1-0.3,1.5-0.3c0.3,0,0.7,0,0.9,0.1c0.3,0.1,0.5,0.2,0.7,0.3c0.2,0.1,0.4,0.2,0.5,0.4	c0.1,0.1,0.3,0.3,0.4,0.4h0v-5.3h1.9V31.7z M97,27c-0.1,0.3-0.1,0.6-0.1,0.9c0,0.3,0,0.6,0.1,0.9c0.1,0.3,0.2,0.6,0.4,0.8	c0.2,0.2,0.4,0.4,0.7,0.6c0.3,0.1,0.6,0.2,1,0.2c0.3,0,0.7-0.1,0.9-0.2c0.3-0.1,0.5-0.3,0.7-0.6c0.2-0.2,0.3-0.5,0.4-0.8	c0.1-0.3,0.2-0.6,0.2-0.9c0-0.3-0.1-0.6-0.2-0.9c-0.1-0.3-0.2-0.6-0.4-0.8c-0.2-0.2-0.4-0.4-0.7-0.6c-0.3-0.1-0.6-0.2-0.9-0.2	c-0.4,0-0.7,0.1-1,0.2c-0.3,0.1-0.5,0.3-0.7,0.6C97.3,26.4,97.1,26.7,97,27z" />
          <path d="M105.9,22.3c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.3,0.1-0.6,0.3-0.8c0.2-0.2,0.5-0.3,0.9-0.3s0.6,0.1,0.9,0.3	c0.2,0.2,0.4,0.5,0.4,0.8c0,0.3-0.1,0.6-0.4,0.8c-0.2,0.2-0.5,0.3-0.9,0.3S106.1,22.5,105.9,22.3z M105.8,24.1h1.9v7.6h-1.9V24.1z" />
          <path d="M115.1,30.7L115.1,30.7c-0.2,0.3-0.5,0.6-1,0.8c-0.4,0.2-0.9,0.3-1.4,0.3c-0.3,0-0.6,0-1-0.1	c-0.3-0.1-0.6-0.2-0.9-0.4c-0.3-0.2-0.5-0.4-0.7-0.7c-0.2-0.3-0.3-0.7-0.3-1.1c0-0.6,0.2-1,0.5-1.4c0.3-0.3,0.7-0.6,1.2-0.8	c0.5-0.2,1.1-0.3,1.7-0.4c0.6-0.1,1.2-0.1,1.8-0.1v-0.2c0-0.5-0.2-0.8-0.5-1.1c-0.3-0.2-0.8-0.3-1.2-0.3c-0.4,0-0.8,0.1-1.2,0.3	c-0.4,0.2-0.7,0.4-0.9,0.6l-1-1.2c0.4-0.4,0.9-0.7,1.5-0.9c0.6-0.2,1.1-0.3,1.7-0.3c0.7,0,1.2,0.1,1.7,0.3c0.4,0.2,0.8,0.4,1,0.7	c0.3,0.3,0.4,0.6,0.5,1c0.1,0.4,0.2,0.8,0.2,1.1v4.6h-1.8V30.7z M115.1,28.2h-0.4c-0.3,0-0.6,0-1,0c-0.3,0-0.6,0.1-0.9,0.2	c-0.3,0.1-0.5,0.2-0.7,0.4c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.2,0,0.3,0.1,0.5c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.3,0.1,0.4,0.2	c0.2,0,0.3,0,0.5,0c0.7,0,1.2-0.2,1.5-0.5c0.3-0.3,0.5-0.8,0.5-1.4V28.2z" />
        </g>
        <g>
          <path d="M253.1,20.5h4c0.7,0,1.5,0.1,2.2,0.3c0.7,0.2,1.4,0.5,2,0.9c0.6,0.4,1.1,1,1.4,1.7c0.4,0.7,0.6,1.6,0.6,2.7	c0,1-0.2,1.8-0.6,2.5c-0.4,0.7-0.9,1.3-1.4,1.7c-0.6,0.5-1.3,0.8-2,1c-0.7,0.2-1.5,0.3-2.2,0.3h-4V20.5z M256.8,29.9	c0.5,0,1-0.1,1.6-0.2c0.5-0.1,1-0.3,1.4-0.6c0.4-0.3,0.8-0.7,1-1.2c0.3-0.5,0.4-1.1,0.4-1.9c0-0.8-0.1-1.5-0.4-2	c-0.3-0.5-0.6-0.9-1-1.2c-0.4-0.3-0.9-0.5-1.4-0.6c-0.5-0.1-1-0.2-1.6-0.2h-1.7v7.8H256.8z" />
          <path d="M265.1,22.3c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.3,0.1-0.6,0.3-0.8c0.2-0.2,0.5-0.3,0.9-0.3c0.3,0,0.6,0.1,0.9,0.3	c0.2,0.2,0.4,0.5,0.4,0.8c0,0.3-0.1,0.6-0.4,0.8c-0.2,0.2-0.5,0.3-0.9,0.3C265.6,22.6,265.3,22.5,265.1,22.3z M265,24.1h1.9v7.6	H265V24.1z" />
          <path d="M273.6,26.1c-0.2-0.2-0.4-0.4-0.7-0.6c-0.3-0.2-0.6-0.2-1-0.2c-0.3,0-0.6,0.1-0.8,0.2	c-0.3,0.1-0.4,0.3-0.4,0.6c0,0.3,0.1,0.5,0.4,0.6c0.3,0.1,0.7,0.2,1.2,0.4c0.3,0.1,0.6,0.1,0.9,0.3c0.3,0.1,0.6,0.2,0.8,0.4	s0.4,0.4,0.6,0.6c0.1,0.3,0.2,0.6,0.2,0.9c0,0.5-0.1,0.9-0.3,1.2c-0.2,0.3-0.4,0.6-0.7,0.8c-0.3,0.2-0.6,0.3-1,0.4	c-0.4,0.1-0.8,0.1-1.2,0.1c-0.6,0-1.2-0.1-1.7-0.3c-0.6-0.2-1-0.5-1.4-0.9l1.3-1.2c0.2,0.3,0.5,0.5,0.8,0.7	c0.3,0.2,0.7,0.3,1.1,0.3c0.1,0,0.3,0,0.4,0c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.3,0.1-0.4	c0-0.3-0.1-0.5-0.4-0.7c-0.3-0.1-0.7-0.3-1.3-0.4c-0.3-0.1-0.6-0.1-0.8-0.2c-0.3-0.1-0.5-0.2-0.7-0.4c-0.2-0.2-0.4-0.4-0.5-0.6	c-0.1-0.2-0.2-0.5-0.2-0.9c0-0.4,0.1-0.8,0.3-1.1c0.2-0.3,0.4-0.6,0.7-0.8c0.3-0.2,0.6-0.3,1-0.4c0.4-0.1,0.7-0.1,1.1-0.1	c0.5,0,1.1,0.1,1.6,0.3c0.5,0.2,0.9,0.5,1.2,0.9L273.6,26.1z" />
          <path d="M275.7,25.6v-1.5h1.3v-2.2h1.9v2.2h1.9v1.5h-1.9v3.5c0,0.3,0.1,0.6,0.2,0.8c0.1,0.2,0.4,0.3,0.8,0.3	c0.1,0,0.3,0,0.4,0c0.1,0,0.3-0.1,0.4-0.1l0.1,1.5c-0.2,0.1-0.4,0.1-0.6,0.2c-0.2,0-0.5,0.1-0.7,0.1c-0.5,0-0.9-0.1-1.2-0.2	c-0.3-0.1-0.6-0.3-0.8-0.6c-0.2-0.2-0.3-0.5-0.4-0.9c-0.1-0.3-0.1-0.7-0.1-1v-3.6H275.7z" />
          <path d="M282.6,24.1h1.8v1.3h0c0.2-0.4,0.5-0.8,0.9-1.1c0.4-0.3,0.8-0.4,1.3-0.4c0.1,0,0.2,0,0.2,0c0.1,0,0.2,0,0.2,0	v1.7c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8,0.1-1,0.2c-0.3,0.2-0.5,0.3-0.6,0.6c-0.1,0.2-0.2,0.4-0.3,0.7	c-0.1,0.2-0.1,0.4-0.1,0.6v4.1h-1.9V24.1z" />
          <path d="M289.1,22.3c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.3,0.1-0.6,0.3-0.8c0.2-0.2,0.5-0.3,0.9-0.3s0.6,0.1,0.9,0.3	c0.2,0.2,0.4,0.5,0.4,0.8c0,0.3-0.1,0.6-0.4,0.8c-0.2,0.2-0.5,0.3-0.9,0.3S289.3,22.5,289.1,22.3z M289,24.1h1.9v7.6H289V24.1z" />
          <path d="M295.7,19.7v5.3h0c0.1-0.1,0.2-0.3,0.4-0.4c0.1-0.1,0.3-0.3,0.5-0.4c0.2-0.1,0.4-0.2,0.7-0.3	c0.3-0.1,0.6-0.1,0.9-0.1c0.5,0,1,0.1,1.5,0.3c0.5,0.2,0.8,0.5,1.2,0.8c0.3,0.4,0.6,0.8,0.8,1.3c0.2,0.5,0.3,1,0.3,1.6	c0,0.6-0.1,1.1-0.3,1.6c-0.2,0.5-0.4,0.9-0.7,1.3c-0.3,0.4-0.7,0.7-1.2,0.9c-0.5,0.2-1,0.3-1.6,0.3c-0.5,0-1-0.1-1.5-0.3	c-0.5-0.2-0.8-0.6-1.1-1h0v1.1h-1.8v-12H295.7z M299.9,28.7c0.1-0.3,0.1-0.6,0.1-0.9c0-0.3,0-0.6-0.1-0.9c-0.1-0.3-0.2-0.6-0.4-0.8	c-0.2-0.2-0.4-0.4-0.7-0.6c-0.3-0.1-0.6-0.2-1-0.2c-0.3,0-0.7,0.1-0.9,0.2c-0.3,0.1-0.5,0.3-0.7,0.6c-0.2,0.2-0.3,0.5-0.4,0.8	c-0.1,0.3-0.2,0.6-0.2,0.9c0,0.3,0,0.6,0.2,0.9c0.1,0.3,0.2,0.6,0.4,0.8c0.2,0.2,0.4,0.4,0.7,0.6c0.3,0.1,0.6,0.2,0.9,0.2	c0.4,0,0.7-0.1,1-0.2c0.3-0.1,0.5-0.3,0.7-0.6C299.7,29.3,299.8,29,299.9,28.7z" />
          <path d="M310.9,31.7h-1.8v-1.2h0c-0.2,0.4-0.5,0.7-0.9,1c-0.4,0.3-0.9,0.4-1.5,0.4c-0.5,0-0.9-0.1-1.3-0.3	c-0.4-0.2-0.6-0.4-0.9-0.7c-0.2-0.3-0.4-0.6-0.5-1c-0.1-0.4-0.2-0.8-0.2-1.1v-4.8h1.9v4.2c0,0.2,0,0.5,0,0.7c0,0.2,0.1,0.5,0.2,0.7	c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.1,0.4,0.2,0.7,0.2c0.3,0,0.6-0.1,0.8-0.2c0.2-0.1,0.4-0.3,0.6-0.5c0.1-0.2,0.3-0.4,0.3-0.7	c0.1-0.2,0.1-0.5,0.1-0.8v-4.2h1.9V31.7z" />
          <path d="M312.9,25.6v-1.5h1.3v-2.2h1.9v2.2h1.9v1.5h-1.9v3.5c0,0.3,0.1,0.6,0.2,0.8c0.1,0.2,0.4,0.3,0.8,0.3	c0.1,0,0.3,0,0.4,0c0.1,0,0.3-0.1,0.4-0.1l0.1,1.5c-0.2,0.1-0.4,0.1-0.6,0.2c-0.2,0-0.5,0.1-0.7,0.1c-0.5,0-0.9-0.1-1.2-0.2	c-0.3-0.1-0.6-0.3-0.8-0.6c-0.2-0.2-0.3-0.5-0.4-0.9c-0.1-0.3-0.1-0.7-0.1-1v-3.6H312.9z" />
          <path d="M320.3,22.3c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.3,0.1-0.6,0.3-0.8c0.2-0.2,0.5-0.3,0.9-0.3s0.6,0.1,0.9,0.3	c0.2,0.2,0.4,0.5,0.4,0.8c0,0.3-0.1,0.6-0.4,0.8c-0.2,0.2-0.5,0.3-0.9,0.3S320.5,22.5,320.3,22.3z M320.2,24.1h1.9v7.6h-1.9V24.1z" />
          <path d="M324.3,29.5c-0.2-0.5-0.3-1-0.3-1.6c0-0.6,0.1-1.1,0.3-1.6c0.2-0.5,0.5-0.9,0.9-1.3c0.4-0.3,0.8-0.6,1.3-0.8	c0.5-0.2,1-0.3,1.6-0.3s1.1,0.1,1.6,0.3c0.5,0.2,0.9,0.5,1.3,0.8c0.4,0.3,0.7,0.8,0.9,1.3s0.3,1,0.3,1.6c0,0.6-0.1,1.2-0.3,1.6	c-0.2,0.5-0.5,0.9-0.9,1.3c-0.4,0.4-0.8,0.6-1.3,0.8c-0.5,0.2-1,0.3-1.6,0.3s-1.1-0.1-1.6-0.3c-0.5-0.2-0.9-0.5-1.3-0.8	C324.8,30.4,324.5,30,324.3,29.5z M326,27c-0.1,0.3-0.1,0.6-0.1,0.9c0,0.3,0,0.6,0.1,0.9c0.1,0.3,0.2,0.6,0.4,0.8	c0.2,0.2,0.4,0.4,0.7,0.6c0.3,0.1,0.6,0.2,1,0.2c0.4,0,0.7-0.1,1-0.2c0.3-0.1,0.5-0.3,0.7-0.6c0.2-0.2,0.3-0.5,0.4-0.8	c0.1-0.3,0.1-0.6,0.1-0.9c0-0.3,0-0.6-0.1-0.9c-0.1-0.3-0.2-0.6-0.4-0.8c-0.2-0.2-0.4-0.4-0.7-0.6c-0.3-0.1-0.6-0.2-1-0.2	c-0.4,0-0.7,0.1-1,0.2c-0.3,0.1-0.5,0.3-0.7,0.6C326.3,26.4,326.1,26.7,326,27z" />
          <path d="M334.4,24.1h1.8v1.2h0c0.2-0.4,0.5-0.7,0.9-1c0.4-0.3,0.9-0.4,1.5-0.4c0.5,0,0.9,0.1,1.3,0.3	c0.4,0.2,0.6,0.4,0.9,0.7c0.2,0.3,0.4,0.6,0.5,1c0.1,0.4,0.2,0.8,0.2,1.1v4.8h-1.9v-4.2c0-0.2,0-0.5,0-0.7c0-0.2-0.1-0.5-0.2-0.7	c-0.1-0.2-0.3-0.4-0.4-0.5c-0.2-0.1-0.4-0.2-0.7-0.2c-0.3,0-0.6,0.1-0.8,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.3,0.4-0.3,0.7	c-0.1,0.2-0.1,0.5-0.1,0.8v4.2h-1.9V24.1z" />
        </g>
        <g>
          <path d="M130.8,29.3l2.6,2.7h-2.5l-1.2-1.3c-0.4,0.4-0.8,0.8-1.3,1.1c-0.5,0.3-1.2,0.4-2,0.4c-0.5,0-1-0.1-1.4-0.2	c-0.5-0.1-0.9-0.3-1.2-0.6c-0.3-0.3-0.6-0.6-0.8-1c-0.2-0.4-0.3-0.9-0.3-1.4c0-0.4,0.1-0.8,0.2-1.1c0.1-0.3,0.3-0.6,0.5-0.9	c0.2-0.3,0.5-0.5,0.8-0.7c0.3-0.2,0.6-0.4,0.9-0.5c-0.4-0.4-0.7-0.8-0.9-1.2c-0.2-0.4-0.3-0.9-0.3-1.3c0-0.5,0.1-0.9,0.3-1.2	c0.2-0.3,0.4-0.6,0.7-0.9c0.3-0.2,0.6-0.4,1-0.5c0.4-0.1,0.8-0.2,1.1-0.2c0.4,0,0.8,0.1,1.2,0.2c0.4,0.1,0.7,0.3,1,0.5	c0.3,0.2,0.5,0.5,0.7,0.9c0.2,0.3,0.3,0.7,0.3,1.2c0,0.4-0.1,0.7-0.2,1c-0.1,0.3-0.3,0.6-0.5,0.8c-0.2,0.2-0.4,0.5-0.7,0.7	c-0.3,0.2-0.6,0.4-0.9,0.5l1.8,1.9l1.3-2h2.2L130.8,29.3z M126.1,27c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.3,0.2-0.5,0.4	c-0.1,0.1-0.3,0.3-0.3,0.5c-0.1,0.2-0.1,0.4-0.1,0.7c0,0.3,0.1,0.5,0.2,0.7c0.1,0.2,0.3,0.4,0.4,0.6c0.2,0.1,0.4,0.3,0.6,0.3	s0.4,0.1,0.6,0.1c0.5,0,0.9-0.1,1.2-0.3c0.3-0.2,0.6-0.5,0.9-0.9L126.1,27z M127,22c-0.4,0-0.7,0.1-0.9,0.3	c-0.3,0.2-0.4,0.5-0.4,0.9c0,0.3,0.1,0.6,0.3,0.9c0.2,0.3,0.4,0.6,0.7,0.9c0.2-0.1,0.4-0.2,0.6-0.3c0.2-0.1,0.4-0.2,0.5-0.4	c0.1-0.1,0.3-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6c0-0.3-0.1-0.6-0.4-0.9C127.7,22.1,127.4,22,127,22z" />
        </g>
        <g>
          <path d="M137.1,20.5h7.4v1.7h-5.4v2.9h5.2v1.7h-5.2v3.2h5.7v1.8h-7.7V20.5z" />
          <path d="M147.4,24.1h1.8v1.2h0c0.2-0.4,0.5-0.7,0.9-1c0.4-0.3,0.9-0.4,1.5-0.4c0.5,0,0.9,0.1,1.3,0.3	c0.4,0.2,0.6,0.4,0.9,0.7c0.2,0.3,0.4,0.6,0.5,1c0.1,0.4,0.2,0.8,0.2,1.1v4.8h-1.9v-4.2c0-0.2,0-0.5,0-0.7c0-0.2-0.1-0.5-0.2-0.7	c-0.1-0.2-0.3-0.4-0.4-0.5c-0.2-0.1-0.4-0.2-0.7-0.2c-0.3,0-0.6,0.1-0.8,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.3,0.4-0.3,0.7	c-0.1,0.2-0.1,0.5-0.1,0.8v4.2h-1.9V24.1z" />
          <path d="M156.4,25.6v-1.5h1.3v-2.2h1.9v2.2h1.9v1.5h-1.9v3.5c0,0.3,0.1,0.6,0.2,0.8c0.1,0.2,0.4,0.3,0.8,0.3	c0.1,0,0.3,0,0.4,0c0.1,0,0.3-0.1,0.4-0.1l0.1,1.5c-0.2,0.1-0.4,0.1-0.6,0.2c-0.2,0-0.5,0.1-0.7,0.1c-0.5,0-0.9-0.1-1.2-0.2	c-0.3-0.1-0.6-0.3-0.8-0.6c-0.2-0.2-0.3-0.5-0.4-0.9c-0.1-0.3-0.1-0.7-0.1-1v-3.6H156.4z" />
          <path d="M170.4,28v0.3c0,0.1,0,0.2,0,0.3h-5.8c0,0.3,0.1,0.5,0.2,0.8c0.1,0.2,0.3,0.4,0.5,0.6c0.2,0.2,0.4,0.3,0.7,0.4	c0.3,0.1,0.5,0.1,0.8,0.1c0.5,0,0.9-0.1,1.2-0.3c0.3-0.2,0.6-0.4,0.8-0.7l1.3,1c-0.8,1-1.8,1.5-3.3,1.5c-0.6,0-1.1-0.1-1.6-0.3	c-0.5-0.2-0.9-0.4-1.3-0.8c-0.4-0.3-0.6-0.8-0.9-1.2c-0.2-0.5-0.3-1-0.3-1.7c0-0.6,0.1-1.2,0.3-1.7c0.2-0.5,0.5-0.9,0.8-1.3	c0.4-0.4,0.8-0.6,1.3-0.8c0.5-0.2,1-0.3,1.6-0.3c0.5,0,1,0.1,1.5,0.3c0.4,0.2,0.8,0.4,1.2,0.8c0.3,0.3,0.6,0.8,0.8,1.3	C170.3,26.7,170.4,27.3,170.4,28z M168.5,27.1c0-0.2,0-0.5-0.1-0.7c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.2-0.3-0.3-0.6-0.4	c-0.2-0.1-0.5-0.2-0.8-0.2c-0.6,0-1.1,0.2-1.5,0.5c-0.4,0.3-0.6,0.8-0.7,1.3H168.5z" />
          <path d="M172.6,24.1h1.8v1.3h0c0.2-0.4,0.5-0.8,0.9-1.1c0.4-0.3,0.8-0.4,1.3-0.4c0.1,0,0.2,0,0.2,0c0.1,0,0.2,0,0.2,0	v1.7c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8,0.1-1,0.2c-0.3,0.2-0.5,0.3-0.6,0.6c-0.1,0.2-0.2,0.4-0.3,0.7	c-0.1,0.2-0.1,0.4-0.1,0.6v4.1h-1.9V24.1z" />
          <path d="M177.7,25.6v-1.5h1.3v-2.2h1.9v2.2h1.9v1.5h-1.9v3.5c0,0.3,0.1,0.6,0.2,0.8c0.1,0.2,0.4,0.3,0.8,0.3	c0.1,0,0.3,0,0.4,0c0.1,0,0.3-0.1,0.4-0.1l0.1,1.5c-0.2,0.1-0.4,0.1-0.6,0.2c-0.2,0-0.5,0.1-0.7,0.1c-0.5,0-0.9-0.1-1.2-0.2	c-0.3-0.1-0.6-0.3-0.8-0.6c-0.2-0.2-0.3-0.5-0.4-0.9c-0.1-0.3-0.1-0.7-0.1-1v-3.6H177.7z" />
          <path d="M189.5,30.7L189.5,30.7c-0.2,0.3-0.5,0.6-1,0.8c-0.4,0.2-0.9,0.3-1.4,0.3c-0.3,0-0.6,0-1-0.1	c-0.3-0.1-0.6-0.2-0.9-0.4c-0.3-0.2-0.5-0.4-0.7-0.7c-0.2-0.3-0.3-0.7-0.3-1.1c0-0.6,0.2-1,0.5-1.4c0.3-0.3,0.7-0.6,1.2-0.8	c0.5-0.2,1.1-0.3,1.7-0.4c0.6-0.1,1.2-0.1,1.8-0.1v-0.2c0-0.5-0.2-0.8-0.5-1.1c-0.3-0.2-0.8-0.3-1.2-0.3c-0.4,0-0.8,0.1-1.2,0.3	c-0.4,0.2-0.7,0.4-0.9,0.6l-1-1.2c0.4-0.4,0.9-0.7,1.5-0.9c0.6-0.2,1.1-0.3,1.7-0.3c0.7,0,1.2,0.1,1.7,0.3c0.4,0.2,0.8,0.4,1,0.7	c0.3,0.3,0.4,0.6,0.5,1c0.1,0.4,0.2,0.8,0.2,1.1v4.6h-1.8V30.7z M189.5,28.2h-0.4c-0.3,0-0.6,0-1,0c-0.3,0-0.6,0.1-0.9,0.2	c-0.3,0.1-0.5,0.2-0.7,0.4c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.2,0,0.3,0.1,0.5c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.3,0.1,0.4,0.2	c0.2,0,0.3,0,0.5,0c0.7,0,1.2-0.2,1.5-0.5s0.5-0.8,0.5-1.4V28.2z" />
          <path d="M194,22.3c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.3,0.1-0.6,0.3-0.8c0.2-0.2,0.5-0.3,0.9-0.3c0.3,0,0.6,0.1,0.9,0.3	c0.2,0.2,0.4,0.5,0.4,0.8c0,0.3-0.1,0.6-0.4,0.8c-0.2,0.2-0.5,0.3-0.9,0.3C194.5,22.6,194.2,22.5,194,22.3z M193.9,24.1h1.9v7.6	h-1.9V24.1z" />
          <path d="M198.6,24.1h1.8v1.2h0c0.2-0.4,0.5-0.7,0.9-1c0.4-0.3,0.9-0.4,1.5-0.4c0.5,0,0.9,0.1,1.3,0.3	c0.4,0.2,0.6,0.4,0.9,0.7c0.2,0.3,0.4,0.6,0.5,1c0.1,0.4,0.2,0.8,0.2,1.1v4.8h-1.9v-4.2c0-0.2,0-0.5,0-0.7c0-0.2-0.1-0.5-0.2-0.7	c-0.1-0.2-0.3-0.4-0.4-0.5c-0.2-0.1-0.4-0.2-0.7-0.2c-0.3,0-0.6,0.1-0.8,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.3,0.4-0.3,0.7	c-0.1,0.2-0.1,0.5-0.1,0.8v4.2h-1.9V24.1z" />
          <path d="M218,23.9c0.5,0,0.9,0.1,1.3,0.3c0.4,0.2,0.6,0.4,0.9,0.7c0.2,0.3,0.4,0.6,0.5,1c0.1,0.4,0.2,0.8,0.2,1.1v4.8	h-1.9v-4.2c0-0.2,0-0.5,0-0.7c0-0.2-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.4-0.4-0.5c-0.2-0.1-0.4-0.2-0.7-0.2c-0.3,0-0.5,0.1-0.8,0.2	c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.2-0.1,0.5-0.1,0.7v4.2h-1.9v-4.6c0-0.5-0.1-0.9-0.3-1.2	c-0.2-0.3-0.6-0.5-1.1-0.5c-0.3,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.2-0.1,0.5-0.1,0.8	v4.2h-1.9v-7.6h1.8v1.2h0c0.1-0.2,0.2-0.4,0.3-0.5c0.1-0.2,0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.2,0.7-0.3c0.3-0.1,0.5-0.1,0.8-0.1	c0.6,0,1.1,0.1,1.5,0.4c0.4,0.3,0.7,0.6,0.9,1c0.3-0.5,0.6-0.8,1-1.1C216.9,24,217.4,23.9,218,23.9z" />
          <path d="M231.3,28v0.3c0,0.1,0,0.2,0,0.3h-5.8c0,0.3,0.1,0.5,0.2,0.8c0.1,0.2,0.3,0.4,0.5,0.6c0.2,0.2,0.4,0.3,0.7,0.4	c0.3,0.1,0.5,0.1,0.8,0.1c0.5,0,0.9-0.1,1.2-0.3c0.3-0.2,0.6-0.4,0.8-0.7l1.3,1c-0.8,1-1.8,1.5-3.3,1.5c-0.6,0-1.1-0.1-1.6-0.3	c-0.5-0.2-0.9-0.4-1.3-0.8c-0.4-0.3-0.6-0.8-0.9-1.2c-0.2-0.5-0.3-1-0.3-1.7c0-0.6,0.1-1.2,0.3-1.7c0.2-0.5,0.5-0.9,0.8-1.3	c0.4-0.4,0.8-0.6,1.3-0.8c0.5-0.2,1-0.3,1.6-0.3c0.5,0,1,0.1,1.5,0.3c0.4,0.2,0.8,0.4,1.2,0.8c0.3,0.3,0.6,0.8,0.8,1.3	C231.2,26.7,231.3,27.3,231.3,28z M229.4,27.1c0-0.2,0-0.5-0.1-0.7c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.2-0.3-0.3-0.6-0.4	c-0.2-0.1-0.5-0.2-0.8-0.2c-0.6,0-1.1,0.2-1.5,0.5c-0.4,0.3-0.6,0.8-0.7,1.3H229.4z" />
          <path d="M233.6,24.1h1.8v1.2h0c0.2-0.4,0.5-0.7,0.9-1c0.4-0.3,0.9-0.4,1.5-0.4c0.5,0,0.9,0.1,1.3,0.3	c0.4,0.2,0.6,0.4,0.9,0.7c0.2,0.3,0.4,0.6,0.5,1c0.1,0.4,0.2,0.8,0.2,1.1v4.8h-1.9v-4.2c0-0.2,0-0.5,0-0.7c0-0.2-0.1-0.5-0.2-0.7	c-0.1-0.2-0.3-0.4-0.4-0.5c-0.2-0.1-0.4-0.2-0.7-0.2c-0.3,0-0.6,0.1-0.8,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.3,0.4-0.3,0.7	c-0.1,0.2-0.1,0.5-0.1,0.8v4.2h-1.9V24.1z" />
          <path d="M242.3,25.6v-1.5h1.3v-2.2h1.9v2.2h1.9v1.5h-1.9v3.5c0,0.3,0.1,0.6,0.2,0.8c0.1,0.2,0.4,0.3,0.8,0.3	c0.1,0,0.3,0,0.4,0c0.1,0,0.3-0.1,0.4-0.1l0.1,1.5c-0.2,0.1-0.4,0.1-0.6,0.2c-0.2,0-0.5,0.1-0.7,0.1c-0.5,0-0.9-0.1-1.2-0.2	c-0.3-0.1-0.6-0.3-0.8-0.6c-0.2-0.2-0.3-0.5-0.4-0.9c-0.1-0.3-0.1-0.7-0.1-1v-3.6H242.3z" />
        </g>
        <path d="M499.6,28h2.5v1.4c-0.4,0.2-0.7,0.3-1.1,0.5c-0.4,0.1-0.7,0.2-1.1,0.2c-1.3,0-2.5-0.7-3.1-1.8	c-0.6-1.1-0.6-2.5,0-3.7c0.6-1.1,1.8-1.8,3.1-1.8c0.6,0,1.1,0.1,1.7,0.3c0.5,0.2,1,0.5,1.5,0.9l1.5-1.8c-0.6-0.5-1.3-1-2.1-1.2 c-0.8-0.3-1.6-0.5-2.5-0.5c-1.2,0-2.2,0.3-3.2,0.8c-0.9,0.5-1.7,1.2-2.3,2.1c-0.5,0.9-0.8,1.9-0.8,3c0,1.1,0.3,2.1,0.8,3 c0.5,0.9,1.3,1.6,2.2,2.1c0.9,0.5,2,0.8,3.1,0.8c0.8,0,1.6-0.1,2.5-0.4c0.9-0.3,1.7-0.8,2.3-1.3v-4.7h-4.9L499.6,28z" />
        <polygon points="393.7,20.6 403.2,20.6 403.2,22.8 399.7,22.8 399.7,32.1 397.1,32.1 397.1,22.8 393.7,22.8 " />
        <polygon points="405.4,20.6 414.2,20.6 414.2,22.8 408,22.8 408,25.2 413.6,25.2 413.6,27.4 408,27.4 408,29.9 414.4,29.9 414.4,32.1 405.4,32.1 " />
        <path d="M424.1,23.1c-0.5-0.3-1-0.4-1.6-0.4c-1.3,0-2.4,0.7-3,1.8c-0.3,0.6-0.5,1.2-0.5,1.8c0,0.6,0.2,1.3,0.5,1.8	c0.3,0.5,0.7,1,1.3,1.3c0.5,0.3,1.1,0.5,1.8,0.5c0.5,0,1.1-0.1,1.6-0.4c0.5-0.2,1-0.6,1.4-1l1.5,1.7c-0.6,0.6-1.3,1.1-2.2,1.5	c-0.8,0.4-1.7,0.6-2.5,0.6c-1.1,0-2.1-0.3-3.1-0.8c-0.9-0.5-1.6-1.2-2.2-2.1c-0.5-0.9-0.8-1.9-0.8-3c0-1.1,0.3-2.1,0.8-3 c0.5-0.9,1.3-1.6,2.2-2.1c0.9-0.5,2-0.8,3.1-0.8c1.7,0,3.3,0.7,4.5,1.9l-1.5,1.8C425.1,23.7,424.7,23.4,424.1,23.1" />
        <polygon points="429.4,20.6 432,20.6 432,25.4 437.2,25.4 437.2,20.6 439.7,20.6 439.7,32.1 437.2,32.1 437.2,27.6 432,27.6 432,32.1 429.4,32.1 " />
        <polygon points="451,20.6 453.5,20.6 453.5,32.1 451.1,32.1 445.6,24.7 445.6,32.1 443.1,32.1 443.1,20.6 445.4,20.6 451,28 " />
        <path d="M465.3,21.2c0.9,0.5,1.7,1.2,2.2,2.1c0.5,0.9,0.8,1.9,0.8,3s-0.3,2.1-0.8,3c-0.5,0.9-1.3,1.6-2.2,2.1 c-0.9,0.5-2,0.8-3.1,0.8c-1.1,0-2.2-0.3-3.1-0.8c-0.9-0.5-1.7-1.2-2.2-2.1c-0.5-0.9-0.8-1.9-0.8-3c0-1.1,0.3-2.1,0.8-3 c0.5-0.9,1.3-1.6,2.2-2.1c0.9-0.5,2-0.8,3.1-0.8C463.3,20.4,464.3,20.7,465.3,21.2 M460.4,23.2c-0.5,0.3-1,0.8-1.3,1.3 c-1,1.7-0.4,4,1.3,5c0.5,0.3,1.1,0.5,1.8,0.5c0.6,0,1.2-0.2,1.7-0.5c0.5-0.3,1-0.8,1.3-1.3c0.3-0.6,0.5-1.2,0.5-1.9	c0-0.6-0.2-1.3-0.5-1.8c-0.3-0.5-0.7-1-1.3-1.3c-0.5-0.3-1.1-0.5-1.7-0.5C461.5,22.7,460.9,22.9,460.4,23.2" />
        <polygon points="470.8,20.6 473.4,20.6 473.4,29.8 478.3,29.8 478.3,32.1 470.8,32.1 " />
        <path d="M489.1,21.2c0.9,0.5,1.7,1.2,2.2,2.1c0.5,0.9,0.8,1.9,0.8,3c0,1.1-0.3,2.1-0.8,3c-0.5,0.9-1.3,1.6-2.2,2.1	c-0.9,0.5-2,0.8-3.1,0.8c-1.1,0-2.2-0.3-3.1-0.8c-0.9-0.5-1.7-1.2-2.2-2.1c-0.5-0.9-0.8-1.9-0.8-3c0-1.1,0.3-2.1,0.8-3 c0.5-0.9,1.3-1.6,2.2-2.1c0.9-0.5,2-0.8,3.1-0.8C487.2,20.4,488.2,20.7,489.1,21.2 M484.3,23.2c-0.5,0.3-1,0.8-1.3,1.3 c-1,1.7-0.4,4,1.3,5c0.5,0.3,1.1,0.5,1.8,0.5c0.6,0,1.2-0.2,1.7-0.5c0.5-0.3,1-0.8,1.3-1.3c0.3-0.6,0.5-1.2,0.5-1.9	c0-0.6-0.2-1.3-0.5-1.8c-0.3-0.5-0.7-1-1.3-1.3c-0.5-0.3-1.1-0.5-1.7-0.5C485.4,22.7,484.8,22.9,484.3,23.2" />
        <polygon points="516.7,20.6 512.5,28.4 512.5,32.1 509.9,32.1 509.9,28.4 505.7,20.6 508.3,20.6 511.2,25.8 514.1,20.6" />
      </Container>
    );
  }
);
export default DMEDOneLineLogo;
