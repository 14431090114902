/**
 * @returns Operations Tables Column Definitions
 */
const OperationsColumnDefinitions = [
  {
    name: 'Ad Event Signal Type',
    table: 'ad_event_signal_type',
    visibility: {
      id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'category',
        headerName: 'Category',
        editable: false,
        flex: 1,
      },
    ],
  },
  {
    name: 'Ad Signal Logging Type',
    table: 'ad_signal_logging_type',
    visibility: {
      id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'logging_type',
        headerName: 'Logging Type',
        editable: false,
        flex: 1,
      },
    ],
  },
  {
    name: 'Avail Type',
    table: 'avail_type',
    visibility: {
      id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'category',
        headerName: 'Category',
        editable: false,
        flex: 1,
      },
    ],
  },
  {
    name: 'DAI Type',
    table: 'dai_type',
    visibility: {
      id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'category',
        headerName: 'Category',
        editable: false,
      },
      {
        field: 'acronym',
        headerName: 'Acronym',
        editable: false,
        flex: 1,
      },
    ],
  },
  {
    name: 'Deal Type',
    table: 'deal_type',
    visibility: {
      id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'category',
        headerName: 'Category',
        editable: false,
        flex: 1,
      },
      {
        field: 'short_name',
        headerName: 'Short Name',
        editable: false,
        flex: 1,
      },
    ],
  },
  {
    name: 'Sales Channel',
    table: 'sales_channel',
    visibility: {
      id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'sales_channel_name',
        headerName: 'Sales Channel Name',
        editable: false,
        flex: 1,
      },
    ],
  },
];
export default OperationsColumnDefinitions;
