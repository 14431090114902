import React, { useState, useContext } from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as Icon from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import ThemeSelector from '../Theme/ThemeSelector';
import { ACMIcon } from '../Icons/ACM';
import { useAuth } from 'oidc-react';
import { stateContext, RouteStore } from '../Auth/StateProvider';

const ProfileNav = (props: any): JSX.Element => {
  const auth = useAuth();
  const { sessionState, dispatch } = useContext(stateContext);
  //console.log('auth: ', auth);
  // State used to control the open and closing of the profile menu
  const [userProfileMenu, setUserProfileMenu] = useState<null | HTMLElement>(null);
  /**
   * @function handleOpenUserMenu Sets the profile menu to open
   * @param event Event triggered by the onClick action
   */
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setUserProfileMenu(event.currentTarget);
  };
  /**
   * @function handleCloseUserMenu Sets the profile menu to close
   */
  const handleCloseUserMenu = (): void => {
    setUserProfileMenu(null);
  };
  /**
   * @function themeChangeHandler Used to change the current theme
   * @param newThemeValue Passes the value of the new theme value
   */
  const themeChangeHandler = (newThemeValue: number): void => {
    props.onThemeChange(newThemeValue);
  };
  const profileMenu = [
    {
      name: 'My Profile',
      longName: '',
      id: 'myProfileBtn',
      category: 'Profile',
      onClick: (): void => {
        handleCloseUserMenu();
      },
      icon: (): JSX.Element => <ACMIcon name="ProfileIcon" width={22} height={22} />,
    },
    {
      name: 'Logout',
      longName: '',
      id: 'logoutBtn',
      category: 'Profile',
      onClick: (): void => {
        // Make sure that the user is authenticated and has session
        if (auth && auth.userData && sessionState.isAuthenticated) {
          // Sign out the user
          auth.signOut();
          // Remove their session state
          dispatch({ type: 'PURGE_STATE' });
        }
        // Route them to the login page
        props.onPageChange('/');
        // Set the stored route to home
        RouteStore('SET');
        handleCloseUserMenu();
      },
      icon: (): JSX.Element => <Icon.LogOut />,
    },
  ];
  // Build the Markup
  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="User Menu">
        <IconButton onClick={handleOpenUserMenu} size="small" sx={{ p: 1 }}>
          <Avatar
            sx={{
              boxShadow:
                '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
            }}
          >
            <Icon.User />
          </Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: '45px' }}
        id="user_menu"
        anchorEl={userProfileMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(userProfileMenu)}
        onClose={handleCloseUserMenu}
      >
        {profileMenu.map((item) => (
          <MenuItem key={item.id} onClick={item.onClick}>
            <item.icon />
            &nbsp;
            <Typography textAlign="center">{item.name}</Typography>
          </MenuItem>
        ))}
        <MenuItem>
          <ACMIcon name="ThemeIcon" width={22} height={22} />
          &nbsp;
          <ThemeSelector onThemeChange={themeChangeHandler} />
        </MenuItem>
      </Menu>
    </Box>
  );
};
export default ProfileNav;
