import React, { Fragment } from 'react';
import { GridApi, GridCellParams } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const ActionsCell = (params: any, props: any, recordProps: any): any => {
  const editRecordHandler = (e: any): any => {
    e.stopPropagation(); // don't select this row after clicking
    console.log('edit params: ', params);
    console.log('edit props: ', props);
    const api: GridApi = params.api;
    const thisRow: Record<string, GridCellParams> = {};
    api
      .getAllColumns()
      .filter((c: any): any => c.field !== '__check__' && !!c)
      .forEach((c: any): any => (thisRow[c.field] = params.getValue(params.id, c.field)));
    recordProps.onEditRecord(params.row);
    //return alert(JSON.stringify(thisRow, null, 4));
  };
  const deleteRecordHandler = (e: any): any => {
    e.stopPropagation(); // don't select this row after clicking
    const api: GridApi = params.api;
    const thisRow: Record<string, GridCellParams> = {};
    api
      .getAllColumns()
      .filter((c: any) => c.field !== '__check__' && !!c)
      .forEach((c: any) => (thisRow[c.field] = params.getValue(params.id, c.field)));
    recordProps.onDeleteRecord(params.row);
    //return alert(JSON.stringify(thisRow, null, 4));
  };

  return (
    <Fragment>
      <Tooltip title="Edit">
        <IconButton size="small" onClick={editRecordHandler}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete" onClick={deleteRecordHandler}>
        <IconButton size="small">
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </Fragment>
  );
};
export default ActionsCell;
