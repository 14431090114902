/**
 * @returns Partner Tables Column Definitions
 */
const PartnerColumnDefinitions = [
  {
    name: 'Partner',
    table: 'partner',
    visibility: {
      id: false,
      partner_type_id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'partner_name',
        headerName: 'Partner Name',
        editable: false,
        flex: 1,
      },
      {
        field: 'acronym',
        headerName: 'Acronym',
        editable: false,
        flex: 1,
      },
      {
        field: 'partner_type_id',
        headerName: 'Partner Type ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'partner_type',
        headerName: 'Partner Type',
        editable: false,
        flex: 1,
      },
      {
        field: 'url',
        headerName: 'URL',
        editable: false,
      },
    ],
  },
  {
    name: 'Partner Asset',
    table: 'partner_asset',
    visibility: {
      id: false,
      partner_id: false,
      asset_id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'partner_id',
        headerName: 'Partner ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'partner_name',
        headerName: 'Partner Name',
        editable: false,
        flex: 1,
      },
      {
        field: 'asset_id',
        headerName: 'Asset ID',
        type: 'number',
        editable: false,
      },
      {
        field: 'asset_name',
        headerName: 'Asset Name',
        editable: false,
        flex: 1,
      },
    ],
  },
  {
    name: 'Partner Type',
    table: 'partner_type',
    visibility: {
      id: false,
      created_by_id: false,
      updated_by_id: false,
    },
    columns: [
      {
        field: 'category',
        headerName: 'Category',
        editable: false,
        flex: 1,
      },
    ],
  },
];
export default PartnerColumnDefinitions;
