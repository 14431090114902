import React from 'react';
import styled from '@emotion/styled';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
// import { Card } from 'prism-ui/lib';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import SVGAsset from '../../components/Assets/SVGAsset';
import Breadcrumbs from '../../components/Navigation/Breadcrumbs';
import axios from 'axios';

const CreativesSection = styled.section`
  width: 100%;
  flex: auto;
  overflow: hidden;
`;

const Vendors = (props: any): JSX.Element => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [vendorData, setVendorData] = React.useState<any[]>([]);
  React.useEffect(() => {
    if (isLoading) {
      axios.get(`data/tableData/vendor_asset.json`).then((res: any): any => {
        const rows: any[] = res.data['vendor_asset'];
        console.log('ajax rowData: ', rows);
        setVendorData(rows);
        console.log('rows: ', rows);
      });
      console.log('vendorData: ', vendorData);
      return (): any => {
        setIsLoading(false);
      };
    }
  }, [isLoading, vendorData]);
  return (
    <CreativesSection>
      <ThemeProvider theme={theme}>
        <Box
          data-testid="app-root"
          sx={{
            display: 'flex',
            width: '100%',
            bgcolor: 'background.default !important',
            color: 'text.primary',
            borderRadius: 1,
            p: 3,
            marginTop: '60px',
          }}
        >
          <Breadcrumbs page="Vendors" />
        </Box>
        <Box sx={{ display: 'flex', width: '100%', marginTop: '0px', p: 3 }}>
          <Grid container spacing={1}>
            {vendorData.map((vendor: any): any => (
              <Grid key={vendor.id} item xs={6} md={3} lg={2} sx={{ textAlign: 'center' }}>
                <Card style={{ minHeight: '410px' }}>
                  <SVGAsset title={vendor.asset_name} content={vendor.content} width={250} height={250} />
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                      {vendor.vendor_name}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </ThemeProvider>
    </CreativesSection>
  );
};
export default Vendors;
